import React, { createContext, FC, ReactNode, useState } from "react";

type SetContextType = Partial<Omit<ModalContextType, "setProps">>;

export type ModalContextType = {
  setProps: (props: SetContextType) => void;
  children?: ReactNode;
  footerContent?: ReactNode;
  show?: boolean;
  subTitle?: string;
  title?: string;
};

export const initialValue = {
  children: undefined,
  FooterContent: undefined,
  goBack: undefined,
  show: false,
  subTitle: "",
  title: "",
  setProps: () => undefined,
};

export const ModalContext = createContext<ModalContextType>({
  setProps: () => {},
});

export function ModalContextProvider({ children }: { children: ReactNode }) {
  const [modalProps, setModalProps] = useState<Omit<ModalContextType, "setProps">>(initialValue);
  const providerProps = {
    ...modalProps,
    setProps: (props: SetContextType) => {
      setModalProps({
        ...initialValue,
        ...props,
      });
    },
  };
  return <ModalContext.Provider value={providerProps}>{children}</ModalContext.Provider>;
}
