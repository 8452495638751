import "./static.scss";
import { StaticPageData } from "@api/static-content";

export default function StaticContent({ content }: { content: StaticPageData }) {
  return (
    <div className={`page-${content.slug} flex flex-col text-white px-12 lg:px-60`}>
      <h1 className="font-bold text-3xl md:text-6xl mb-4 md:mb-8">{content?.title}</h1>
      {content?.strapline && <h2 className="text-lg mb-8 md:mb-16">{content?.strapline}</h2>}
      {content?.blocks.map((block) => {
        return (
          <div className="flex flex-col mb-4 md:mb-6">
            <h3 className="text-2xl mb-2 md:mb-4">{block.heading}</h3>
            <div dangerouslySetInnerHTML={{ __html: block.text }} className="static-content" />
          </div>
        );
      })}
    </div>
  );
}
