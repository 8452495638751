const overflowHiddenClass = "overflow-hidden";

function setBodyOverflowHidden() {
  const classList = document.body.classList;
  classList.add(overflowHiddenClass);
}

function removeBodyOverflowHidden() {
  const classList = document.body.classList;
  classList.remove(overflowHiddenClass);
}

function useBodyOverflowHidden() {
  return {
    setBodyOverflowHidden,
    removeBodyOverflowHidden,
  };
}

export default useBodyOverflowHidden;
