import cx from "classnames";

type Props = { text: string; count: number | string; variant: "alert" | "warning" };
function BadgeInfo({ text, count, variant }: Props) {
  const countText = count > 50 ? "50+" : count;
  const getCountBgText = () => {
    if (count > 49) {
      return "bg-blue text-white";
    } else if (count > 10) {
      return "bg-warning text-brand-black";
    } else return "bg-danger text-white";
  };
  return (
    <span className="flex items-center">
      <span className="text-white">{text}</span>
      <span className={cx(getCountBgText(), "ml-2 w-8 h-8 flex items-center justify-center rounded-full font-bold")}>
        {countText}
      </span>
    </span>
  );
}

export default BadgeInfo;
