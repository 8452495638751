import { useNavigate } from "react-router-dom";
import { LoadingSpinner } from "@atoms/icons";
import usePurchaseRequest from "@hooks/use-purchase-request";
import { FetchedTicket } from "@api/tickets";
import { Checkout } from "../checkout/checkout";
import { useCallback, useEffect } from "react";

type Props = {
  title: string;
  purchaseCode: string;
  onPurchaseExpiry: (redirectUrl: string) => void;
};

function EventCheckout({ title, purchaseCode, onPurchaseExpiry }: Props) {
  const { requestQuery, processPurchase, purchaseExpiry, priceList, totalPrice, completeFreePurchase } =
    usePurchaseRequest("event", purchaseCode);
  const handlePurchaseExpiryRedirect = useCallback(() => {
    const redirectUrl = !!requestQuery.data ? `/events/${requestQuery.data.event.slug}` : "/events";
    onPurchaseExpiry(redirectUrl);
  }, [onPurchaseExpiry, requestQuery.data]);
  const currencySymbol = !!requestQuery.data ? requestQuery.data.event.seller.currency_symbol : "$";

  const onProcessPurchase = ({
    email,
    mobile,
    firstname,
    lastname,
    custom_question_1,
    custom_question_2,
    custom_answer_1,
    custom_answer_2,
  }: {
    email: string;
    mobile: string;
    firstname: string;
    lastname: string;
    custom_question_1: string | null;
    custom_question_2: string | null;
    custom_answer_1: string | null;
    custom_answer_2: string | null;
  }) => {
    return processPurchase.mutateAsync({
      email,
      phone: mobile,
      code: purchaseCode,
      firstname,
      lastname,
      custom_question_1,
      custom_question_2,
      custom_answer_1,
      custom_answer_2,
    });
  };
  const ticketPurchaseDetails = (() => {
    if (!requestQuery.data) return [];
    const ticketTypes = requestQuery.data.event.ticket_types;
    const purchaseIdAndQuantity = Object.entries(requestQuery.data.purchase_request);
    return purchaseIdAndQuantity.reduce((accum, [id, quantity]) => {
      const ticketType = ticketTypes.find(({ uuid }) => uuid === id);
      if (!ticketType) return accum;
      return [...accum, { ticketType, quantity }];
    }, [] as { ticketType: FetchedTicket; quantity: number }[]);
  })();

  useEffect(() => {
    if (requestQuery.isError && (requestQuery.error as any)?.message === "Code expired") handlePurchaseExpiryRedirect();
  }, [requestQuery.isError, requestQuery.error, handlePurchaseExpiryRedirect]);

  if (!requestQuery.isSuccess) return <div className="w-24 h-24 mx-auto" children={<LoadingSpinner />} />;
  return (
    <Checkout
      type="ticket"
      occassion={requestQuery.data?.event}
      {...{
        onProcessPurchase,
        purchaseExpiry,
        title,
        handlePurchaseExpiryRedirect,
        ticketPurchaseDetails,
        priceList,
        totalPrice,
        completeFreePurchase,
        currencySymbol,
      }}
    />
  );
}

export default EventCheckout;
