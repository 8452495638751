import BadgeInfo from "@atoms/badge-info";
import SkeletonCardLoader from "@atoms/skeleton-card-loader";
import cx from "classnames";
import { ReactNode } from "react";
import { priceFormatter } from "@utils/formatters";
import Avatar from "@atoms/avatar";
import Svg from "@atoms/svg";
import { ReactComponent as Video } from "@assets/icons/video.svg";
import { ReactComponent as Image } from "@assets/icons/image.svg";
import { ReactComponent as Music } from "@assets/icons/music.svg";
import { Collectible } from "@api/collectibles";
import CurrencySymbol from "@atoms/currency-symbol";

export type Props = Collectible;

const LabelRow = ({ label, children, className }: { className?: string; label: string; children: ReactNode }) => {
  return (
    <div className={cx("text-lg flex justify-between", className)}>
      <span>{label}</span>
      <div className="flex flex-col items-end">{children}</div>
    </div>
  );
};

export default function CollectibleCard({ imageUrl, quantityAvailable, title, price, seller, ...collectable }: Props) {
  const Icon = (() => {
    if (collectable.audioMp3Url) return Music;
    return Image;
  })();

  return (
    <div className="rounded-3xl bg-navy overflow-hidden text-white">
      <div className="relative">
        <div className="absolute bg-gradient-to-t from-black/70 inset-0 z-1" />
        <img src={imageUrl} className="object-cover object-center w-full aspect-4/3" alt="Event thumbnail" />
        <div className="absolute px-4 bottom-5 z-2 flex justify-between w-full items-center">
          <div className="bg-blue rounded-full aspect-square p-1" children={<Svg icon={Icon} size="large" />} />
          <BadgeInfo count={quantityAvailable} text="Editions left:" variant="warning" />
        </div>
      </div>
      <div className="px-4 py-5">
        <h3 className="text-xl mb-4 truncate font-bold">{title}</h3>
        <LabelRow label="Price:" className="mb-2.5">
          <div className="flex items-center justify-center">
            <span className="inline-block text-2xl font-bold">
              <CurrencySymbol text={seller.currencySymbol} />{priceFormatter(price)}
            </span>
          </div>
        </LabelRow>
        <LabelRow label="Creator:" className="mb-2.5 flex flex-row items-center">
          <div className="flex items-center justify-center">
            <Avatar
              verified={seller.isVerified}
              imgSrc={seller.thumbnailUrl}
              label={seller.name}
              size="medium"
              labelClassName="text-white"
            />
          </div>
        </LabelRow>
        {/* <LabelRow label="Creator:">
          <Avatar size="small" border="holographic" label={creator} />
        </LabelRow> */}
      </div>
    </div>
  );
}
