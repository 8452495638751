import { Helmet } from "react-helmet";

type Props = {
  titlePrefix?: string;
  title?: string;
  canonicalUrl?: string;
  image?: string;
};

function HeadTitle({ titlePrefix, title, canonicalUrl, image }: Props) {
  const headTitle = title || (titlePrefix ? `${titlePrefix} | SeatlabNFT` : "SeatlabNFT | NFT Ticketing Marketplace");
  const description =
    "An NFT event ticketing marketplace that reduces scalping, enables transparent secondary sales and connects fans to incredible experiences.";

  return (
    <Helmet
      meta={[
        { property: "og:title", content: headTitle },
        { property: "og:description", content: description },
        ...(canonicalUrl ? [{ property: "og:url", content: canonicalUrl }] : []),
        ...(image ? [{ property: "og:image", content: image }] : []),
      ]}
    >
      <title>{headTitle}</title>
    </Helmet>
  );
}

export default HeadTitle;
