import { getContractConfig } from "@api/config";
import { createContext, ReactNode, useState } from "react";
import { AppVariables } from "./app-variables-context-types";
import { useQuery } from "react-query";

export const AppVariablesContext = createContext<AppVariables | undefined>(undefined);

export function AppVariablesContextProvider({ children }: { children: ReactNode }) {
  const [marketPlaceConfig, setMarketPlaceConfig] = useState<AppVariables>();
  useQuery(["config"], getContractConfig, {
    onSuccess: (fetchedConfig) => {
      const contracts = fetchedConfig.contracts;
      const nativeAppUrls = {
        wallet: { ios: fetchedConfig.wallet_app.ios.link, android: fetchedConfig.wallet_app.android.link },
      };
      const marketplaceFeatures = {
        ticketTransfersEnabled: fetchedConfig.features.marketplace.ticket_transfers_enabled || false,
        quickPayEnabled: fetchedConfig.features.marketplace.quick_pay_enabled || false,
      };
      setMarketPlaceConfig({ contracts, nativeAppUrls, marketplaceFeatures });
    },
  });

  return <AppVariablesContext.Provider value={marketPlaceConfig}>{children}</AppVariablesContext.Provider>;
}
