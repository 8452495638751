import { ReactNode, useEffect, useRef, useState } from "react";
import { ChevronDown, ChevronUp } from "@atoms/icons";
import classes from "./collapsible.module.css";
import classNames from "classnames";

type Props = {
  shrinkHeight?: "none" | "xs" | "sm";
  children?: ReactNode;
  open: boolean;
  onToggle: (open: boolean) => void;
  openLabel?: string;
  closeLabel?: string;
  iconClass?: string;
};

const getMinimisedHeight = (shrinkHeight: Props["shrinkHeight"]) => {
  switch (shrinkHeight) {
    case "sm":
      return 208; // "h-52"
    case "xs":
      return 128; // "h-32"
    default:
      return 0;
  }
};

function Collapsible({
  children,
  open,
  onToggle,
  shrinkHeight = "none",
  openLabel = "View More",
  closeLabel = "Less",
  iconClass,
}: Props) {
  const contentRef = useRef<HTMLDivElement>(null);
  const minimisedHeight = getMinimisedHeight(shrinkHeight);
  const [collapseNotNeeded, setCollapseNotNeeded] = useState(false);
  const [contentHeight, setContentHeight] = useState(minimisedHeight);
  const triggerText = open ? closeLabel : openLabel;
  const TriggerIcon = open ? ChevronUp : ChevronDown;

  useEffect(() => {
    const fullContentHeight = contentRef.current?.getBoundingClientRect().height;
    const contentSmallEnough = !fullContentHeight || (!!fullContentHeight && fullContentHeight <= minimisedHeight);
    setCollapseNotNeeded(contentSmallEnough);
  });

  useEffect(() => {
    const shrinkContent = () => setContentHeight(minimisedHeight);
    const expandContent = () => setContentHeight(contentRef.current?.getBoundingClientRect().height ?? 0);

    if (open) expandContent();
    else shrinkContent();
  }, [open]);

  return (
    <div>
      <div
        className={classNames("transition-height h-ease-out duration-300 overflow-hidden relative", {
          [classes.fadeOutMask]: !open && !collapseNotNeeded,
        })}
        style={{ height: contentHeight }}
      >
        <div ref={contentRef}>{children}</div>
      </div>
      {!collapseNotNeeded && (
        <button onClick={() => onToggle(!open)} className="flex items-center gap-2 font-bold text-sm">
          {triggerText} <TriggerIcon className={iconClass} />
        </button>
      )}
    </div>
  );
}

export default Collapsible;
