import HeadTitle from "@atoms/head-title/head-title";
import PageContainer from "@atoms/page-container";
import { useNavigate, useParams } from "react-router-dom";
import EventCheckout from "@pages/event-checkout/event-checkout";
import CollectibleCheckout from "@pages/collectible-checkout/collectible-checkout";
import { useState } from "react";
import PurchaseExpired from "@pages/purchase-expired";

export type CartCheckoutProps = {
  pageTitle?: string;
  purchaseType: "collectible" | "event";
};

function CartCheckout({ pageTitle, purchaseType }: CartCheckoutProps) {
  const title = pageTitle || "Checkout";
  const { purchaseCode } = useParams<{ purchaseCode: string }>();
  const navigate = useNavigate();
  const [expiryRedirectUrl, setExpiryRedirectUrl] = useState("");

  if (!!expiryRedirectUrl) return <PurchaseExpired onGoBack={() => navigate(expiryRedirectUrl)} />;

  return (
    <PageContainer>
      <HeadTitle titlePrefix={title} />
      {!purchaseCode ? null : purchaseType === "collectible" ? (
        <CollectibleCheckout {...{ title, purchaseCode }} onPurchaseExpiry={setExpiryRedirectUrl} />
      ) : purchaseType === "event" ? (
        <EventCheckout {...{ title, purchaseCode }} onPurchaseExpiry={setExpiryRedirectUrl} />
      ) : null}
    </PageContainer>
  );
}

export default CartCheckout;
