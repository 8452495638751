import { SVGProps } from "react";

const GraphIncreasing = (props: SVGProps<SVGSVGElement>) => (
  <svg width="56" height="54" viewBox="0 0 56 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.94141 0V48H53.3164V54H0.0195312V0H5.94141ZM51.223 9.879L55.4098 14.121L38.5117 31.242L29.6289 22.245L16.9176 35.121L12.7308 30.879L29.6289 13.758L38.5117 22.755L51.223 9.879Z"
      fill="white"
    />
  </svg>
);

export default GraphIncreasing;
