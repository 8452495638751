import GradientBorderSection from "@atoms/gradient-border-section";
import { SuccessCheck } from "@atoms/icons";
import QrGradient from "@atoms/qr-gradient";
import SVG from "@atoms/svg";
import TicketWalletStoreLink from "@organisms/ticket-wallet-store-link";
import IframeHeading from "@atoms/iframe-heading";
import React from "react";

function IframePurchaseSuccess({ pageTitle }: { pageTitle?: string }) {
  const title = pageTitle || "Purchase Success";
  return (
    <>
      <div className="mx-auto max-w-screen-xl px-5 lg:px-10">
        <IframeHeading title={title}></IframeHeading>
        <div className="grid grid-cols-1 gap-y-0 pt-5 w-full">
          <span className="mb-8 flex flex-row items-center">
            <h1 className="text-5xl sm:text-6xl font-bold text-white">Purchase Successful</h1>
            <SVG styles="ml-4" icon={SuccessCheck} height={92} width={92} />
          </span>
          <GradientBorderSection className="bg-[#1A2743] relative px-8 sm:pl-16 sm:pr-10 pt-7 sm:pt-12 pb-14 text-white mb-8">
            <div className="sm:grid sm:grid-cols-[60%_40%]">
              <div className="flex flex-col w-full md:pr-10 border-b border-white/20 pb-6 mb-6 sm:mb-0 sm:border-none">
                <p className="text-2xl sm:text-3xl font-bold text-white my-6">
                  Congratulations! Your order was successful. We've reserved it to your mobile number.
                </p>
                <p className="text-lg">
                  If you have already downloaded and set up our app, your tickets/collectables are waiting for you! Just
                  open the app and view your collections where you’ll find them.”
                </p>
              </div>
              <div className="flex flex-col w-full sm:text-center">
                <p className="text-2xl font-bold text-white mb-2">Don't have the app yet?</p>
                <p className="text-lg mb-2 hidden sm:block">
                  Scan the QR code below to download our wallet app. Once set up, you’ll find your tickets/collectables
                  waiting for you.
                </p>
                <p className="text-lg mb-2 sm:hidden">
                  Download the app using the links below to claim your tickets/collectables.
                </p>
                <div className="self-center hidden sm:block">
                  <QrGradient
                    url="https://www.seatlabnft.com/appdownload"
                    width={170}
                    height={170}
                    backgroundColor={"transparent"}
                  />
                </div>
                <div className="flex flex-row space-x-4 w-full sm:hidden mt-5 pb-8 mb-4 border-b border-white/20">
                  <TicketWalletStoreLink platform="ios" />
                  <TicketWalletStoreLink platform="android" />
                </div>
              </div>
            </div>
          </GradientBorderSection>
        </div>
      </div>
    </>
  );
}

export default IframePurchaseSuccess;
