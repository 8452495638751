import logo from "@assets/seatlab-logo-white.png";
import SocialIcons from "@molecules/social-icons";
import TicketWalletStoreLink from "@organisms/ticket-wallet-store-link";
import { Link } from "react-router-dom";
import bg from "./footer-cta-bg-brighter.jpg";

function Footer() {
  return (
    <footer className="mb-16 mt-16 md:mt-28 px-9 flex flex-col text-sm self-center max-w-screen-xl w-full">
      <div
        className="rounded-xl flex flex-col justify-center items-center mb-14 lg:mb-24 p-8 relative"
        style={{ background: `no-repeat center/cover url(${bg}) rgba(5,0,53,0.5)` }}
      >
        <h6 className="leading-8 text-3xl sm:text-4xl sm:leading-10 font-bold text-center text-white max-w-2xl mx-auto">
          Download our app now and change how you enjoy events forever
        </h6>
        <div className="flex space-x-4 w-full max-w-[400px] mx-auto my-4 lg:my-8">
          <TicketWalletStoreLink platform="android" />
          <TicketWalletStoreLink platform="ios" />
        </div>
        <p className="font-bold text-lg text-center text-white">
          Our wallet app allows you to purchase and store your tickets and digital collectables.
        </p>
      </div>
      <div className="flex flex-col-reverse justify-center items-center md:flex-row md:justify-between mb-2">
        <div className="w-40">
          <img src={logo} alt="seatlab logo" />
        </div>
        <div className="mb-20 md:mb-0">
          <SocialIcons />
        </div>
      </div>
      <div className="flex flex-col-reverse mt-3 md:space-y-10 items-center md:flex-row md:justify-between">
        <p className="md:w-80 leading-6 mt-8 md:mt-0">
          The use of this site is governed by our <br />
          <Link to="/privacy-policy">Privacy Policy</Link> and <Link to="/terms-of-service">Terms of Service</Link>.
        </p>
        <span className="text-center text-xs">
          <p>Copyright &copy; {new Date().getFullYear()} Seatlab</p>
          <p>All rights reserved.</p>
        </span>
      </div>
    </footer>
  );
}

export default Footer;
