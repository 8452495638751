export function priceFormatter(price: number | string) {
  return (Number(price) / 100).toFixed(2);
}

interface UrlParameterProps {
  [key: string]: any;
}

export const formatUrlParams = (urlParams: UrlParameterProps) =>
  Object.entries(urlParams)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");
