import "./styles/fonts.css";
import "./styles/global.css";
import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://2750faf69d8d4fd2b26ee6038a95a014@o920934.ingest.sentry.io/6633914",
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.1,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
