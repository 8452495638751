import { Event } from "@api/events";
import IframeEventListCard from "@molecules/iframe-event-list-card";
import IframeEventListMobileCard from "@molecules/iframe-event-list-mobile-card";
import React from "react";
import IframeHeading from "@atoms/iframe-heading";
import { useMediaQuery } from "react-responsive";

type EventsListingProps = {
  events?: Event[];
  isLoading: boolean;
};

function IframeEventListing({ events, isLoading }: EventsListingProps) {
  const isMobile = useMediaQuery({ query: "(max-width: 420px)" });

  return (
    <>
      <IframeHeading title={"Events"}></IframeHeading>
      <div className="grid grid-cols-1 gap-y-2 pt-5 w-full">
        {events?.map((event, index) => {
          const detailUrl = `/iframe/events/${event.slug}`;
          return (
            <React.Fragment key={`/events/${event.slug}`}>
              <div className="block" key={event.id}>
                {isMobile ? (
                  <IframeEventListMobileCard {...event} loading={isLoading} {...{ detailUrl }} />
                ) : (
                  <IframeEventListCard {...event} loading={isLoading} {...{ detailUrl }} />
                )}
                {index + 1 !== events.length && <div className="h-px my-6 bg-white/50" />}
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
}

export default IframeEventListing;
