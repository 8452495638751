import { IKContext } from "imagekitio-react";
import React, { ReactNode } from "react";

const urlEndpoint = "https://ik.imagekit.io/seatlabnft";

function ImagekitContext({ children }: { children: ReactNode }) {
  return <IKContext urlEndpoint={urlEndpoint}>{children}</IKContext>;
}

export default ImagekitContext;
