import { useQuery } from "react-query";
import { useState } from "react";
import { FilterOrganiser, SearchFilters } from "@api/common";

type DefaultValues = Partial<SearchFilters>;

type Params = {
  defaultValues: DefaultValues;
};

type ItemsQueryParams<R> =
  | [string, (filters: DefaultValues) => Promise<R>]
  | [string, (filters: DefaultValues) => Promise<R>, Params];
type OrganisersQueryParams = [string, (includeUnverified: boolean) => Promise<FilterOrganiser[]>];

function useSearchListingFilter<R>(
  [queryKey, searchFn, options]: ItemsQueryParams<R>,
  [organisersQueryKey, organisersSearchFn]: OrganisersQueryParams
) {
  const { defaultValues } = options || {};
  const getDefaultValue = (prop: keyof DefaultValues) => (defaultValues ? defaultValues[prop] : undefined);
  const [selectedOrganiserIds, setSelectedOrganiserIds] = useState(
    getDefaultValue("selectedOrganiserIds") as number[] | undefined
  );
  const [minPrice, setMinPrice] = useState(getDefaultValue("minPrice") as number | undefined);
  const [maxPrice, setMaxPrice] = useState(getDefaultValue("maxPrice") as number | undefined);
  const [startDate, setStartDate] = useState(getDefaultValue("startDate") as Date | undefined);
  const [endDate, setEndDate] = useState(getDefaultValue("endDate") as Date | undefined);
  const [verifiedOnly, setVerifiedOnly] = useState((getDefaultValue("verifiedOnly") as boolean | undefined) ?? true);
  const [page, setPage] = useState((getDefaultValue("page") as number | undefined) ?? 1);
  const [search, setSearch] = useState((getDefaultValue("search") as string | undefined) || "");
  const queryOptions = { verifiedOnly, page, minPrice, maxPrice, startDate, endDate, search, selectedOrganiserIds };
  const query = useQuery([queryKey, queryOptions], () => searchFn(queryOptions));
  const organisersQueryOptions = { includeUnverified: !verifiedOnly };
  const organiserQuery = useQuery([organisersQueryKey, organisersQueryOptions], () =>
    organisersSearchFn(organisersQueryOptions.includeUnverified)
  );

  const refetchQuery = (val: Partial<SearchFilters>) => {
    setMinPrice(val.minPrice);
    setMaxPrice(val.maxPrice);
    setStartDate(val.startDate);
    setEndDate(val.endDate);
    setSearch(val.search || "");
    setSelectedOrganiserIds(val.selectedOrganiserIds);
    query.refetch();
  };

  return {
    query,
    organiserQuery,
    refetchQuery,
    selectedOrganiserIds,
    minPrice,
    maxPrice,
    startDate,
    endDate,
    verifiedOnly,
    page,
    search,
    setVerifiedOnly,
    setPage,
  };
}

export default useSearchListingFilter;
