import { apiUrl, unAuthenticatedFetch } from "./lib";

type ApiConfig = {
  near_network: string;
  contracts: {
    tickets: string;
    collectibles: string;
    marketplace: string;
    usn: string;
    seat: string;
  };
  wallet_app: {
    ios: {
      min_version: string;
      link: string;
    };
    android: {
      min_version: string;
      link: string;
    };
  };
  scanner_app: {
    ios: {
      min_version: string;
      link: string;
    };
    android: {
      min_version: string;
      link: string;
    };
  };
  features: {
    marketplace: {
      ticket_transfers_enabled: boolean;
      quick_pay_enabled: boolean;
    };
  };
};
export function getContractConfig(): Promise<ApiConfig> {
  return unAuthenticatedFetch(`${apiUrl}/config`).then((json) => json.result);
}
