import { ModalContext, ModalContextType } from "@context/modal.context";
import { useContext } from "react";

type ShowModalProps = ModalContextType;

function useModal() {
  const { setProps, ...modalContext } = useContext(ModalContext);

  return {
    props: modalContext,
    showModal: (props: Omit<ShowModalProps, "setProps">) => setProps({ ...props, show: true }),
    closeModal: () => {
      setProps({ show: false });
    },
  };
}

export default useModal;
