import { Link, useNavigate } from "react-router-dom";
import cn from "classnames";
import { Close, HamburgerMenu } from "@atoms/icons";
import SVG from "@atoms/svg/svg";
import logo from "@assets/seatlab_white_logo.svg";
import React, { useEffect, useState } from "react";
import SocialIcons from "@molecules/social-icons";
import classNames from "classnames";
import useBodyOverflowHidden from "@hooks/use-body-overflow-hidden";
import Button from "@atoms/button";

const navContainerBaseClasses = "flex-col flex-1 justify-end right-0";
const navContainerDesktopClasses = "lg:mt-0 lg:absolute lg:top-0 lg:h-full lg:items-center";
const navItemsBaseClasses = "flex flex-col flex-1 gap-10";
const navItemsDesktopClasses = "lg:flex lg:flex-row lg:items-center";

function Header() {
  const navigate = useNavigate();
  const { setBodyOverflowHidden, removeBodyOverflowHidden } = useBodyOverflowHidden();
  const [navOpen, setNavOpen] = useState(false);
  const navOpenClass = navOpen ? "flex" : "hidden lg:flex";

  const handleLinkClick = (url: string) => {
    return (e: React.MouseEvent) => {
      setNavOpen(false);
      navigate(url);
    };
  };

  const linkItem = (url: string, label: string) => (
    <span onClick={handleLinkClick(url)} className="text-white font-medium text-m block select-none cursor-pointer">
      {label}
    </span>
  );

  const externalLinkItem = (url: string, label: string) => (
    <span
      onClick={() => {
        window.location.href = url;
      }}
      className="text-white font-medium text-m block select-none cursor-pointer"
    >
      {label}
    </span>
  );

  useEffect(() => {
    navOpen ? setBodyOverflowHidden() : removeBodyOverflowHidden();
    return () => removeBodyOverflowHidden();
  }, [navOpen]);

  return (
    <header
      className={cn(
        "flex flex-col py-4 px-6 lg:px-24 mb-3 max-w-full lg:bg-transparent lg:h-auto lg:static lg:w-auto",
        {
          "bg-siteBg h-screen fixed z-50 w-screen": navOpen,
        }
      )}
    >
      <div className="relative flex-1 flex flex-col">
        <div className="flex justify-between items-center">
          <div className="w-40 mb-2">
            <Link to="/" children={<img src={logo} />} />
          </div>
          <div
            className="hover:cursor-pointer h-full flex items-center lg:hidden text-white"
            onClick={() => setNavOpen((s) => !s)}
          >
            {navOpen ? <SVG icon={Close} /> : <HamburgerMenu />}
          </div>
        </div>
        <div className={classNames(navOpenClass, navContainerBaseClasses, navContainerDesktopClasses)}>
          <div className={classNames(navItemsBaseClasses, navItemsDesktopClasses)}>
            {linkItem("/events", "Events")}
            {linkItem("/collectables", "Collectables")}
            {externalLinkItem("https://www.seatlabnft.com/sellers", "Sellers")}
            {externalLinkItem("https://www.seatlabnft.com/blog", "Blog")}
            {externalLinkItem("https://www.seatlabnft.com/contact", "Contact")}
            <Button
              className="h-11 px-6"
              size="medium"
              onClick={() => {
                window.location.href = "https://www.seatlabnft.com/app";
              }}
              variant="primary"
            >
              Download app
            </Button>
          </div>
          <div className="flex flex-col items-center justify-end lg:hidden">
            <SocialIcons />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
