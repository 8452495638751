import { assign, createMachine } from "xstate";
import { getMyProfile } from "../api/users";
import { Profile } from "../context/auth-context";
import { STORAGE_KEY } from "../context/auth-context";

type Context = {
  jwt: string | undefined;
  profile: Profile | undefined;
};

type Events =
  | { type: "NO_TOKEN" }
  | { type: "SET_PROFILE"; payload: Profile }
  | { type: "FETCH_ERROR" }
  | { type: "REMOVE_LOCAL_STORAGE_TOKEN" };

type TypeStates =
  | {
      value: "localStorage";
      context: Context & {
        profile: undefined;
      };
    }
  | {
      value: "fetchProfile";
      context: Context;
    }
  | {
      value: "end";
      context: Context;
    }
  | {
      value: "fail";
      context: Context & {
        profile: undefined;
      };
    };

function isAuthValid(context: Context) {
  return context.profile && context.jwt;
}

const authBootstrap = createMachine<Context, Events, TypeStates>({
  initial: "localStorage",
  context: {
    jwt: undefined,
    profile: undefined,
  },
  states: {
    localStorage: {
      invoke: {
        id: "localStorage",
        src: () => new Promise((resolve) => resolve(window.localStorage.getItem(STORAGE_KEY))),
        onDone: {
          target: "fetchProfile",
          actions: assign({ jwt: (_, event) => event.data }),
        },
        onError: "fail",
      },
    },
    fetchProfile: {
      invoke: {
        id: "fetchProfile",
        src:
          ({ jwt }) =>
          (callback) => {
            if (!jwt) return callback({ type: "NO_TOKEN" });
            getMyProfile(jwt)
              .then((data) => {
                callback({ type: "SET_PROFILE", payload: data });
              })
              .catch((e) => {
                if (e.message === "unauthorized") callback({ type: "REMOVE_LOCAL_STORAGE_TOKEN" });
                callback({ type: "FETCH_ERROR" });
              });
          },
      },
      on: {
        NO_TOKEN: "end",
        SET_PROFILE: {
          target: "end",
          actions: assign({ profile: (_, event) => event.payload || undefined }),
        },
        REMOVE_LOCAL_STORAGE_TOKEN: {
          actions: () => window.localStorage.removeItem(STORAGE_KEY),
        },
        FETCH_ERROR: "fail",
      },
    },
    fail: {
      type: "final",
      data: {
        error: (_: any, e: any) => e || new Error("Could not bootstrap"),
      },
    },
    end: {
      type: "final",
      data: {
        jwt: (context: Context) => (isAuthValid(context) ? context.jwt : undefined),
        profile: (context: Context) => (isAuthValid(context) ? context.profile : undefined),
      },
    },
  },
});

export default authBootstrap;
