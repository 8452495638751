import Button from "@atoms/button";
import { priceFormatter } from "@utils/formatters";
import CurrencySymbol from "@atoms/currency-symbol";

type Props = {
  price: number;
  purchaseCollectible: () => void;
  disabled: boolean;
  currencySymbol: string;
};

const CollectibleBuySection = ({ price, purchaseCollectible, disabled, currencySymbol }: Props) => {
  return (
    <div className="bg-dark-panel border-2 border-brand-black h-30 w-full rounded-xl p-8">
      <span>
        <h2 className="text-xl mb-2.5 font-bold">Price:</h2>
        <span className="flex items-center space-x-2 mb-2.5">
          <p className="text-3xl md:text-5xl font-bold"><CurrencySymbol text={currencySymbol} />{priceFormatter(price)}</p>
        </span>
        <Button size="large" className="w-full max-w-[360px] mt-4" onClick={purchaseCollectible} {...{ disabled }}>
          Buy now
        </Button>
      </span>
    </div>
  );
};

export default CollectibleBuySection;
