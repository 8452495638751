import classNames from "classnames";

type ToggleProps = {
  active?: boolean;
  className?: string;
  onClick: (active: boolean) => void;
  disabled?: boolean;
};

const Toggle = ({ className, onClick, active, disabled }: ToggleProps) => {
  const containerClass = classNames(" w-14 h-7 rounded-full flex items-center p-0.5 cursor-pointer", className, {
    "justify-start bg-brand-black": !active,
    "justify-end bg-blue": active,
  });
  return (
    <div className={containerClass} onClick={!disabled ? () => onClick(!active) : () => null}>
      <div className="bg-white h-6 w-6 rounded-full"></div>
    </div>
  );
};

export default Toggle;
