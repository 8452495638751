import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import EventDetailsRoute from "./event-details";
import EventListingRoute from "./event-listing";
import IframeCollectibleListingRoute from "./iframe-collectible-listing";
import IframeEventListingRoute from "./iframe-event-listing";
import IframeEventTicketDetailsRoute from "./iframe-event-ticket-details";
import IframeCartCheckout from "@routes/iframe-cart-checkout";
import IframePurchaseSuccess from "@pages/iframe-purchase-success";
import CollectibleListingRoute from "./collectible-listing";
import CollectibleDetailsRoute from "./collectible-details";
import LoggedInHeader from "@organisms/header";
import AppInstallPrompt from "@pages/app-install-prompt/app-install-prompt";
import Homepage from "./homepage";
import Faq from "./faq";
import PrivacyPolicy from "./privacy-policy";
import TermsOfService from "./terms-of-service";
import { ReactNode } from "react";
import CheckoutOld from "@pages/checkout-old";
import { Helmet } from "react-helmet";
import NotFound from "@pages/404";
import Checkout from "./cart-checkout";
import PurchaseSuccess from "@pages/purchase-success";
import { useQueryClient } from "react-query";
import useDidMount from "@hooks/use-did-mount";
import useModal from "@hooks/use-modal";
import { UserException } from "@api/lib";
import Header from "@organisms/header";
import Footer from "@organisms/footer";
import Layout from "@templates/layout/layout";
import TermsOfUse from "./terms-of-use";

function RobotHidden({ children }: { children: ReactNode }) {
  return (
    <>
      <Helmet children={<meta name="robots" content="noindex" />} />
      {children}
    </>
  );
}

const RootRoutes = () => {
  const queryClient = useQueryClient();
  const { showModal } = useModal();

  useDidMount(() => {
    const defaultOptions = queryClient.getDefaultOptions();
    const onError = (msg: string) => showModal({ children: msg, title: "An error ocurred" });
    const errorHandler = (err: any) => {
      if (err instanceof UserException && err.status === 503) {
        return onError("Sorry your request could not be completed, please try againg later");
      }
      if (err instanceof UserException || err instanceof Error) return onError(err.message);
      throw err;
    };

    queryClient.setDefaultOptions({
      ...defaultOptions,
      queries: { ...defaultOptions.queries, onError: errorHandler },
      mutations: { ...defaultOptions.mutations, onError: errorHandler },
    });
  });

  return (
    <Routes>
      <Route path="/" element={<LoggedInHeader />} />
      <Route index element={<Homepage />} />
      <Route path="/iframe/buy-tickets/:purchaseCode" element={<RobotHidden children={<IframeCartCheckout />} />} />
      <Route path="/iframe/purchase-success" element={<RobotHidden children={<IframePurchaseSuccess />} />} />
      <Route path="/iframe/events/:eventSlug" element={<RobotHidden children={<IframeEventTicketDetailsRoute />} />} />
      <Route path="/iframe/:sellerSlug/events" element={<RobotHidden children={<IframeEventListingRoute />} />} />
      <Route
        path="/iframe/:sellerSlug/collectables"
        element={<RobotHidden children={<IframeCollectibleListingRoute />} />}
      />
      <Route element={<Layout children={<Outlet />} />}>
        {/* @TODO: Marketing team messed up, remove this url when no longer in use */}
        <Route path="/elrow-xxl-dubai-friday" element={<Navigate to="/events/elrow-xxl-dubai-friday" replace />} />
        <Route path="/collectables" element={<CollectibleListingRoute />} />
        <Route path="/collectables/:collectableSlug" element={<CollectibleDetailsRoute />} />
        <Route path="/events" element={<EventListingRoute />} />
        <Route path="/events/:eventSlug" element={<EventDetailsRoute />} />
        <Route path="/events/:eventSlug/:ticketUUID" element={<RobotHidden children={<EventDetailsRoute />} />} />
        <Route path="/login/code/*" element={<AppInstallPrompt />} />
        <Route
          path="/buy-tickets/:purchaseCode"
          element={<RobotHidden children={<Checkout purchaseType="event" />} />}
        />
        <Route
          path="/buy-collectables/:purchaseCode"
          element={<RobotHidden children={<Checkout purchaseType="collectible" />} />}
        />
        <Route path="/purchase-success" element={<RobotHidden children={<PurchaseSuccess />} />} />
        <Route
          path="/lottery/:ticketUUID/:eventSlug"
          element={
            <RobotHidden
              children={
                <CheckoutOld pageTitle="Enter ticket lottery" walletSectionTitle="Enter with your SeatlabNFT wallet" />
              }
            />
          }
        />
        <Route path="/faq" element={<Faq />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="*" element={<RobotHidden children={<NotFound />} />} />
      </Route>
    </Routes>
  );
};

export default RootRoutes;
