import { SVGProps } from "react";

const Check = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.791016 6.72206L2.94931 9.67817C3.62578 10.6047 5.01425 10.5867 5.66648 9.64295L11.2077 1.625"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Check;
