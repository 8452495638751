import { SVGProps } from "react";

const Discord = (props: SVGProps<SVGSVGElement>) => (
  <svg width="44" height="32" viewBox="0 0 44 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M36.6072 2.71114C41.3719 9.679 43.725 17.5386 42.8454 26.5867C42.8417 26.625 42.8216 26.6601 42.79 26.6832C39.1818 29.3185 35.6859 30.9177 32.239 31.9783C32.2122 31.9865 32.1835 31.986 32.1569 31.9771C32.1303 31.9681 32.1072 31.9512 32.0908 31.9285C31.2945 30.8265 30.571 29.6646 29.9371 28.4446C29.9007 28.3727 29.9339 28.2862 30.0088 28.2579C31.1579 27.8272 32.2506 27.3111 33.3017 26.7C33.3845 26.6517 33.3897 26.5337 33.3133 26.4771C33.0902 26.3124 32.8692 26.1393 32.6578 25.9662C32.6182 25.9342 32.5649 25.9279 32.5201 25.9494C25.6966 29.0835 18.2217 29.0835 11.3175 25.9494C11.2727 25.9295 11.2194 25.9363 11.1809 25.9678C10.97 26.1409 10.7485 26.3124 10.5275 26.4771C10.451 26.5337 10.4574 26.6517 10.5407 26.7C11.5917 27.2995 12.6844 27.8272 13.832 28.26C13.9063 28.2883 13.9416 28.3727 13.9047 28.4446C13.2846 29.6662 12.561 30.8281 11.7499 31.9301C11.7146 31.9747 11.6566 31.9951 11.6017 31.9783C8.17124 30.9177 4.67535 29.3185 1.06714 26.6832C1.03708 26.6601 1.01546 26.6234 1.01229 26.5851C0.277147 18.7586 1.77539 10.834 7.24467 2.70956C7.25785 2.68806 7.27789 2.67127 7.30109 2.66131C9.99223 1.43286 12.8753 0.529091 15.8887 0.0129534C15.9435 0.00456087 15.9984 0.0297383 16.0268 0.0779951C16.3992 0.733658 16.8247 1.57448 17.1127 2.26161C20.289 1.77905 23.5149 1.77905 26.7576 2.26161C27.0456 1.58917 27.4564 0.733658 27.8271 0.0779951C27.8403 0.0540554 27.8608 0.0348763 27.8856 0.0231957C27.9104 0.0115152 27.9383 0.00793048 27.9653 0.0129534C30.9802 0.530665 33.8633 1.43443 36.5523 2.66131C36.5761 2.67127 36.5956 2.68806 36.6072 2.71114ZM18.7275 17.5921C18.7607 15.2784 17.0647 13.3638 14.9357 13.3638C12.8242 13.3638 11.1445 15.2616 11.1445 17.5921C11.1445 19.922 12.8574 21.8198 14.9357 21.8198C17.0478 21.8198 18.7275 19.922 18.7275 17.5921ZM32.7458 17.5921C32.7791 15.2784 31.0831 13.3638 28.9546 13.3638C26.8425 13.3638 25.1629 15.2616 25.1629 17.5921C25.1629 19.922 26.8758 21.8198 28.9546 21.8198C31.0831 21.8198 32.7458 19.922 32.7458 17.5921Z"
      fill="white"
    />
  </svg>
);

export default Discord;
