import HeadTitle from "@atoms/head-title/head-title";
import useStaticContent from "@hooks/use-static-content";
import PrivacyPolicyPage from "@pages/privacy-policy";

function PrivacyPolicy() {
  const { data, isLoading } = useStaticContent("privacy-policy");

  if (isLoading || !data) return <div />;

  return (
    <>
      <HeadTitle titlePrefix="Privacy Policy" />
      <PrivacyPolicyPage content={data} />
    </>
  );
}

export default PrivacyPolicy;
