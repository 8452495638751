import HeadTitle from "@atoms/head-title/head-title";
import useStaticContent from "@hooks/use-static-content";
import TermsOfUsePage from "@pages/terms-of-use";

function TermsOfUse() {
  const { data, isLoading } = useStaticContent("terms-of-use");

  if (isLoading || !data) return <div />;

  return (
    <>
      <HeadTitle titlePrefix="Terms of use" />
      <TermsOfUsePage content={data} />
    </>
  );
}

export default TermsOfUse;
