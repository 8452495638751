import { Collectible } from "@api/collectibles";
import SkeletonCardLoader from "@atoms/skeleton-card-loader";
import CollectibleCard from "@molecules/collectible-card";
import { Link } from "react-router-dom";

type CollectiblesListingProps = {
  collectibles?: Collectible[];
  loading: boolean;
};

function CollectiblesListing({ collectibles, loading }: CollectiblesListingProps) {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 gap-6 justify-items-center">
      {loading
        ? new Array(10)
            .fill(undefined)
            .map(() => <div className="w-full h-[412px]" children={<SkeletonCardLoader />} />)
        : collectibles?.map((collectible) => (
            <Link to={`/collectables/${collectible.uuid}`} className="w-full" key={collectible.uuid}>
              <CollectibleCard {...collectible} />
            </Link>
          ))}
    </div>
  );
}

export default CollectiblesListing;
