import * as React from "react";
import { SVGProps } from "react";

const Share = (props: SVGProps<SVGSVGElement>) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.207 4.75H7.70703C6.60246 4.75 5.70703 5.64543 5.70703 6.75V17.25C5.70703 18.3546 6.60246 19.25 7.70703 19.25H18.207C19.3116 19.25 20.207 18.3546 20.207 17.25V14.75"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M20.207 9.25V4.75H15.707" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M19.957 5L12.707 12.25" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default Share;
