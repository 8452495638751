import CircularText from "@atoms/circular-text";
import Container from "@atoms/container";
import MetaverseToggle from "@atoms/metaverse-toggle.tsx/metaverse-toggle";
import SearchBar from "@atoms/search-bar";
import HeroImages from "@molecules/hero-images";

export default function Hero() {
  return (
    <div className="flex justify-center">
      <div className="lg:grid lg:grid-cols-[40%_60%] max-w-[1100px]">
        <div className="flex flex-col flex-1 lg:flex-none justify-center text-center lg:text-left">
          <h1 className="font-semibold text-4xl md:text-7xl text-white mb-8">
            NFTs — <br /> New, Fairer <br /> Ticketing.
          </h1>
          <p className="text-white text-lg mb-8 leading-8">
            Buy and sell NFT tickets and collectables on our transparent marketplace. Experience immersive events in a
            new way and be rewarded for your loyalty.
          </p>
          {/* <div className="mx-4 lg:mx-0">
            <SearchBar id="" onChange={() => {}} withButton placeholder="Search for event, team, artist, or venue" />
          </div> */}
        </div>
        <div className="flex-1 hidden lg:block ml-8">
          <HeroImages />
          {/* <div className="absolute w-[180px] h-[180px] right-0 bottom-0">
            <div className="mt-[67px]">
              <CircularText text="Discover More Categories " elementHeight="200px" />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
