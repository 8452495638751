import { useParams } from "react-router-dom";
import React from "react";
import Container from "@atoms/container";
import HeadTitle from "@atoms/head-title/head-title";
import IframeTicketListing from "@pages/iframe-tickets-listing";

export type IframeEventTicketDetailParams = {
  eventSlug: string;
  ticketUUID?: string;
};

function IframeEventTicketDetails() {
  const params = useParams<IframeEventTicketDetailParams>();
  if (!params.eventSlug) throw new Error("Event Slug not found in url");
  return (
    <>
      <HeadTitle titlePrefix="Tickets" />
      <Container children={<IframeTicketListing ticketUUID={params.ticketUUID} eventSlug={params.eventSlug} />} />
    </>
  );
}

export default IframeEventTicketDetails;
