import { Link, useNavigate } from "react-router-dom";
import React, { useContext, useMemo, useState } from "react";
import IframeHeading from "@atoms/iframe-heading";
import EventTicketsList from "@organisms/event-tickets-list";
import EventDetailsPanel from "@molecules/event-details-card/event-details-panel";
import { priceFormatter } from "@utils/formatters";
import Button from "@atoms/button";
import useEvents from "@hooks/use-events";
import { AppVariablesContext } from "@context/app-variables-context";
import { QueryFunctionContext, useMutation, useQuery } from "react-query";
import { Ticket } from "@api/tickets";
import { isString } from "xstate/lib/utils";

type TicketListingProps = {
  eventSlug: string;
  ticketUUID?: string;
};

function IframeTicketListing({ eventSlug, ticketUUID }: TicketListingProps) {
  const navigate = useNavigate();
  const { fetchEventBySlug, fetchEventVerificationCode, fetchUrlOnlyEvent } = useEvents();
  const { marketplaceFeatures } = useContext(AppVariablesContext) || {};
  const ticketTransfersEnabled = marketplaceFeatures ? marketplaceFeatures.ticketTransfersEnabled : false;
  const isUrlOnlyEvent = !!ticketUUID;
  const queryName = isUrlOnlyEvent ? "urlOnlyEvent" : "event";
  const queryParams: [string, string | undefined] = [eventSlug, ticketUUID];
  const { data: occasion, isLoading } = useQuery([queryName, ...queryParams], fetchEvent);
  const getEventCode = useMutation((tickets: { [uuid: string]: number }) => fetchEventVerificationCode(tickets), {
    onSuccess: (code) => void navigate(`/iframe/buy-tickets/${code}`),
  });
  const [selectedTickets, setSelectedTickets] = useState<{ [ticketUuid: string]: number }>({});

  const onSelectTicket = (ticket: Ticket, quantity: number) => {
    setSelectedTickets((current) => ({ ...current, [ticket.uuid]: quantity }));
  };

  function fetchEvent({ queryKey: [, eventSlug, ticketUUID] }: QueryFunctionContext<Array<string | undefined>>) {
    if (!eventSlug) throw new Error("Event Slug not found in url");
    if (eventSlug && ticketUUID) return fetchUrlOnlyEvent(eventSlug, ticketUUID);
    return fetchEventBySlug(eventSlug);
  }

  const hasSelectedTickets = useMemo(() => {
    if (!!Object.keys(selectedTickets).length) {
      return Object.values(selectedTickets).some((quantity) => quantity > 0);
    }
    return false;
  }, [selectedTickets]);

  const totalSelectedTicketCost = useMemo(() => {
    if (occasion) {
      const ticketTypesByUUID = occasion.ticketTypes.reduce((allTickets, ticketType) => {
        return { ...allTickets, [ticketType.uuid]: ticketType };
      }, {} as { [uuid: string]: Ticket });
      return Object.keys(selectedTickets).reduce((total, ticketUUID) => {
        const ticket = ticketTypesByUUID[ticketUUID];
        if (!ticket) throw new Error("Could not find ticket");
        const ticketTotal = ticket.price * (selectedTickets[ticketUUID] || 0);
        return total + ticketTotal;
      }, 0);
    } else {
      return 0;
    }
  }, [selectedTickets, occasion?.ticketTypes]);

  if (isLoading) return <div />;

  if (!occasion) {
    return (
      <div>
        <h1 className="text-4xl">Sorry, this event cannot be found</h1>
        <Link to="/iframe/events">Go back to events</Link>
      </div>
    );
  }

  let eventSlugsIncludingVat: string[] = [];
  if (process.env.REACT_APP_EVENT_SLUGS_INCLUDING_VAT) {
    eventSlugsIncludingVat = process.env.REACT_APP_EVENT_SLUGS_INCLUDING_VAT.split(",");
  }

  const priceIncludesVat = eventSlugsIncludingVat.includes(occasion.slug);

  const isLotteryOnly = occasion.ticketTypes.every(({ lottery }) => {
    return !!lottery;
  });

  return (
    <>
      <IframeHeading title={"Tickets"}></IframeHeading>
      <div className="grid grid-cols-1 gap-y-8 pt-5 w-full">
        <EventTicketsList
          tickets={occasion.ticketTypes}
          selectedTickets={selectedTickets}
          onSelectTicket={onSelectTicket}
          showPriceInclVat={priceIncludesVat}
          ticketTransfersEnabled={ticketTransfersEnabled}
          currencySymbol={occasion.seller.currencySymbol}
        />
        <EventDetailsPanel
          title={undefined}
          headerClassName="border-0"
          containerClassName="border-none md:max-h-[915px]"
          contentClassName={"border-none"}
          footer={
            hasSelectedTickets ? (
              <div className="flex flex-row pl-5 pr-2 py-3 space-x-4 w-full">
                <div className="text-left text-xl text-white w-1/2">
                  <p>Total Cost:</p>
                  <p>
                    {occasion.seller.currencySymbol}
                    {priceFormatter(totalSelectedTicketCost)}
                  </p>
                </div>
                <div className="w-1/2 text-right">
                  <Button disabled={getEventCode.isLoading} onClick={() => getEventCode.mutate(selectedTickets)}>
                    {isLotteryOnly ? "Enter lottery" : "Buy now"}
                  </Button>
                </div>
              </div>
            ) : null
          }
          footerClassName={"border-none"}
        />
      </div>
    </>
  );
}

export default IframeTicketListing;
