import { Collectible } from "@api/collectibles";
import { CollectableDetailImage } from "@molecules/collectable-detail-Image/collectable-detail-Image";

type Props = {
  collectible: Collectible;
};

const CollectibleDetailDescription = ({ collectible }: Props) => {
  return (
    <>
      <h1 className="text-2xl md:text-3xl mb-2 md:mb-5 font-bold">{collectible.title}</h1>
      <div className="mb-2 md:hidden">
        <CollectableDetailImage {...collectible} />
      </div>
      <p className="text-lg  mt-5 mb-5 md:mb-12 whitespace-pre-wrap">{collectible.description}</p>
    </>
  );
};

export default CollectibleDetailDescription;
