import HeadTitle from "@atoms/head-title/head-title";
import useEvents from "@hooks/use-events";
import { Event } from "@api/events";
import { useEffect, useState } from "react";
import Container from "@atoms/container";
import IframeEventListing from "@pages/iframe-events-listing";
import { useParams } from "react-router-dom";

export type IframeEventListingParams = {
  sellerSlug: string;
};

function EventListingRoute() {
  const params = useParams<IframeEventListingParams>();
  if (!params.sellerSlug) throw new Error("Seller Slug not found in url");
  const { fetchAllEvents } = useEvents();
  const [events, setEvents] = useState<Event[]>([]);
  let isLoading = false;
  const eventsQuery = fetchAllEvents({ page: 1, perPage: 10000, sellerSlug: params.sellerSlug }).then((response) => {
    return response.result;
  });
  const getEvents = (query: any) => {
    return query.then((data: Event[]) => {
      setEvents(data);
    });
  };

  useEffect(() => {
    getEvents(eventsQuery);
  }, []);

  return (
    <>
      <HeadTitle titlePrefix="Events" />
      <Container children={<IframeEventListing events={events} isLoading={isLoading} />} />
    </>
  );
}

export default EventListingRoute;
