import { useNavigate, useParams } from "react-router-dom";
import React, { useState } from "react";
import Container from "@atoms/container";
import HeadTitle from "@atoms/head-title/head-title";
import IframePurchaseExpired from "@pages/iframe-purchase-expired";
import IframeEventCheckout from "@pages/iframe-event-checkout";

export type IframeEventCheckoutParams = {
  title: string;
  purchaseCode: string;
};

function IframeCartCheckout() {
  const params = useParams<IframeEventCheckoutParams>();
  const navigate = useNavigate();

  const [expiryRedirectUrl, setExpiryRedirectUrl] = useState("");
  if (!!expiryRedirectUrl) return <IframePurchaseExpired onGoBack={() => navigate(expiryRedirectUrl)} />;

  if (!params.purchaseCode) throw new Error("Purchase code not found in url");
  return (
    <>
      <HeadTitle titlePrefix="Select Your Ticket" />
      <Container
        children={
          <IframeEventCheckout
            title={"Checkout"}
            purchaseCode={params.purchaseCode}
            onPurchaseExpiry={setExpiryRedirectUrl}
          />
        }
      />
    </>
  );
}

export default IframeCartCheckout;
