import React from "react";
import { Close } from "@atoms/icons";
import SVG from "@atoms/svg";
import classNames from "classnames";

function Pill({ label, onClose, className }: { label: string; onClose?: () => void; className?: string }) {
  return (
    <div className={classNames("inline-block", className)}>
      <div
        className={classNames(
          "inline-flex justify-center items-center pl-4 py-1 bg-blue text-white rounded-full min-w-16 gap-1",
          { "pr-4": !onClose }
        )}
      >
        <span className="text-sm font-bold">{label}</span>
        {onClose && (
          <button type="button" onClick={onClose} className="w-6 h-6 mr-2 rounded-full bg-white text-blue">
            <SVG size="medium" icon={Close} styles="cursor-pointer" />
          </button>
        )}
      </div>
    </div>
  );
}

export default Pill;
