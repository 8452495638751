import { EventPurchaseRequest } from "./../api/events";
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "react-query";
import useCollectibles from "@hooks/use-collectibles";
import useEvents from "@hooks/use-events";
import { CollectiblePurchaseRequest } from "@api/collectibles";
import { useMemo } from "react";

type ProcessPurchaseArgs = {
  phone: string;
  code: string;
  email: string;
  firstname: string;
  lastname: string;
  custom_question_1: string | null;
  custom_question_2: string | null;
  custom_answer_1: string | null;
  custom_answer_2: string | null;
};

type ReturnArgs = {
  processPurchase: UseMutationResult<any, unknown, ProcessPurchaseArgs, unknown>;
  completeFreePurchase: UseMutationResult<any, unknown, void, unknown>;
  purchaseExpiry: Date | undefined;
  totalPrice: number | undefined;
  priceList: [string, number][];
};

type CollectibleReturn = ReturnArgs & {
  requestQuery: UseQueryResult<CollectiblePurchaseRequest | undefined>;
};
type EventReturn = ReturnArgs & {
  requestQuery: UseQueryResult<EventPurchaseRequest | undefined>;
};

function usePurchaseRequest<T extends "collectible">(purchaseType: T, purchaseCode: string): CollectibleReturn;
function usePurchaseRequest<T extends "event">(purchaseType: T, purchaseCode: string): EventReturn;
function usePurchaseRequest<T extends "event" | "collectible">(
  purchaseType: T,
  purchaseCode: string
): EventReturn | CollectibleReturn;
function usePurchaseRequest<T extends any>(purchaseType: T, purchaseCode: string) {
  const eventsHook = useEvents();
  const collectiblesHook = useCollectibles();

  const fetchPurchase =
    purchaseType === "collectible" ? collectiblesHook.fetchPurchaseRequest : eventsHook.fetchPurchaseRequest;

  const requestQuery = useQuery<CollectiblePurchaseRequest | EventPurchaseRequest | undefined>(
    ["purchaseRequest", purchaseCode],
    () => (!purchaseCode ? undefined : fetchPurchase(purchaseCode)),
    {
      enabled: !!purchaseCode,
      retry: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );
  const { isSuccess, data } = requestQuery;

  const processCollectablePurchaseRquest = useMutation(
    ({
      code,
      phone,
      email,
      firstname,
      lastname,
      custom_question_1,
      custom_question_2,
      custom_answer_1,
      custom_answer_2,
    }: ProcessPurchaseArgs) => {
      return collectiblesHook.processAnonymousPurchaseRequest(
        code,
        phone,
        email,
        firstname,
        lastname,
        custom_question_1,
        custom_question_2,
        custom_answer_1,
        custom_answer_2
      );
    }
  );
  const processEventPurchaseRquest = useMutation(
    ({
      code,
      phone,
      email,
      firstname,
      lastname,
      custom_question_1,
      custom_question_2,
      custom_answer_1,
      custom_answer_2,
    }: ProcessPurchaseArgs) => {
      return eventsHook.processAnonymousPurchaseRequest(
        code,
        phone,
        email,
        firstname,
        lastname,
        custom_question_1,
        custom_question_2,
        custom_answer_1,
        custom_answer_2
      );
    }
  );

  const { purchaseExpiry, totalPrice, priceList } = useMemo(() => {
    if (!isSuccess || !data) return { priceList: [] };
    return {
      purchaseExpiry: new Date(data.expires_at),
      totalPrice: data.price_total,
      priceList: [["Platform fee", data.price_platform_fee]] as [string, number][],
    };
  }, [data, isSuccess]);

  const completeFreePurchase = useMutation(() => {
    if (purchaseType === "collectible") return collectiblesHook.processFreeAnonymousPurchaseRequest(purchaseCode);
    else return eventsHook.processFreeAnonymousPurchaseRequest(purchaseCode);
  });

  return {
    requestQuery,
    ...(purchaseType === "collectible"
      ? { processPurchase: processCollectablePurchaseRquest }
      : { processPurchase: processEventPurchaseRquest }),
    purchaseExpiry,
    totalPrice,
    priceList,
    completeFreePurchase,
  };
}

export default usePurchaseRequest;
