import CurrencyInput from "@atoms/currency-input";
import classNames from "classnames";
import Toggle from "../../atoms/toggle";
import { MaterialDateRangePicker } from "@molecules/date-range";
import Button from "@atoms/button";
import * as yup from "yup";
import { Field, FieldProps, Formik, useFormikContext } from "formik";
import { DateRange } from "@material-ui/pickers";
import { FilterOrganiser } from "@api/common";
import Checkbox from "@atoms/checkbox";
import SearchBar from "@atoms/search-bar";
import { useMemo, useState } from "react";

type FormProps = {
  setVerified: (verified: boolean) => void;
  verifiedOnly: boolean;
  disabled?: boolean;
  pricing: boolean;
  dates: boolean;
  organisers?: FilterOrganiser[];
  organisersFilterTitle: string;
};

type Props = FormProps & {
  onSubmit: (values: Values) => void;
  initialValues?: Values;
};

export type Values = {
  minPrice?: number;
  maxPrice?: number;
  startDate?: Date;
  endDate?: Date;
  selectedOrganiserIds?: number[];
};

const FilterSection = ({ title, children }: { title: string; children: React.ReactNode; className?: string }) => (
  <div className="pt-4 pb-7 px-4 lg:px-0 border-b border-brand-black">
    <div className={classNames("text-2xl font-bold text-placeholder mb-3")}>{title}</div>
    {children}
  </div>
);

const validationSchema = yup.object().shape({
  minPrice: yup.number().nullable(),
  maxPrice: yup.number().nullable(),
  startDate: yup.date().nullable(),
  endDate: yup.date().nullable(),
  selectedOrganiserIds: yup.array().of(yup.string()).nullable(),
});

const defaultValues = {
  minPrice: undefined,
  maxPrice: undefined,
  startDate: undefined,
  endDate: undefined,
  selectedOrganiserIds: [],
};

const SearchListingFilters = ({ onSubmit, initialValues = defaultValues, ...props }: Props) => {
  return (
    <Formik {...{ initialValues, validationSchema, onSubmit }} enableReinitialize>
      <SearchListingFiltersForm {...props} />
    </Formik>
  );
};

const SearchListingFiltersForm = ({
  setVerified,
  verifiedOnly,
  disabled,
  pricing,
  dates,
  organisers,
  organisersFilterTitle,
}: FormProps) => {
  const { setFieldValue, handleSubmit, values, setValues } = useFormikContext<Values>();
  const [organiserSearch, setOrganiserSearch] = useState("");
  const selectedOrganiserIds = useMemo(() => values.selectedOrganiserIds || [], [values.selectedOrganiserIds]);

  const selectedUnverifiedOrganisers = useMemo(() => {
    return organisers?.filter(({ isVerified, id }) => !isVerified && selectedOrganiserIds.includes(id)) ?? [];
  }, [organisers, selectedOrganiserIds]);

  const activeOrganiserList = useMemo(() => {
    return organisers?.filter(({ isVerified }) => (!!verifiedOnly ? !!isVerified : true)) ?? [];
  }, [verifiedOnly, organisers]);

  const dateClicked = ([start, end]: DateRange<Date>) => {
    setFieldValue("startDate", start);
    setFieldValue("endDate", end);
  };

  const onResetFilters = () => {
    setVerified(true);
    setValues(defaultValues);
    setOrganiserSearch("");
    handleSubmit();
  };

  const organiserClicked = (organiser: FilterOrganiser) => {
    const newSelectedOrganisers = selectedOrganiserIds.includes(organiser.id)
      ? selectedOrganiserIds.filter((id) => id !== organiser.id)
      : [...selectedOrganiserIds, organiser.id];
    setFieldValue("selectedOrganiserIds", newSelectedOrganisers);
  };

  const renderOrganisers = (organiserList: FilterOrganiser[]) => {
    return organiserList
      .filter(({ name }) => name.toLowerCase().includes(organiserSearch.toLowerCase()))
      .map((organiser: FilterOrganiser, index: number) => {
        const isSelected = selectedOrganiserIds.includes(organiser.id);
        const onClick = () => organiserClicked(organiser);
        return (
          <span key={index} className="flex flex-row mb-2.5 items-center cursor-pointer" {...{ onClick }}>
            <Checkbox selected={!!isSelected} />
            <img src={organiser.thumbnailUrl} className="h-6 w-6 rounded-full mr-2.5" alt="organiser avatar" />
            {organiser.name}
          </span>
        );
      });
  };

  return (
    <div>
      <FilterSection title={"Show"}>
        <div className={classNames("flex flex-row justify-between font-bold")}>
          <span>Verified only</span>
          <Toggle className="ml-4" onClick={setVerified} active={verifiedOnly} disabled={disabled} />
        </div>
      </FilterSection>

      {pricing && (
        <FilterSection title={"Price"}>
          <Field name="minPrice">
            {({ field, form }: FieldProps<Values["minPrice"]>) => {
              return (
                <CurrencyInput
                  className="mb-2"
                  decimalsLimit={2}
                  prefix="£"
                  value={field.value}
                  onValueChange={(val) => form.setFieldValue("minPrice", val)}
                  label="From"
                  onBlur={field.onBlur}
                />
              );
            }}
          </Field>
          <Field name="maxPrice">
            {({ field, form }: FieldProps<Values["maxPrice"]>) => (
              <CurrencyInput
                decimalsLimit={2}
                prefix="£"
                value={field.value}
                onValueChange={(val) => form.setFieldValue("maxPrice", val)}
                label="To"
                onBlur={field.onBlur}
              />
            )}
          </Field>
        </FilterSection>
      )}
      {dates && (
        <FilterSection title="Dates">
          <MaterialDateRangePicker
            value={[values.startDate || null, values.endDate || null]}
            onChange={dateClicked}
            placeholders={["From", "To"]}
          />
        </FilterSection>
      )}
      <FilterSection title={organisersFilterTitle}>
        <SearchBar
          value={organiserSearch}
          onChange={(e) => setOrganiserSearch(e.target.value)}
          placeholder="Search by event organiser"
          className="mb-2.5"
        />
        <div className="bg-brand-black rounded-lg px-4 pt-5 pb-2.5 h-40 overflow-auto scrollbar-thin scrollbar-thumb-grey scrollbar-track-slate/50 scrollbar-thumb-rounded">
          {!!verifiedOnly && renderOrganisers(selectedUnverifiedOrganisers)}
          {!!activeOrganiserList?.length && renderOrganisers(activeOrganiserList)}
        </div>
      </FilterSection>
      <div className="grid grid-cols-2 gap-4 mt-8 px-4 lg:px-0">
        <Button onClick={() => handleSubmit()}>Apply</Button>
        <Button onClick={() => onResetFilters()} variant="outline">
          Reset Filters
        </Button>
      </div>
    </div>
  );
};

export default SearchListingFilters;
