import { UrlOptionsBase } from "imagekit-javascript/dist/src/interfaces/UrlOptions.d";
import imagekit, { isImageKitUrl } from "@utils/imagekit";
import { useMemo } from "react";

function useImagekitUrl(absoluteurl: string, urlOptions?: UrlOptionsBase) {
  const thumbImgUrl = useMemo(() => {
    return isImageKitUrl(absoluteurl)
      ? imagekit.url({ src: absoluteurl, transformation: [{ width: "350" }], ...urlOptions })
      : absoluteurl;
  }, [absoluteurl, urlOptions]);

  return thumbImgUrl;
}

export default useImagekitUrl;
