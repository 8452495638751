import HeadTitle from "@atoms/head-title/head-title";
import useStaticContent from "@hooks/use-static-content";
import FAQPage from "@pages/faq";

function FAQ() {
  const { data, isLoading } = useStaticContent("faq");

  if (isLoading || !data) return <div />;

  return (
    <>
      <HeadTitle titlePrefix="FAQs" />
      <FAQPage content={data} />
    </>
  );
}

export default FAQ;
