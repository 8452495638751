import Button from "@atoms/button";
import { ButtonProps } from "@atoms/button";
import React, { ReactNode } from "react";

export type Props<D> = {
  title: string;
  buttonVariant?: ButtonProps<"button">["variant"];
  onButtonClick?: () => void;
  buttonText?: string;
  data: D[];
  renderItem: (item: { data: D; index: number }) => ReactNode;
};

function ListPreview<D extends { id: string | number }>({
  title,
  onButtonClick,
  buttonText,
  data,
  renderItem,
  buttonVariant,
}: Props<D>) {
  const renderedButton = !!onButtonClick ? (
    <Button className="h-11 px-6" size="medium" onClick={onButtonClick} variant={buttonVariant}>
      {buttonText}
    </Button>
  ) : null;

  return (
    <section className="lg:mx-10 flex flex-col lg:block items-center lg:items-baseline">
      <header className="flex md:justify-between lg:items-center mb-7 px-5 lg:mb-12 justify-center">
        <h2 className="text-3xl font-bold text-center lg:text-left text-white">{title}</h2>
        {!!renderedButton && <div className="hidden lg:inline-block">{renderedButton}</div>}
      </header>
      <div className="grid-cols-1 md:grid-cols-2 lg:grid-cols-3 grid gap-8 justify-items-center">
        {data.map((data, index) => (
          <React.Fragment key={data.id}>{renderItem({ data, index })}</React.Fragment>
        ))}
      </div>
      {!!renderedButton && <div className="lg:hidden flex mt-4 justify-center">{renderedButton}</div>}
    </section>
  );
}

export default ListPreview;
