import EventsListing from "@pages/events-listing";
import HeadTitle from "@atoms/head-title/head-title";
import useEvents from "@hooks/use-events";
import SearchListing from "@templates/search-listing";

function EventListingRoute() {
  const { fetchAllEvents, fetchSellers } = useEvents();

  return (
    <>
      <HeadTitle titlePrefix="Events" />
      <SearchListing
        organisersFilterTitle="Organisers"
        showFilters={{ pricing: true, dates: true }}
        itemsQuery={fetchAllEvents}
        itemsQueryKey="allEvents"
        organisersQuery={fetchSellers}
        organisersQueryKey="allSellers"
        searchPlaceholder="Search by event title or seller"
        noResults="No events to display"
      >
        {({ data, isLoading }) => <EventsListing events={data?.result} isLoading={isLoading} />}
      </SearchListing>
    </>
  );
}

export default EventListingRoute;
