import GradientBorderSection from "@atoms/gradient-border-section";
import React, { ReactNode } from "react";

function BorderedCard({ children, title }: { children: ReactNode; title: string }) {
  return (
    <GradientBorderSection className="bg-[#1A2743] relative px-7 py-8 text-white mb-8">
      <header className="text-2xl font-bold">{title}</header>
      <div className="h-[1px] w-full bg-white opacity-20 my-5" />
      {children}
      <div className="h-[1px] w-full bg-white opacity-20 my-5 md:hidden" />
    </GradientBorderSection>
  );
}

export default BorderedCard;
