import { useContext, useMemo } from "react";
import { isPast } from "date-fns";
import { getLoginCode } from "../api/auth";
import { getMyProfile } from "../api/users";
import { AuthContext } from "../context/auth-context";

type WebsocketResponse = {
  jwt: string;
};

function useAuth() {
  const {
    disconnectPusher,
    listenForLogin,
    profile,
    setProfile,
    saveTokenToStorage,
    loginParams,
    setLoginParams,
    loginSessionId,
    setLoginSessionId,
  } = useContext(AuthContext);
  const loginCode = loginParams?.code;
  const loginCodeExpiry = loginParams?.expires_at;

  const saveJsonWebToken = useMemo(() => (token: string) => saveTokenToStorage(token), [saveTokenToStorage]);
  const refetchProfile = useMemo(() => () => getMyProfile().then((result) => setProfile(result)), [setProfile]);

  const requestLoginCode = useMemo(
    () => async () => {
      if (!loginCode || !loginCodeExpiry || isPast(new Date(loginCodeExpiry))) {
        const loginCodeParams = await getLoginCode();
        setLoginParams(loginCodeParams);
        return loginCodeParams.code;
      }
      return loginCode;
    },
    [loginCodeExpiry, loginCode, setLoginParams]
  );

  const observeLoginAuth = (code: string, onSuccess?: () => void) => {
    const socketChannel = listenForLogin(code);
    if (!socketChannel) {
      throw Error("Could not open socket channel. Has the application been wrapped with AuthContextProvider?");
    }
    socketChannel.bind("buyer.login", function (response: WebsocketResponse) {
      socketChannel.unsubscribe();
      saveJsonWebToken(response.jwt);
      disconnectPusher();
      setLoginSessionId("");
      refetchProfile();
      onSuccess && onSuccess();
    });
  };

  const loginUrl = loginSessionId ? `/login/code/${loginSessionId}` : "";

  const initLogin = (onSuccess?: () => void) => {
    if (loginSessionId) {
      setLoginSessionId("");
      disconnectPusher();
    } else {
      requestLoginCode().then((code) => {
        setLoginSessionId(code);
        observeLoginAuth(code, onSuccess);
      });
    }
  };

  const cancelLogin = () => setLoginSessionId("");

  return {
    profile,
    loginSessionId,
    loginUrl,
    initLogin,
    cancelLogin,
  };
}

export default useAuth;
