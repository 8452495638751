import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { useMachine } from "@xstate/react";
import authMachine from "@state-machines/auth-machine";
import { AuthContextProvider, Profile } from "@context/auth-context";
import { useState } from "react";
import RootRoutes from "@routes/index";
import { AppVariablesContextProvider } from "@context/app-variables-context";
import ScrollToTop from "@utils/scroll-to-top";
import { handleStatusErrors } from "@api/lib";
import { ModalContextProvider } from "@context/modal.context";
import ContextAlertModal from "@organisms/context-alert-modal/context-alert-modal";
import { LocalizationProvider } from "@material-ui/pickers";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import enLocale from "date-fns/locale/en-GB";
import ImagekitContext from "@context/imagekit";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: handleStatusErrors,
    },
    mutations: {
      onError: handleStatusErrors,
    },
  },
});

export default function App() {
  const [userProfile, setUserProfile] = useState<Profile>();
  useMachine(authMachine, {
    actions: {
      saveBearerToken: (context) => {
        // if (context.jsonWebToken)
      },
      saveProfile: (context) => setUserProfile(context.profile),
    },
  });

  return (
    <ImagekitContext>
      <LocalizationProvider dateAdapter={DateFnsUtils} locale={enLocale}>
        <QueryClientProvider client={queryClient}>
          <AppVariablesContextProvider>
            <AuthContextProvider userProfile={userProfile}>
              <ModalContextProvider>
                <ContextAlertModal />
                <BrowserRouter>
                  <ScrollToTop />

                  <RootRoutes />
                </BrowserRouter>
              </ModalContextProvider>
            </AuthContextProvider>
          </AppVariablesContextProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </LocalizationProvider>
    </ImagekitContext>
  );
}
