import { apiUrl, SuccessResponse, unAuthenticatedFetch } from "./lib";

export type StaticPageData = {
  slug: string;
  title: string;
  strapline: string;
  blocks: { type: string; heading: string; text: string }[];
};

export function fetchStaticPageContent(): Promise<StaticPageData[] | null> {
  return unAuthenticatedFetch(`${apiUrl}/pages/`).then(({ result }: SuccessResponse<StaticPageData[]>) => result);
}
