import { useEffect, useRef } from "react";

function useDidMount(cb: () => void | (() => void)) {
  const ref = useRef(cb);

  useEffect(() => {
    if (ref.current) {
      return ref.current();
    }
  }, []);

  return undefined;
}

export default useDidMount;
