import React, { useMemo } from "react";
import cx from "classnames";

const SkeletonLoader = ({ size = "large" }: { size?: "small" | "medium" | "large" }) => {
  const height = useMemo(() => {
    switch (size) {
      case "small":
        return "h-24";
      case "medium":
        return "h-52";
      case "large":
      default:
        return "";
    }
  }, [size]);
  return (
    <div className={cx("rounded-3xl bg-navy overflow-hidden w-full", height)}>
      <div className="h-full animate-pulse">
        <div className="w-full object-cover aspect-2/1 bg-background-gray" />
        <div className="h-1/2 px-4 py-5">
          <div className="w-4/5 h-8 bg-background-gray" />
          <div className="mt-2 w-1/2 h-8 bg-background-gray" />
          <div className="mt-6 w-1/3 h-5 bg-background-gray" />
          <div className="mt-6 flex items-center">
            <div className="w-10 h-10 rounded-lg bg-background-gray" />
            <div className="ml-2 w-1/3 h-5 bg-background-gray" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonLoader;
