import QrGradient from "@atoms/qr-gradient";
import TicketWalletStoreLink from "@organisms/ticket-wallet-store-link";
import React from "react";

type Props = {
  url: string;
};

const numberClasses = "border-2 border-white rounded-full w-9 h-9 items-center justify-center flex font-bold shrink-0";

const steps = ["Scan the QR code using the SeatlabNFT wallet app", "Approve & pay for your items with one click"];
function TicketWalletQrcodeCheckout({ url }: Props) {
  return (
    <div className="lg:flex lg:justify-evenly p-8">
      <div className="flex justify-between items-center lg:mr-8">
        <QrGradient {...{ url }} width={170} height={170} backgroundColor={"transparent"} />
        <div className="flex ml-4">
          {steps.map((txt, idx) => (
            <div key={`step-idx`} className="flex max-w-xs ml-4">
              <div className={numberClasses}>{idx + 1}</div>
              <p className="ml-4">{txt}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-8 items-center justify-center">
        <header className="font-medium text-xl text-white text-center">Don’t have the app yet?</header>
        <div className="flex w-80 mt-4 mx-auto">
          <div className="flex-1">
            <TicketWalletStoreLink platform="ios" />
          </div>
          <div className="ml-2" />
          <div className="flex-1">
            <TicketWalletStoreLink platform="android" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TicketWalletQrcodeCheckout;
