import BorderedCard from "@molecules/bordered-card/bordered-card";
import { useNavigate } from "react-router-dom";
import useModal from "@hooks/use-modal";

import React, { useState, useEffect } from "react";
import { PaymentRequestButtonElement, useStripe } from "@stripe/react-stripe-js";
import { PaymentRequest } from "@stripe/stripe-js/types/stripe-js/payment-request";

type Props = {
  totalPrice: number;
  clientSecret: string;
  stripeCountry: string;
  stripeCurrency: string;
};

export default function QuickPaymentSection({ totalPrice, clientSecret, stripeCountry, stripeCurrency }: Props) {
  const { showModal } = useModal();
  const navigate = useNavigate();

  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null);

  useEffect(() => {
    if (stripe) {
      const paymentRequest = stripe.paymentRequest({
        country: stripeCountry,
        currency: stripeCurrency.toLowerCase(),
        total: {
          label: "SeatlabNFT",
          amount: totalPrice,
        },
        requestPayerName: true,
        requestPayerEmail: false,
      });

      // Check the availability of the Payment Request API.
      paymentRequest.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(paymentRequest);
        }
      });

      paymentRequest.on("paymentmethod", async (ev) => {
        // Confirm the PaymentIntent without handling potential next actions (yet).
        const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
          clientSecret,
          { payment_method: ev.paymentMethod.id },
          { handleActions: false }
        );

        if (confirmError) {
          // Report to the browser that the payment failed, prompting it to
          // re-show the payment interface, or show an error message and close
          // the payment interface.
          ev.complete("fail");
        } else {
          // Report to the browser that the confirmation was successful, prompting
          // it to close the browser payment method collection interface.
          ev.complete("success");
          // Check if the PaymentIntent requires any actions and if so let Stripe.js
          // handle the flow. If using an API version older than "2019-02-11"
          // instead check for: `paymentIntent.status === "requires_source_action"`.
          if (paymentIntent.status === "requires_action") {
            // Let Stripe.js handle the rest of the payment flow.
            const { error } = await stripe.confirmCardPayment(clientSecret);
            if (error) {
              // The payment failed -- ask your customer for a new payment method.
              console.error(error.message);
              showModal({ title: "An error occurred", children: error.message });
            } else {
              navigate("/purchase-success");
            }
          } else {
            navigate("/purchase-success");
          }
        }
      });
    }
  }, [clientSecret, navigate, showModal, stripe, totalPrice]);

  return (
    paymentRequest && (
      <BorderedCard title="Quick pay">
        <PaymentRequestButtonElement options={{ paymentRequest }} />
      </BorderedCard>
    )
  );
}
