import { ClaimableItem, Collectible } from "@api/collectibles";
import ClaimableCard from "@molecules/claimable-card";

type Props = {
  collectible: Collectible;
  claimables: ClaimableItem[];
};

const CollectibleDetailClaimables = ({ collectible, claimables }: Props) => {
  return (
    <>
      <h1 className="text-2xl md:text-3xl mb-2 md:mb-5 font-bold">{collectible.title}</h1>
      <div className="flex mb-2 justify-center flex-col bg-dark-panel rounded-3xl overflow-hidden md:hidden">
        <img className="h-96 object-contain" src={collectible.imageUrl} alt="Collectible" />
      </div>
      <div className="flex flex-col gap-3 mb-4">
        {claimables.map((claimable, index) => (
          <ClaimableCard key={index} name={claimable.name} itemCount={claimable.itemCount} />
        ))}
      </div>
    </>
  );
};

export default CollectibleDetailClaimables;
