import { Collectible } from "@api/collectibles";
import ImagekitImage from "@molecules/imagekit-image/imagekit-image";
import { priceFormatter } from "@utils/formatters";
import { ReactNode } from "react";
import { Link, useNavigate } from "react-router-dom";
import CurrencySymbol from "@atoms/currency-symbol";
import Button from "@atoms/button";

export default function IframeCollectibleListMobileCard({
  detailUrl,
  imageUrl,
  title,
  price,
  seller,
}: Collectible & { loading?: boolean; detailUrl: string }) {
  const navigate = useNavigate();
  const clickableText = (child: ReactNode) => <Link to={detailUrl}>{child}</Link>;

  return (
    <div className="flex flex-row h-48 w-full">
      {clickableText(
        <span className="inline-block relative rounded-2xl overflow-hidden mt-5 w-39">
          <span className="absolute bg-gradient-to-b from-transparent to-black top-0 left-0 w-full h-max-48 z-1 opacity-30" />
          <ImagekitImage src={imageUrl} className="object-cover max-h-40 w-30" alt="Event thumbnail" />
        </span>
      )}
      <div className="flex flex-col items-between justify-between mx-1 text-white w-full">
        <div />
        <div className="flex flex-col gap-1 mt-1">
          <h1 className="text-lg font-bold ml-2">{clickableText(title)}</h1>
          {clickableText(
            <>
              <span className="flex flex-row space-x-2">
                <span className="flex items-center w-full">
                  <span className="pl-2">
                    <span className="flex flex-row items-start space-x-2 text-white text-lg">
                      <CurrencySymbol text={seller.currencySymbol} />
                      {price ? priceFormatter(price) : "Free"}
                    </span>
                  </span>
                </span>
              </span>
            </>
          )}
        </div>
        <span className="flex flex-row items-center space-x-5">
          <Button
            size="small"
            className="w-full max-w-[250px] mt-4"
            onClick={() => {
              void navigate(`${detailUrl}`);
            }}
          >
            Buy now
          </Button>
        </span>
      </div>
    </div>
  );
}
