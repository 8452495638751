import { SVGProps } from "react";

const DollarCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30.334 60C13.9807 60 0.724609 46.569 0.724609 30C0.724609 13.431 13.9807 0 30.334 0C46.6872 0 59.9434 13.431 59.9434 30C59.9434 46.569 46.6872 60 30.334 60ZM30.334 54C36.6163 54 42.6413 51.4714 47.0836 46.9706C51.5258 42.4697 54.0215 36.3652 54.0215 30C54.0215 23.6348 51.5258 17.5303 47.0836 13.0294C42.6413 8.52856 36.6163 6 30.334 6C24.0517 6 18.0267 8.52856 13.5844 13.0294C9.14213 17.5303 6.64648 23.6348 6.64648 30C6.64648 36.3652 9.14213 42.4697 13.5844 46.9706C18.0267 51.4714 24.0517 54 30.334 54ZM19.9707 36H36.2559C36.6485 36 37.0251 35.842 37.3027 35.5607C37.5804 35.2794 37.7363 34.8978 37.7363 34.5C37.7363 34.1022 37.5804 33.7206 37.3027 33.4393C37.0251 33.158 36.6485 33 36.2559 33H24.4121C22.4489 33 20.5661 32.2098 19.1779 30.8033C17.7897 29.3968 17.0098 27.4891 17.0098 25.5C17.0098 23.5109 17.7897 21.6032 19.1779 20.1967C20.5661 18.7902 22.4489 18 24.4121 18H27.373V12H33.2949V18H40.6973V24H24.4121C24.0195 24 23.6429 24.158 23.3653 24.4393C23.0876 24.7206 22.9316 25.1022 22.9316 25.5C22.9316 25.8978 23.0876 26.2794 23.3653 26.5607C23.6429 26.842 24.0195 27 24.4121 27H36.2559C38.2191 27 40.1019 27.7902 41.4901 29.1967C42.8783 30.6032 43.6582 32.5109 43.6582 34.5C43.6582 36.4891 42.8783 38.3968 41.4901 39.8033C40.1019 41.2098 38.2191 42 36.2559 42H33.2949V48H27.373V42H19.9707V36Z"
      fill="white"
    />
  </svg>
);

export default DollarCircle;
