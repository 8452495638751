import ImageKit from "imagekit-javascript";

const urlEndpoint = "https://ik.imagekit.io/seatlabnft";

const imagekit = new ImageKit({
  urlEndpoint,
});

export const isImageKitUrl = (url: string) => url.startsWith(urlEndpoint);

export default imagekit;
