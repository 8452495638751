import { Event } from "@api/events";
import EventListCard from "@molecules/event-list-card";
import EventCard from "@molecules/event-card";
import { useNavigate } from "react-router-dom";
import React from "react";
type EventsListingProps = {
  events?: Event[];
  isLoading: boolean;
};

function EventListing({ events, isLoading }: EventsListingProps) {
  const navigate = useNavigate();

  return (
    <div className="grid grid-cols-1 gap-y-8 md:block">
      {events?.map((event, index) => {
        const detailUrl = `/events/${event.slug}`;
        return (
          <React.Fragment key={`/events/${event.slug}`}>
            <div className="hidden md:block" key={event.id}>
              <EventListCard {...event} loading={isLoading} {...{ detailUrl }} />
              {index + 1 !== events.length && <div className="h-px my-6 bg-white/50" />}
            </div>
            <div className="md:hidden">
              <EventCard
                {...event}
                onClick={() => navigate(detailUrl)}
                imgUrl={event.thumbnailUrl}
                loading={isLoading}
                collectibles
                remainingTickets={event.ticketTypes.reduce((a, b) => a + b.ticketsRemaining, 0)}
              />
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default EventListing;
