import { Seller } from "@api/seller";
import Avatar from "@atoms/avatar";
import BadgeInfo from "@atoms/badge-info";
import { MapPin } from "@atoms/icons";
import SkeletonLoader from "@atoms/skeleton-card-loader";
import EventDateTime from "@molecules/event-date-time";
import { priceFormatter } from "@utils/formatters";
import classNames from "classnames";
import { useEffect, useState } from "react";
import ImagekitImage from "@molecules/imagekit-image/imagekit-image";
import CurrencySymbol from "@atoms/currency-symbol";

export type Props = {
  name: string;
  startDate: Date;
  endDate: Date;
  imgUrl: string;
  remainingTickets: number | string;
  loading?: boolean;
  onClick?: () => void;
  seller: Seller;
  collectibles?: boolean;
  lowestPrice: number;
  venueName?: string;
};

export default function EventCard({
  name,
  startDate,
  endDate,
  imgUrl,
  remainingTickets,
  loading,
  onClick,
  seller,
  lowestPrice,
  venueName,
}: Props) {
  const [showEvent, setShowEvent] = useState(false);

  useEffect(() => {
    if (showEvent) return;
    if (loading === false) setShowEvent(true);
  }, [loading, showEvent]);

  return (
    <div className="rounded-3xl overflow-hidden shadow-lg relative w-full">
      <div className={classNames("w-full", { absolute: !loading })} children={<SkeletonLoader />} />
      <div
        className="relative opacity-0 duration-1000 transition-opacity delay-300 h-full"
        style={{ opacity: showEvent ? 1 : 0 }}
      >
        {!loading && (
          <div className="bg-navy w-full pb-5 flex flex-col h-full">
            <div className={classNames({ "cursor-pointer": !!onClick })} onClick={onClick}>
              <div className="overflow-hidden relative">
                <span className="absolute bg-gradient-to-t from-black top-0 left-0 w-full h-full z-1 opacity-70" />
                <ImagekitImage
                  src={imgUrl}
                  transformation={[{ width: "350" }]}
                  className="w-full object-cover aspect-2/1"
                />
                <div className="absolute bottom-0 w-full flex flex-row items-center gap-4 px-4 pb-4">
                  <div className="text-white flex-1">
                    <Avatar
                      border="holographic"
                      imgSrc={seller.thumbnailUrl}
                      label={seller.name || seller.walletAddress}
                      size="medium"
                      verified={seller.isVerified}
                    />
                  </div>
                  <div className="w-[100px]">
                    <BadgeInfo count={remainingTickets} text="Tickets:" variant="alert" />
                  </div>
                </div>
              </div>
            </div>

            <div className="px-4 py-5 h-1/2 text-white flex flex-col justify-between grow">
              <div>
                <h3
                  className={classNames("text-xl mb-2 font-bold truncate max-w-[300px]", {
                    "cursor-pointer": !!onClick,
                  })}
                  onClick={onClick}
                >
                  {name}
                </h3>
                <EventDateTime startDate={startDate} dateFormat="dd/MM/yy" endDate={endDate} fontSize="text-lg" />
                <div className="mb-5" />
              </div>
              <div className="flex flex-row justify-between items-center">
                <span className="flex space-x-3">
                  <MapPin />
                  <p className="truncate max-w-[180px]">{venueName}</p>
                </span>
                <span className="flex flex-row items-center space-x-2">
                  <p className="text-lg">
                    <CurrencySymbol text={seller.currencySymbol} />{lowestPrice ? priceFormatter(lowestPrice) : "0.00"}
                  </p>
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
