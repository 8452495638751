import classNames from "classnames";
export interface ISVGProps {
  size?: "xsmall" | "small" | "medium" | "large" | "xlarge" | "xxlarge";
  icon: React.ElementType;
  styles?: string;
  width?: number | string;
  height?: number | string;
  color?: string;
  onClick?: () => void;
}

const iconSizeConversion = (size: string): string | number => {
  switch (size) {
    case "xsmall":
      return 12;
    case "small":
      return 16;
    case "medium":
      return 20;
    case "large":
      return 24;
    case "xlarge":
      return 29;
    case "xxlarge":
      return 32;
    default:
      return "100%";
  }
};

const SVG = ({ size = "medium", icon, height, width, color, onClick }: ISVGProps) => {
  const Icon = icon;
  return (
    <span
      onClick={onClick}
      className={classNames(`flex align-center justify-center`)}
      role="presentation"
      style={{
        height: height ?? iconSizeConversion(size),
        width: width ?? iconSizeConversion(size),
      }}
    >
      <Icon color={color} preserveAspectRatio="xMidYMid" />
    </span>
  );
};

export default SVG;
