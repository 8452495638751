import IntlTelInput, { CountryData } from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import cn from "classnames";
import React, { InputHTMLAttributes } from "react";

export type Props = Omit<InputHTMLAttributes<HTMLInputElement>, "onChange" | "onBlur" | "value" | "defaultValue"> & {
  defaultValue?: string;
  className?: string;
  containerClass?: string;
  label?: string;
  value: string;
  onChange: (...args: OnChangeParams) => void;
  onBlur?: (e: React.FocusEvent<HTMLElement, Element>, isValid: boolean) => void;
  error?: string;
};

type OnChangeParams = Parameters<
  (isValid: boolean, value: string, selectedCountryData: CountryData, fullNumber: string, extension?: string) => void
>;
type OnBlurParams = [...OnChangeParams, ...Parameters<(event: React.FocusEvent<HTMLInputElement>) => void>];

const MobilePrefixSelector = (props: Props) => {
  const { disabled, required, className, onBlur, value, onChange, containerClass, label, error, name, id } = props;

  const labelBasePositioning = cn("transition-transform origin-top-left absolute top-4 left-24");
  const labelClass = cn(
    labelBasePositioning,
    "text-grey z-10 items-center flex pointer-events-none scale-75 -translate-y-2"
  );

  const inputStyle = cn(
    "outline-none text-heading3 rounded-md text-white h-16 p-4 bg-brand-black w-full px-4 pt-7 pb-2",
    !!error ? "pr-9" : "pr-4",
    { "ring-2 ring-inset ring-danger bg-danger/20": !!error },
    { "bg-opacity-60": disabled },
    className
  );

  const onFieldBlur = (...args: OnBlurParams) => {
    const [isValid, , , , , event] = args;
    if (onBlur) onBlur(event, isValid);
  };

  return (
    <div className="relative mt-4 lg:mt-0">
      {label && <label className={labelClass}>{label}</label>}

      {error && <span className="text-danger absolute -top-4 lg:top-2 right-4 text-xs">{error}</span>}
      <IntlTelInput
        excludeCountries={["CU", "IR", "KP", "SY", "RU", "UA"]}
        preferredCountries={["GB", "US", "NZ", "AE"]}
        value={value}
        inputClassName={inputStyle}
        onPhoneNumberChange={onChange}
        fieldName={name}
        fieldId={id}
        aria-required={required}
        aria-invalid={!!error}
        {...{ disabled, required }}
        onSelectFlag={(number, country, fullnumber, valid) => onChange(valid, number, country, fullnumber)}
        containerClassName={`intl-tel-input w-full ${containerClass}`}
        onPhoneNumberBlur={onFieldBlur}
      />
    </div>
  );
};

export default MobilePrefixSelector;
