import Button from "@atoms/button";
import PageContainer from "@atoms/page-container";
import { useNavigate } from "react-router-dom";
import MysteryBox from "../../../assets/mystery-box.png";

const backgroundStyle = {
  backgroundImage: `url(${MysteryBox})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "contain",
};

const NotFound = () => {
  const navigate = useNavigate();
  const goHome = () => navigate("/");
  return (
    <PageContainer>
      <div className="flex flex-col items-center text-center text-white">
        <div style={backgroundStyle}>
          <p className="text-5xl font-bold mb-12">Lost in the metaverse</p>
          <p className="text-9xl font-bold mb-8">404</p>
          <p className="text-xl text-center max-w-[844px]">
            The page you are looking for might have been renamed, removed, or may never have existed on this planet.
          </p>
        </div>
        <Button onClick={goHome} className="sm:w-[410px] mt-7">
          Go to homepage
        </Button>
      </div>
    </PageContainer>
  );
};

export default NotFound;
