import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.582 2.74994H18.2487C18.4918 2.74994 18.725 2.84652 18.8969 3.01843C19.0688 3.19034 19.1654 3.4235 19.1654 3.66661V18.3333C19.1654 18.5764 19.0688 18.8095 18.8969 18.9815C18.725 19.1534 18.4918 19.2499 18.2487 19.2499H1.7487C1.50558 19.2499 1.27242 19.1534 1.10052 18.9815C0.928608 18.8095 0.832031 18.5764 0.832031 18.3333V3.66661C0.832031 3.4235 0.928608 3.19034 1.10052 3.01843C1.27242 2.84652 1.50558 2.74994 1.7487 2.74994H5.41536V0.916611H7.2487V2.74994H12.7487V0.916611H14.582V2.74994ZM12.7487 4.58328H7.2487V6.41661H5.41536V4.58328H2.66536V8.24994H17.332V4.58328H14.582V6.41661H12.7487V4.58328ZM17.332 10.0833H2.66536V17.4166H17.332V10.0833ZM4.4987 12.8333H6.33203V14.6666H4.4987V12.8333ZM8.16536 12.8333H15.4987V14.6666H8.16536V12.8333Z"
      fill="white"
    />
  </svg>
);

export default SvgComponent;
