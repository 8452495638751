import Gym from "./gym.png";
import Microphone from "./microphone.png";
import Theatre from "./theatre.png";

function HeroImages() {
  return (
    <div className="flex relative items-start">
      <div children={<img alt="hero-img-1" className="" src={Microphone} />} />
      <div children={<img alt="hero-img-2" src={Gym} />} className="-ml-24 mt-20" />
      <div children={<img alt="hero-img-3" src={Theatre} />} className="-ml-24 mt-40" />
    </div>
  );
}
export default HeroImages;
