import { ReactNode, useState } from "react";
import cn from "classnames";
import { ChevronDown, ChevronUp } from "@atoms/icons";
export type EventDetailsPanelProps = {
  title?: string;
  children?: ReactNode;
  footer?: ReactNode;
  containerClassName?: string;
  contentClassName?: string;
  headerClassName?: string;
  collapsible?: boolean;
  footerClassName?: string;
};
export default function EventDetailsPanel({
  title,
  containerClassName,
  contentClassName,
  headerClassName,
  children,
  collapsible,
  footer,
  footerClassName,
}: EventDetailsPanelProps) {
  const [isExpanded, setIsExpanded] = useState(true);
  return (
    <div
      className={cn(
        "w-full flex flex-col rounded-lg border-2 border-brand-black overflow-hidden",
        containerClassName,
        !isExpanded && "h-[64px]" // TODO Fix this
      )}
    >
      {title && (
        <div
          className={cn(
            "min-h-[64px] border-b-2 border-brand-black bg-layer-blue px-6 flex justify-between items-center cursor-pointer",
            headerClassName
          )}
          onClick={() => collapsible && setIsExpanded((s) => !s)}
        >
          <h2 className="text-l md:text-xl text-white font-bold">{title}</h2>
          {collapsible && <div>{isExpanded ? <ChevronUp /> : <ChevronDown />}</div>}
        </div>
      )}
      {isExpanded && (
        <div
          className={cn(
            "w-full h-full bg-dark-panel px-6 overflow-auto scrollbar-thin scrollbar-thumb-grey scrollbar-track-slate/50 scrollbar-thumb-rounded",
            contentClassName
          )}
        >
          {children}
        </div>
      )}
      {footer && (
        <div className={cn("border-t-2 h-[78px] border-brand-black justify-self-end", footerClassName)}>{footer}</div>
      )}
    </div>
  );
}
