import { Collectible } from "@api/collectibles";
import Avatar from "@atoms/avatar";
import Button from "@atoms/button";
import PageContainer from "@atoms/page-container";
import CollectibleDetailClaimables from "@organisms/collectible-detail-claimables/";
import CollectibleDetailDescription from "@organisms/collectible-detail-description";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { CollectableDetailImage } from "@molecules/collectable-detail-Image/collectable-detail-Image";
import { UseMutationResult } from "react-query";
import CollectibleBuySection from "@molecules/collectible-buy-section";

type CollectibleDetailsProps = {
  collectible: Collectible;
  onPurchaseCollectible: UseMutationResult<any, unknown, string, unknown>;
};

const tabs = {
  description: "DESCRIPTION",
  claimables: "CLAIMABLES",
};

export default function CollectibleDetails({ collectible, onPurchaseCollectible }: CollectibleDetailsProps) {
  const isMobile = useMediaQuery({ query: "(max-width: 420px)" });
  const claimables = collectible?.claimableItems;
  const hasClaimables = claimables && claimables.length > 0;
  const [activeTab, setActiveTab] = useState<string>(tabs.description);

  const { seller } = collectible;

  return (
    <PageContainer>
      <div className="md:flex justify-between items-start gap-8  md:mt-10">
        <div className="flex md:w-3/5 flex-col">
          <span className="flex flex-col sm:flex-row  sm:items-center font-bold text-sm sm:text-xl text-white mb-4">
            Creator:{" "}
            <Avatar
              containerClassName="sm:ml-4 mt-1 sm:mt-0"
              imgSrc={seller.thumbnailUrl}
              label={seller.name || seller.walletAddress}
              size="medium"
              labelClassName="text-white"
              verified={seller.isVerified}
            />
          </span>

          {hasClaimables && (
            <span>
              <Button
                className="-mb-px mr-5"
                onClick={() => setActiveTab(tabs.description)}
                active={activeTab === tabs.description}
                variant="underline"
                size={isMobile ? "medium" : "xlarge"}
              >
                Description
              </Button>
              <Button
                className="-mb-px"
                onClick={() => setActiveTab(tabs.claimables)}
                active={activeTab === tabs.claimables}
                variant="underline"
                size={isMobile ? "medium" : "xlarge"}
              >
                Claimables
              </Button>
            </span>
          )}
          <hr className="mb-5 md:mb-10 opacity-40 bg-brand-black" />
          <div className=" text-white">
            {activeTab === tabs.description ? (
              <CollectibleDetailDescription collectible={collectible} />
            ) : (
              <CollectibleDetailClaimables collectible={collectible} claimables={claimables} />
            )}
            <CollectibleBuySection
              price={collectible.price}
              purchaseCollectible={() => onPurchaseCollectible.mutate(collectible.uuid)}
              disabled={onPurchaseCollectible.isLoading}
              currencySymbol={collectible.seller.currencySymbol}
            />
          </div>
        </div>

        <div className="hidden md:block md:w-2/5">
          <CollectableDetailImage {...collectible} />
        </div>
      </div>
    </PageContainer>
  );
}
