import * as React from "react";
import { SVGProps } from "react";

const HamburgerMenu = (props: SVGProps<SVGSVGElement>) => (
  <svg width={28} height={22} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M.875 1.125h26.25m-26.25 20h26.25H.875Zm0-10h26.25H.875Z"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default HamburgerMenu;
