import { LoadingSpinner } from "@atoms/icons";
import usePurchaseRequest from "@hooks/use-purchase-request";
import { useCallback, useEffect } from "react";
import { Checkout } from "../checkout/checkout";

type Props = {
  title: string;
  purchaseCode: string;
  onPurchaseExpiry: (redirectUrl: string) => void;
};

function CollectibleCheckout({ title, purchaseCode, onPurchaseExpiry }: Props) {
  const { requestQuery, processPurchase, purchaseExpiry, totalPrice, priceList, completeFreePurchase } =
    usePurchaseRequest("collectible", purchaseCode);

  const handlePurchaseExpiryRedirect = useCallback(() => {
    const redirectUrl = !!requestQuery.data
      ? `/collectables/${requestQuery.data.collectibles[0].uuid}`
      : "/collectables";
    onPurchaseExpiry(redirectUrl);
  }, [onPurchaseExpiry, requestQuery.data]);

  const onProcessPurchase = ({
    email,
    mobile,
    firstname,
    lastname,
    custom_question_1,
    custom_question_2,
    custom_answer_1,
    custom_answer_2,
  }: {
    email: string;
    mobile: string;
    firstname: string;
    lastname: string;
    custom_question_1: string | null;
    custom_question_2: string | null;
    custom_answer_1: string | null;
    custom_answer_2: string | null;
  }) => {
    return processPurchase.mutateAsync({
      email,
      phone: mobile,
      code: purchaseCode,
      firstname,
      lastname,
      custom_question_1,
      custom_question_2,
      custom_answer_1,
      custom_answer_2,
    });
  };

  useEffect(() => {
    if (requestQuery.isError && (requestQuery.error as any)?.message === "Code expired") handlePurchaseExpiryRedirect();
  }, [requestQuery.isError, requestQuery.error, handlePurchaseExpiryRedirect]);

  if (!requestQuery.isSuccess) return <div className="w-24 h-24 mx-auto" children={<LoadingSpinner />} />;

  const currencySymbol = requestQuery.data ? requestQuery.data.collectibles[0].seller.currencySymbol : "GBP";

  return (
    <Checkout
      type="collectible"
      {...{
        onProcessPurchase,
        purchaseExpiry,
        title,
        handlePurchaseExpiryRedirect,
        priceList,
        totalPrice,
        completeFreePurchase,
      }}
      collectiblePurchaseDetails={requestQuery.data?.collectibles}
      currencySymbol={currencySymbol}
    />
  );
}

export default CollectibleCheckout;
