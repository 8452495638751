import { Collectible } from "@api/collectibles";
import { EventQuestion, FetchedEvent } from "@api/events";
import { FetchedTicket } from "@api/tickets";
import Button from "@atoms/button";
import GradientBorderSection from "@atoms/gradient-border-section";
import Input from "@atoms/input";
import MobilePrefixSelector from "@molecules/mobile-prefix-selector";
import { Field, FieldProps, Formik, isObject, isString } from "formik";
import * as yup from "yup";
import useEvents from "@hooks/use-events";
import { useMemo, useState } from "react";

export type Props = {
  onSubmit: (params: {
    email: string;
    mobile: string;
    fullNumber: string;
    firstname: string;
    lastname: string;
    custom_question_1: string | null;
    custom_question_2: string | null;
    custom_answer_1: string | null;
    custom_answer_2: string | null;
  }) => void;
  disabled?: boolean;
  occasion?: FetchedEvent;
  ticketPurchaseDetails?: { ticketType: FetchedTicket; quantity: number }[];
  collectiblePurchaseDetails?: Collectible[];
  priceList: [string, number][];
  totalPrice?: number;
  currencySymbol: string;
};

export default function CheckoutPrePaymentForm({
  ticketPurchaseDetails,
  collectiblePurchaseDetails,
  onSubmit,
  disabled,
  occasion,
  priceList,
  totalPrice,
  currencySymbol,
}: Props) {
  const { fetchEventQuestions } = useEvents();
  const [questions, setQuestions] = useState<EventQuestion[]>([]);
  const [questionsFetched, setQuestionsFetched] = useState(false);

  const title = !!collectiblePurchaseDetails ? "Reserve your collectable" : "Reserve your ticket";
  const description = !!collectiblePurchaseDetails
    ? "Your ticket wallet is tied to your mobile number; it’s vital that you enter the correct number below. If you enter the wrong number, your collectable will be sent to the wrong device."
    : "Your ticket wallet is tied to your mobile number; it’s vital that you enter the correct number below. If you enter the wrong number, your tickets will be sent to the wrong device.";

  useMemo(() => {
    if (isObject(occasion) && isString(occasion?.slug) && !questionsFetched) {
      fetchEventQuestions(occasion?.slug)
        .then((data: EventQuestion[]) => {
          setQuestions(data);
          setQuestionsFetched(true);
        })
        .catch((error) => {
          console.log(error);
          setQuestions([]);
          setQuestionsFetched(true);
        });
    }
  }, [occasion, questions]);

  let initialValues = {
    email: "",
    mobile: "",
    extension: "",
    fullNumber: "",
    confirmMobile: "",
    firstname: "",
    lastname: "",
    custom_question_1: isObject(questions[0]) ? questions[0].id.toString() : "",
    custom_question_2: isObject(questions[1]) ? questions[1].id.toString() : "",
    custom_answer_1: "",
    custom_answer_2: "",
  };

  let validationRules = {
    email: yup.string().email().required("Required"),
    mobile: yup.string().required("Required"),
    fullNumber: yup.string().required(),
    confirmMobile: yup
      .string()
      .required("Required")
      .test("match", "Numbers do not match", function (value) {
        const mobile = this.parent.mobile;
        return value === mobile;
      }),
    firstname: yup.string().required("Required"),
    lastname: yup.string().required("Required"),
    custom_question_1: yup.string().nullable(true),
    custom_question_2: yup.string().nullable(true),
    custom_answer_1:
      isObject(questions[0]) && questions[0].mandatory
        ? yup.string().required("Required")
        : yup.string().nullable(true),
    custom_answer_2:
      isObject(questions[1]) && questions[1].mandatory
        ? yup.string().required("Required")
        : yup.string().nullable(true),
  };

  const validationSchema = yup.object().shape(validationRules);

  return (
    <Formik
      {...{ validationSchema, initialValues }}
      onSubmit={(values, formikHelpers) => {
        const fullNumber = values.extension + values.mobile;
        const email = values.email;
        const mobile = values.mobile;
        const firstname = values.firstname;
        const lastname = values.lastname;
        const custom_question_1 = initialValues.custom_question_1;
        const custom_question_2 = initialValues.custom_question_2;
        const custom_answer_1 = values.custom_answer_1;
        const custom_answer_2 = values.custom_answer_2;

        onSubmit({
          email,
          mobile,
          fullNumber,
          firstname,
          lastname,
          custom_question_1,
          custom_question_2,
          custom_answer_1,
          custom_answer_2,
        });
      }}
    >
      {({ handleSubmit }) => (
        <>
          <GradientBorderSection className="bg-[#1A2743] relative px-7 py-8 text-white mb-8">
            <div className="flex flex-col md:flex-row">
              <div className="basis-[55%] md:pr-12">
                <header className="text-2xl font-bold mb-4">{title}</header>
                <p className="text-lg">{description}</p>
                <div className="h-[1px] w-full bg-white opacity-20 my-5" />
                <p className="font-bold mb-3">
                  The mobile number must be associated with the device where you’ll install the app.
                </p>
                <Field name="mobile">
                  {({ field, form, meta }: FieldProps) => (
                    <MobilePrefixSelector
                      label="Mobile number"
                      {...{ ...field, disabled }}
                      error={meta.touched ? meta.error : undefined}
                      onBlur={(e, valid) => {
                        field.onBlur(e);
                        if (!valid)
                          setTimeout(() => form.setFieldError(field.name, "Please enter a valid phone number"), 0);
                      }}
                      onChange={(valid, value, country, fullNumber) => {
                        form.setFieldTouched(field.name);
                        form.setFieldValue("extension", `+${country.dialCode}`);
                        form.setFieldValue(field.name, value);
                        form.setFieldValue("fullNumber", fullNumber);
                        if (!valid)
                          setTimeout(() => form.setFieldError(field.name, "Please enter a valid phone number"), 0);
                      }}
                    />
                  )}
                </Field>

                <p className="mb-3 mt-6 text-sm">Confirm mobile number:</p>
                <Field name="confirmMobile">
                  {({ field, form, meta }: FieldProps) => (
                    <MobilePrefixSelector
                      {...{ ...field, disabled }}
                      error={meta.touched ? meta.error : undefined}
                      label="Mobile number"
                      onChange={(_, value) => {
                        form.setFieldTouched(field.name);
                        form.setFieldValue(field.name, value);
                      }}
                    />
                  )}
                </Field>
              </div>
              <div className="basis-[45%] flex flex-col justify-between">
                <div className="h-[1px] w-full bg-white opacity-20 my-8 md:hidden" />
                <div className="order-last md:order-first">
                  {!!occasion && !!ticketPurchaseDetails && (
                    <>
                      <p className="text-sm mb-3">You are purchasing the following ticket:</p>
                      {ticketPurchaseDetails?.map(({ ticketType, quantity }) => (
                        <div
                          key={ticketType.uuid}
                          className="bg-navy px-4 py-5 rounded-3xl shadow font-bold flex justify-between items-center mb-9"
                        >
                          <div>
                            <p className="text-lg">{ticketType.name}</p>
                            <p>{occasion.name}</p>
                          </div>
                          <span className="h-8 w-8 border rounded-full border-dark-blue font-normal flex items-center justify-center">
                            x{quantity}
                          </span>
                        </div>
                      ))}
                    </>
                  )}
                  {!!collectiblePurchaseDetails && (
                    <>
                      <p className="text-sm mb-3">You are purchasing the following ticket:</p>
                      {collectiblePurchaseDetails.map(({ title }) => (
                        <div
                          key={title}
                          className="bg-navy px-4 py-5 rounded-3xl shadow font-bold flex justify-between items-center mb-9"
                        >
                          <p className="text-lg">{title}</p>
                        </div>
                      ))}
                    </>
                  )}
                  {priceList.map(([label, value]) => (
                    <div className="flex flex-row justify-between" key={label}>
                      <p>{label}</p>{" "}
                      <p>
                        {currencySymbol}
                        {(value / 100).toFixed(2)}
                      </p>
                    </div>
                  ))}
                  <div className="h-[1px] w-full bg-white opacity-20 my-3" />
                  {typeof totalPrice === "number" && (
                    <div className="flex flex-row justify-between font-bold text-xl">
                      <p>Total</p>{" "}
                      <p>
                        {currencySymbol}
                        {(totalPrice / 100).toFixed(2)}
                      </p>
                    </div>
                  )}
                </div>
                <div className="order-first md:order-last">
                  <p className="text-sm mb-3 mt-8">
                    Provide your email address so we can contact you in case of any issues:
                  </p>
                  <Field name="email">
                    {({ field, meta }: FieldProps) => (
                      <Input
                        {...{ ...field, disabled }}
                        error={meta.touched ? meta.error : undefined}
                        label="Email address"
                        type="email"
                        required
                      />
                    )}
                  </Field>
                </div>
              </div>
            </div>
          </GradientBorderSection>
          <GradientBorderSection className="bg-[#1A2743] relative px-7 py-8 text-white mb-8">
            <header className="text-2xl font-bold">Personal Details</header>
            <div className="flex flex-col md:flex-row">
              <div className="h-[1px] w-full bg-white opacity-20 my-5" />
            </div>
            <div className="flex flex-col md:flex-row">
              <p className="text-sm mb-2 mt-2">Enter your personal information:</p>
            </div>
            <div className="flex flex-col md:flex-row mb-4">
              <div className="basis-[50%] md:pr-12">
                <Field name="firstname">
                  {({ field, meta }: FieldProps) => (
                    <Input
                      {...{ ...field, disabled }}
                      error={meta.touched ? meta.error : undefined}
                      label="First Name"
                      type="text"
                      required={true}
                    />
                  )}
                </Field>
              </div>
              <div className="basis-[50%] flex flex-col justify-between xs:pt-2 md:pt-0">
                <Field name="lastname">
                  {({ field, meta }: FieldProps) => (
                    <Input
                      {...{ ...field, disabled }}
                      error={meta.touched ? meta.error : undefined}
                      label="Surname"
                      type="text"
                      required={true}
                    />
                  )}
                </Field>
              </div>
            </div>
            {questions.length > 0 && (
              <div className="flex flex-col md:flex-row">
                <div className="basis-[50%] md:pr-12">
                  {isObject(questions[0]) && (
                    <Field name="custom_answer_1">
                      {({ field, meta }: FieldProps) => (
                        <Input
                          {...{ ...field, disabled }}
                          error={meta.touched ? meta.error : undefined}
                          label={questions[0].question}
                          type="text"
                          required={questions[0].mandatory}
                        />
                      )}
                    </Field>
                  )}
                </div>
                <div className="basis-[50%] flex flex-col justify-between">
                  {isObject(questions[1]) && (
                    <Field name="custom_answer_2">
                      {({ field, meta }: FieldProps) => (
                        <Input
                          {...{ ...field, disabled }}
                          error={meta.touched ? meta.error : undefined}
                          label={questions[1].question}
                          type="text"
                          required={questions[1].mandatory}
                        />
                      )}
                    </Field>
                  )}
                </div>
              </div>
            )}

            <Button type="button" className="mt-6" onClick={() => handleSubmit()} disabled={disabled}>
              Continue
            </Button>
          </GradientBorderSection>
        </>
      )}
    </Formik>
  );
}
