import React, { HTMLAttributes } from "react";
import cx from "classnames";

export enum HeadingVariant {
  heading1 = "heading1",
  heading2 = "heading2",
  heading3 = "heading3",
  heading4 = "heading4",
  heading5 = "heading5",
}

type Props<P> = P & {
  as?: any;
  variant: keyof typeof HeadingVariant | HeadingVariant;
  className?: string;
  children: React.ReactNode;
};

function getFontWeightClass(variant: keyof typeof HeadingVariant) {
  switch (variant) {
    case HeadingVariant.heading1:
      return "font-bold";
    case HeadingVariant.heading3:
    case HeadingVariant.heading2:
      return "font-medium";
    case HeadingVariant.heading5:
    case HeadingVariant.heading4:
    default:
      return "font-medium";
  }
}

function getFontSizeClass(variant: keyof typeof HeadingVariant) {
  switch (variant) {
    case HeadingVariant.heading1:
      return "text-2xl";
    case HeadingVariant.heading3:
      return "text-sm";
    case HeadingVariant.heading4:
      return "text-xs";
    case HeadingVariant.heading5:
      return "text-lg";
    case HeadingVariant.heading2:
    default:
      return "text-base";
  }
}

function Heading<P = HTMLAttributes<HTMLElement>>({ as: Component = "span", variant, className, ...props }: Props<P>) {
  const fontWeight = getFontWeightClass(variant);
  const fontSize = getFontSizeClass(variant);
  const classes = cx(fontWeight, fontSize, className);

  if (Component === null) return null;

  return <Component {...props} className={classes} />;
}

export default Heading;
