import { camelCase } from "camel-case";
import Pusher from "pusher-js";
import { STORAGE_KEY } from "../context/auth-context";

export const apiUrl = process.env.REACT_APP_API_URL;
const FALLBACK_ERROR_MESSAGE = "Sorry, something went wrong please try again later";

if (!apiUrl) throw new Error("APIURL is not set");

export class UserException {
  name: "UserException" = "UserException";
  status: number;
  statusText: string;
  message: string;
  errors?: string[];

  constructor(response: Response, message: string, errors?: string[]) {
    this.status = response.status;
    this.statusText = response.statusText;
    this.message = message;
    this.errors = errors;
  }
}

export type ErrorResponse = {
  meessage: string;
  errors: {
    [key: string]: string[];
  };
};

export function createPusherClient() {
  const pusherApiKey = process.env.REACT_APP_PUSHER_API_KEY;
  if (!pusherApiKey) throw new Error("PUSHER_API_KEY is not set");

  return new Pusher(pusherApiKey, {
    cluster: "eu",
  });
}

export async function handleErrors(response: Response) {
  const json = await response.json();
  if (!response.ok) {
    const errorsMessage = !json.errors
      ? FALLBACK_ERROR_MESSAGE
      : Object.values((json as ErrorResponse).errors).reduce((accum, v) => `${accum}\n${v.join("\n")}`, "");
    throw new UserException(response, json?.message || errorsMessage.trim(), json.errrors);
  }
  return json;
}

export function authenticatedFetch(url: string, opts?: RequestInit) {
  const jwt = window.localStorage.getItem(STORAGE_KEY);
  if (!jwt) throw new Error("Json Web Token not present in localstorage");
  return fetch(url, {
    ...(opts ?? {}),
    headers: {
      ...(opts?.headers ?? {}),
      accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${jwt}`,
    },
  }).then(handleErrors);
}

export function unAuthenticatedFetch(url: string, opts?: RequestInit) {
  return fetch(url, {
    ...(opts ?? {}),
    headers: {
      ...(opts?.headers ?? {}),
      accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(handleErrors);
}

export type SuccessResponse<R> = {
  result: R;
};

type FormatOptions = {
  [key: string]: (val: any) => any;
};
export function convertToCamelCasedObject(obj: { [key: string]: any }, formatters?: FormatOptions) {
  if (!obj) return null;
  return Object.entries(obj).reduce((accum, [k, v]) => {
    const keyName = camelCase(k);
    const getValue = () => (formatters?.[keyName] ? formatters[keyName](v) : v);

    const value: any = Array.isArray(v)
      ? v.map((val) => convertToCamelCasedObject(val))
      : typeof v === "object"
      ? convertToCamelCasedObject(v)
      : getValue();
    return { ...accum, [keyName]: value };
  }, {} as { [key: string]: any });
}

export function handleStatusErrors(err: any) {
  if (err instanceof UserException && err.status === 503) window.alert("503 - Service Unavailable");
  else throw err;
}
