import React from "react";
import Button from "@atoms/button";
import ErrorCross from "@atoms/icons/illustrative/error-cross";
import GradientBorderSection from "@atoms/gradient-border-section";
import HeadTitle from "@atoms/head-title/head-title";
import PageContainer from "@atoms/page-container";
import purchaseExpiredImage from "./purchase-expired.jpg";
import purchaseExpiredImage2x from "./purchase-expired@2x.jpg";
import purchaseExpiredImage3x from "./purchase-expired@3x.jpg";

function PurchaseExpired(props: { pageTitle?: string; onGoBack: () => void }) {
  const { pageTitle, onGoBack } = props;
  const title = pageTitle || "Purchase Expired";
  return (
    <PageContainer>
      <HeadTitle titlePrefix={title} />
      <div className="flex items-center justify-between sm:justify-start">
        <h1 className="text-5xl md:text-6xl font-bold text-white mb-8">Purchase Expired</h1>
        <ErrorCross className="ml-5 grow-0 shrink-0 basis-auto mb-8" />
      </div>
      <GradientBorderSection className="bg-[#1A2743] relative px-8 py-8 md:py-16 md:px-16 text-white flex flex-col md:flex-row-reverse items-center text-center md:items-start md:text-left md:justify-between">
        <img
          alt="Purchase Expired"
          className="mb-8 md:ml-8 md:mb-0 w-9/12 max-w-full md:max-w-xs md:w-full"
          src={purchaseExpiredImage}
          srcSet={`${purchaseExpiredImage}, ${purchaseExpiredImage2x} 2x, ${purchaseExpiredImage3x} 3x`}
        />
        <div>
          <h2 className="text-5xl font-bold text-white mb-8 hidden md:block">Oh Dear!</h2>
          <p className="text-xl max-w-lg mb-8">
            Unfortunately, you took too long to check out which has resulted in your reserved items expiring.
          </p>
          <p className="text-xl max-w-lg mb-8">Please go back and try again.</p>
          <Button className="h-11 px-6 w-64 max-w-full" size="medium" onClick={onGoBack}>
            Go Back
          </Button>
        </div>
      </GradientBorderSection>
    </PageContainer>
  );
}

export default PurchaseExpired;
