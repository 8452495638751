import { assign, createMachine, send } from "xstate";
import { Profile } from "../context/auth-context";
import authBootstrap from "./auth-bootstrap";

type Context = {
  profile?: Profile;
  jsonWebToken?: "";
};

const AuthMachine = createMachine<Context>({
  initial: "bootstrap",
  context: {},
  states: {
    bootstrap: {
      invoke: {
        src: authBootstrap,
        onDone: {
          target: "authenticatedPredicate",
          actions: assign((_, { data }: any) => {
            if (data.jwt && data.profile) return { jsonWebToken: data.jwt, profile: data.profile };
            return {};
          }),
        },
      },
    },
    authenticatedPredicate: {
      entry: [
        send(({ profile, jsonWebToken }) => {
          if (!profile || !jsonWebToken) return { type: "UNAUTHENTICATED" };
          else return { type: "AUTHENTICATED" };
        }),
      ],
      on: {
        UNAUTHENTICATED: "unauthenticated",
        AUTHENTICATED: {
          target: "authenticated",
          actions: ["saveBearerToken", "saveProfile"],
        },
      },
    },
    unauthenticated: {},
    authenticated: {},
  },
});

export default AuthMachine;
