import HeadTitle from "@atoms/head-title/head-title";
import PageContainer from "@atoms/page-container";
import QrcodeCheckout from "@molecules/qrcode-checkout";

function Checkout({ pageTitle, walletSectionTitle }: { pageTitle?: string; walletSectionTitle?: string }) {
  const url = window.location.href;
  const payWithWalletTitle = walletSectionTitle ?? "Pay with your SeatlabNFT wallet";
  const title = pageTitle || "Checkout";
  return (
    <PageContainer>
      <HeadTitle titlePrefix={title} />
      <h1 className="text-6xl font-bold text-white mb-8">{title}</h1>
      <section className="bg-[#1A2743] max-w-6xl relative rounded-xl overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-tr from-black/60 to-black/00" />
        <div className="relative">
          {payWithWalletTitle && (
            <header className="text-2xl font-bold text-white py-4 my-2 mx-8 border-b border-white/20">
              {payWithWalletTitle}
            </header>
          )}
          <QrcodeCheckout {...{ url }} />
        </div>
      </section>
    </PageContainer>
  );
}

export default Checkout;
