import { SVGProps } from "react";

const Medium = (props: SVGProps<SVGSVGElement>) => (
  <svg width={38} height={38} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38 .196H.39v37.608H38V.196ZM9.304 12.323a.979.979 0 0 1 .06.45v11.168a1.285 1.285 0 0 1-.342 1.108l-2.654 3.22v.424h7.525v-.425l-2.653-3.219a1.333 1.333 0 0 1-.366-1.108v-9.658l6.605 14.41h.767l5.673-14.41v11.485c0 .307 0 .366-.2.566l-2.04 1.981v.425h9.907v-.425l-1.97-1.934a.59.59 0 0 1-.224-.566V11.606a.59.59 0 0 1 .224-.566l2.017-1.934v-.424h-6.982l-4.978 12.417-5.662-12.417H6.687v.424l2.36 2.842a.98.98 0 0 1 .258.375Z"
      fill="#fff"
    />
  </svg>
);

export default Medium;
