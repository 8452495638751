import HeadTitle from "@atoms/head-title/head-title";
import useStaticContent from "@hooks/use-static-content";
import TermsOfServicePage from "@pages/terms-of-service";

function TermsOfService() {
  const { data, isLoading } = useStaticContent("terms-of-service");

  if (isLoading || !data) return <div />;

  return (
    <>
      <HeadTitle titlePrefix="Terms of service" />
      <TermsOfServicePage content={data} />
    </>
  );
}

export default TermsOfService;
