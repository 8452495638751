import { formatUrlParams } from "@utils/formatters";
import { parse } from "date-fns";
import { FilterOrganiser, FilterOrganiserResponse, SearchFilters, SearchListingResponse } from "./common";
import { apiUrl, convertToCamelCasedObject, SuccessResponse, unAuthenticatedFetch } from "./lib";
import { Seller } from "./seller";

type FetchCollectiblesResponse = SearchListingResponse<Collectible>;

export type ClaimableItem = {
  createdAt: Date;
  id: number;
  itemCount: number | null;
  name: string;
};

export type Collectible = {
  title: string;
  description: string;
  imageUrl: string;
  audioMp3Url: string | null;
  quantityAvailable: number;
  price: number;
  seller: Seller;
  uuid: string;
  claimableItems: ClaimableItem[];
};

export type FetchedCollectible = {
  title: string;
  description: string;
  image_url: string;
  quantity_available: number;
  price: number;
  formatted_price: string;
  seller: Seller;
  uuid: string;
  claimable_items: ClaimableItem[];
  audio_mp3_url: string | null;
};

export type CollectiblePurchaseRequest = {
  expires_at: string;
  price: number;
  price_platform_fee: number;
  price_total: number;
  purchase_request: {
    [uuid: string]: number;
  };
  collectibles: Collectible[];
};

export type CollectibleProcessPurchaseRequest = CollectiblePurchaseRequest & {
  client_secret?: string;
  stripe_publishable_key: string;
};

export function fetchAllCollectibles({
  perPage,
  page,
  search,
  ...filters
}: SearchFilters): Promise<FetchCollectiblesResponse> {
  const urlParams = {
    featured: filters.featuredOnly ? "1" : "0",
    include_unverified: !!filters.verifiedOnly ? "0" : "1",
    per_page: perPage ?? 15,
    page: page ?? 1,
    ...(search ? { q: search } : {}),
    ...(filters.minPrice ? { min_price: filters.minPrice * 100 } : {}),
    ...(filters.maxPrice ? { max_price: filters.maxPrice * 100 } : {}),
    ...(filters.selectedOrganiserIds && filters.selectedOrganiserIds.length > 0
      ? { seller_ids: filters.selectedOrganiserIds }
      : {}),
    ...(filters.sellerSlug ? { seller_slug: filters.sellerSlug } : {}),
    ...(filters.orderBy ? { order_by: filters.orderBy } : {}),
    ...(filters.order ? { order: filters.order } : {}),
  };

  return unAuthenticatedFetch(`${apiUrl}/collectibles?${formatUrlParams(urlParams)}`).then((data) => {
    const formattedCollectible = data.result.map((fetchCollectible: FetchedCollectible) =>
      mapFetchedCollectibleToCollectible(fetchCollectible)
    );
    return { ...data, result: formattedCollectible };
  });
}

function mapFetchedCollectibleToCollectible(fetchedCollectible: FetchedCollectible) {
  const formatters = { createdAt: (val: string) => parse(val, "YYYY-MM-dd HH:mm:ss", new Date()) };
  const camelCasedCollectible = convertToCamelCasedObject(fetchedCollectible, formatters) as Collectible;
  return Object.entries(camelCasedCollectible).reduce((accum, [k, v]) => {
    const key = k as keyof Collectible;
    return {
      ...accum,
      [key]: v,
    };
  }, {} as Collectible);
}

export function fetchCollectibleBySlug(slug: string): Promise<Collectible | null> {
  return unAuthenticatedFetch(`${apiUrl}/collectibles/${slug}`).then(
    ({ result }: SuccessResponse<FetchedCollectible>) =>
      result ? (convertToCamelCasedObject(result) as Collectible) : null
  );
}

export function fetchCollectibleVerificationCode(collectibles: { [collectibleId: string]: number }) {
  return unAuthenticatedFetch(`${apiUrl}/collectibles/create-purchase-request`, {
    method: "POST",
    body: JSON.stringify({ collectibles }),
  }).then((json) => json.result.code);
}

export function fetchPurchaseRequest(purchaseCode: string): Promise<CollectiblePurchaseRequest> {
  return unAuthenticatedFetch(`${apiUrl}/collectibles/get-purchase-request`, {
    method: "POST",
    body: JSON.stringify({ code: purchaseCode }),
  }).then((json) => {
    const formattedCollectible = json.result.collectibles.map((fetchCollectible: FetchedCollectible) =>
      mapFetchedCollectibleToCollectible(fetchCollectible)
    );
    return { ...json.result, collectibles: formattedCollectible };
  });
}

export function processAnonymousPurchaseRequest(
  code: string,
  phone: string,
  email: string,
  firstname: string,
  lastname: string,
  custom_question_1: string | null,
  custom_question_2: string | null,
  custom_answer_1: string | null,
  custom_answer_2: string | null
) {
  return unAuthenticatedFetch(`${apiUrl}/collectible_purchases/process-anonymous-purchase`, {
    method: "POST",
    body: JSON.stringify({
      ...{
        code,
        phone,
        email,
        firstname,
        lastname,
        custom_question_1,
        custom_question_2,
        custom_answer_1,
        custom_answer_2,
      },
    }),
  }).then((json) => json.result as CollectibleProcessPurchaseRequest);
}

export function processFreeAnonymousPurchaseRequest(code: string) {
  return unAuthenticatedFetch(`${apiUrl}/collectible_purchases/anonymous-purchase-free`, {
    method: "POST",
    body: JSON.stringify({ ...{ code } }),
  }).then((json) => json.result);
}

export function fetchSellers(includeUnverified?: boolean): Promise<FilterOrganiser[]> {
  const unverified = includeUnverified ? "1" : "0";
  return unAuthenticatedFetch(`${apiUrl}/collectibles/filter-options/sellers?include_unverified=${unverified}`).then(
    ({ result }: FilterOrganiserResponse) => {
      return result.map((organiser) => convertToCamelCasedObject(organiser)) as FilterOrganiser[];
    }
  );
}
