import { Link, useNavigate, useParams } from "react-router-dom";
import EventDetailsPage from "@pages/event-details";
import { QueryFunctionContext, useMutation, useQuery } from "react-query";
import useEvents from "@hooks/use-events";
import HeadTitle from "@atoms/head-title/head-title";
import {useContext} from "react";
import {AppVariablesContext} from "@context/app-variables-context";

export type EventDetailParams = {
  eventSlug: string;
  ticketUUID?: string;
};

function EventDetails() {
  const params = useParams<EventDetailParams>();
  const navigate = useNavigate();
  const { fetchEventBySlug, fetchEventVerificationCode, fetchUrlOnlyEvent } = useEvents();
  const { marketplaceFeatures } = useContext(AppVariablesContext) || {};
  const ticketTransfersEnabled = marketplaceFeatures ? marketplaceFeatures.ticketTransfersEnabled : false;
  const isUrlOnlyEvent = !!params.ticketUUID;
  if (!params.eventSlug) throw new Error("Event Slug not found in url");
  const queryName = isUrlOnlyEvent ? "urlOnlyEvent" : "event";
  const queryParams: [string, string | undefined] = [params.eventSlug, params.ticketUUID];
  const { data: occassion, isLoading } = useQuery([queryName, ...queryParams], fetchEvent);
  const getEventCode = useMutation((tickets: { [uuid: string]: number }) => fetchEventVerificationCode(tickets), {
    onSuccess: (code) => void navigate(`/buy-tickets/${code}`),
  });

  function fetchEvent({ queryKey: [, eventSlug, ticketUUID] }: QueryFunctionContext<Array<string | undefined>>) {
    if (!eventSlug) throw new Error("Event Slug not found in url");
    if (eventSlug && ticketUUID) return fetchUrlOnlyEvent(eventSlug, ticketUUID);
    return fetchEventBySlug(eventSlug);
  }

  if (isLoading) return <div />;

  if (!occassion) {
    return (
      <div>
        <h1 className="text-4xl">Sorry, this event cannot be found</h1>
        <Link to="/events">Go back to events</Link>
      </div>
    );
  }

  // @TODO: remove this after elrow event end
  const elrowSlugs = ["elrow-xxl-dubai", "elrow-dubai", "elrow"];
  let eventSlugsIncludingVat: string[] = [];
  if(process.env.REACT_APP_EVENT_SLUGS_INCLUDING_VAT) {
    eventSlugsIncludingVat = process.env.REACT_APP_EVENT_SLUGS_INCLUDING_VAT.split(',');
  }
  const priceIncludesVat = eventSlugsIncludingVat.includes(occassion.slug);
  const timezone = elrowSlugs.includes(occassion.slug) ? "GST" : undefined;

  return (
    <>
      <HeadTitle titlePrefix={occassion.name} image={occassion.thumbnailUrl} />
      <EventDetailsPage
        {...{ occassion, timezone, priceIncludesVat }}
        urlOnlyEvent={isUrlOnlyEvent}
        onPurchaseTickets={getEventCode}
        ticketTransfersEnabled={ticketTransfersEnabled}
      />
    </>
  );
}

export default EventDetails;
