import Button from "@atoms/button";
import IconCard from "@atoms/icon-card";
import { DollarCircle, GraphIncreasing, ShieldTick } from "@atoms/icons/illustrative";
import PageContainer from "@atoms/page-container";
import ExploreEventsCards, { EventListItem } from "@organisms/explore-events-cards";
import Hero from "@organisms/hero";

export type Props = {
  user?: {
    name: string;
    userName: string;
    seatCoins: number;
    unclaimedGold: number;
    unclaimedHolographic: number;
    unclaimedSapphire: number;
  };
  loadingEvents?: boolean;
  events?: EventListItem[];
  collectibles?: {
    id: number | string;
    imgUrl: string;
    editionsLeft: number;
    creator: string;
    gbpPrice: number;
    usdPrice: number;
    name: string;
  }[];
  onViewAllEvents: () => void;
  onViewEvent: (event: EventListItem) => void;
};

const iconCardContent = [
  {
    icon: DollarCircle,
    heading: "Transparent Resale Market",
    description:
      "Fans can resell tickets at any time at the price they choose (or below the price ceiling if set by the event organiser), creating secondary sales revenue for artists and event organisers through royalty splits.",
  },
  {
    icon: ShieldTick,
    heading: "NFTs With Real Utility",
    description:
      "All tickets and collectables are fully functional, scannable NFTs that allow users to claim whatever IRL items the artist/event organiser chooses to offer! They're tied to usernames and locked to devices to stop ticket touts from operating within our ticketing ecosystem.",
  },
  {
    icon: GraphIncreasing,
    heading: "Reward Fan Loyalty",
    description:
      "Artists and event organisers can now airdrop resaleable and scarce digital assets for free to loyal fans, allowing them to extract real value from attending events while generating additional revenue for the issuer via royalty splits.",
  },
];

function Home({ events, collectibles, user, loadingEvents, onViewAllEvents, onViewEvent }: Props) {
  return (
    <PageContainer>
      <Hero />
      <div className="lg:mt-32">
        <ExploreEventsCards
          skeletonNum={6}
          {...{ events, onViewEvent }}
          isLoading={!events?.length && loadingEvents}
          onViewEvents={onViewAllEvents}
        />
      </div>
      {/* <div className="mt-36">
        <CollectibleListPreview
          title="Featured collectibles"
          buttonText="View all collectibles"
          collectibles={collectibles ?? []}
          onButtonClick={() => {}}
        />
      </div> */}
      {/* {!!user && (
        <div className="mt-32">
          <StakingRewardsSummary {...user} onClaim={() => undefined} />
        </div>
      )} */}
      <div className="mt-32 text-center">
        <h4 className="font-bold text-4xl text-white mb-9">
          Revolutionise your ticketing with NFTs <br /> - Sell on SeatlabNFT Today!
        </h4>
        <p className="text-xl text-white">
          By using blockchain technology and NFTs, we are now at the forefront of a new era of event ticketing
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-11">
          {iconCardContent.map(({ icon, heading, description }) => (
            <div className="flex items-stretch justify-center" key={heading}>
              <IconCard {...{ icon, heading, description }} />
            </div>
          ))}
        </div>
        <div className="w-44 mx-auto mt-11">
          <Button as="a" variant="primary" size="medium" href="https://dashboard.seatlabnft.com/" target="_blank">
            Start Selling Today
          </Button>
        </div>
      </div>
    </PageContainer>
  );
}

export default Home;
