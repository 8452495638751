import { Discord, Medium, Telegram, Twitter } from "@atoms/icons";

export default function SocialIcons() {
  return (
    <span className="flex space-x-6 md:mb-unset">
      <a href="https://twitter.com/seatlabnft">
        <Twitter />
      </a>
      <a href="https://discord.com/invite/seatlabnft">
        <Discord />
      </a>
      <a href="https://seatlabnft.medium.com/">
        <Medium />
      </a>
      <a href="https://t.me/seatlab">
        <Telegram />
      </a>
    </span>
  );
}
