import { apiUrl, authenticatedFetch, SuccessResponse, unAuthenticatedFetch } from "@api/lib";
import { FetchedTicket, Ticket } from "@api/tickets";
import { FetchedSeller, Seller } from "@api/seller";
import { camelCase } from "camel-case";
import { format, parse } from "date-fns";
import { formatUrlParams } from "@utils/formatters";
import {
  FetchedFilterOrganiser,
  FilterOrganiser,
  FilterOrganiserResponse,
  SearchFilters,
  SearchListingResponse,
} from "./common";

export type FetchedEvent = {
  id: number;
  name: string;
  slug: string;
  description: string;
  start_date: string;
  entry_date: string;
  end_date: string;
  venue_name: string;
  is_live: boolean;
  cover_photo_url: string;
  thumbnail_url: string;
  ticket_types: FetchedTicket[];
  seller: FetchedSeller;
  is_ended: boolean;
  event_page_button_1_url?: string | URL;
  event_page_button_1_text?: string;
  event_page_button_2_url?: string | URL | null;
  event_page_button_2_text?: string;
};

type CamelCasedEvent = {
  id: number;
  name: string;
  slug: string;
  description: string;
  startDate: string;
  entryDate: string;
  endDate: string;
  venueName: string;
  isLive: boolean;
  coverPhotoUrl: string;
  thumbnailUrl: string;
  ticketTypes: Ticket[];
  lowestPrice: number;
  seller: Seller;
  isEnded: boolean;
  eventPageButton_1Url?: string | URL;
  eventPageButton_1Text?: string;
  eventPageButton_2Url?: string | URL;
  eventPageButton_2Text?: string;
};

export type Event = Omit<CamelCasedEvent, "startDate" | "entryDate" | "endDate"> & {
  startDate: Date;
  entryDate: Date;
  endDate: Date;
};

export type EventQuestion = {
  id: number;
  question: string;
  mandatory: boolean;
};

export type EventPurchaseRequest = {
  expires_at: string;
  price: number;
  price_platform_fee: number;
  price_total: number;
  purchase_request: {
    [uuid: string]: number;
  };
  event: FetchedEvent;
};

export type EventProcessPurchaseRequest = EventPurchaseRequest & {
  client_secret?: string;
  stripe_publishable_key: string;
  stripe_country: string;
  stripe_currency: string;
  stripe_currency_symbol: string;
};

type EventUrlParams = {
  featured?: string;
  include_unverified?: string;
  per_page: number;
  page: number;
  min_price?: number;
  max_price?: number;
  start_date?: string;
  end_date?: string;
  q?: string;
  seller_ids?: number[];
};

type FetchAllEventsResponse = SearchListingResponse<Event>;

export function fetchAllEvents({ perPage, page, search, ...filters }: SearchFilters): Promise<FetchAllEventsResponse> {
  const urlParams: EventUrlParams = {
    featured: filters.featuredOnly ? "1" : "0",
    include_unverified: !!filters.verifiedOnly ? "0" : "1",
    per_page: perPage ?? 9,
    page: page ?? 1,
    ...(search ? { q: search } : {}),
    ...(filters.minPrice ? { min_price: filters.minPrice * 100 } : {}),
    ...(filters.maxPrice ? { max_price: filters.maxPrice * 100 } : {}),
    ...(filters.startDate ? { start_date: format(filters.startDate as Date, "yyyy-MM-dd") } : {}),
    ...(filters.endDate ? { end_date: format(filters.endDate as Date, "yyyy-MM-dd") } : {}),
    ...(filters.selectedOrganiserIds && filters.selectedOrganiserIds.length > 0
      ? { seller_ids: filters.selectedOrganiserIds }
      : {}),
    ...(filters.sellerSlug ? { seller_slug: filters.sellerSlug } : {}),
  };
  return unAuthenticatedFetch(`${apiUrl}/events?${formatUrlParams(urlParams)}`).then((data) => {
    const formattedEvents = data.result.map((fetchedEvent: FetchedEvent) => mapFetchedEventToEvent(fetchedEvent));
    return { ...data, result: formattedEvents };
  });
}

export function fetchFeaturedEvents(): Promise<Event[]> {
  return unAuthenticatedFetch(`${apiUrl}/events?featured=1`).then(({ result }: SuccessResponse<FetchedEvent[]>) => {
    return result.map((fetchedEvent) => mapFetchedEventToEvent(fetchedEvent));
  });
}

export function fetchPurchaseRequest(code: string): Promise<EventPurchaseRequest> {
  return unAuthenticatedFetch(`${apiUrl}/get-purchase-request`, {
    method: "POST",
    body: JSON.stringify({ code }),
  }).then((json) => {
    return json.result;
  });
}

export function fetchEventBySlug(slug: string, authenticated?: boolean): Promise<Event | null> {
  const fetchMethod = authenticated ? authenticatedFetch : unAuthenticatedFetch;
  return fetchMethod(`${apiUrl}/events/${slug}`).then(({ result }: SuccessResponse<FetchedEvent>) =>
    result ? mapFetchedEventToEvent(result) : null
  );
}

export function fetchUrlOnlyEvent(slug: string, ticketId: string, authenticated?: boolean): Promise<Event | null> {
  const fetchMethod = authenticated ? authenticatedFetch : unAuthenticatedFetch;
  return fetchMethod(`${apiUrl}/events/${slug}/${ticketId}`).then(({ result }: SuccessResponse<FetchedEvent>) =>
    result ? mapFetchedEventToEvent(result) : null
  );
}

export function fetchEventVerificationCode(ticketTypes: { [ticketId: string]: number }) {
  const ticket_types = Object.entries(ticketTypes).reduce((accum, [k, v]) => {
    return v > 0 ? { ...accum, [k]: v } : accum;
  }, {} as typeof ticketTypes);
  return unAuthenticatedFetch(`${apiUrl}/events/create-purchase-request`, {
    method: "POST",
    body: JSON.stringify({ ticket_types }),
  }).then((json) => json.result.code);
}

export function fetchEventQuestions(slug: string): Promise<EventQuestion[]> {
  return unAuthenticatedFetch(`${apiUrl}/events/${slug}/questions`).then((json) => json.result);
}

export function processAnonymousPurchaseRequest(
  code: string,
  phone: string,
  email: string,
  firstname: string,
  lastname: string,
  custom_question_1: string | null,
  custom_question_2: string | null,
  custom_answer_1: string | null,
  custom_answer_2: string | null
) {
  return unAuthenticatedFetch(`${apiUrl}/events/process-anonymous-purchase`, {
    method: "POST",
    body: JSON.stringify({
      ...{
        code,
        phone,
        email,
        firstname,
        lastname,
        custom_question_1,
        custom_question_2,
        custom_answer_1,
        custom_answer_2,
      },
    }),
  }).then((json) => json.result as EventProcessPurchaseRequest);
}

export function processFreeAnonymousPurchaseRequest(code: string) {
  return unAuthenticatedFetch(`${apiUrl}/events/anonymous-purchase-free`, {
    method: "POST",
    body: JSON.stringify({ ...{ code } }),
  }).then((json) => json.result);
}

export function fetchSellers(includeUnverified?: boolean): Promise<FilterOrganiser[]> {
  const unverified = includeUnverified ? "1" : "0";
  return unAuthenticatedFetch(`${apiUrl}/events/filter-options/sellers?include_unverified=${unverified}`).then(
    ({ result }: FilterOrganiserResponse) => {
      const camelCasedResult = result.map((organiser: FetchedFilterOrganiser) => convertToCamelCasedObject(organiser));
      return camelCasedResult as FilterOrganiser[];
    }
  );
}

export function convertToCamelCasedObject(obj: { [key: string]: any }) {
  if (!obj) return null;
  return Object.entries(obj).reduce((accum, [k, v]) => {
    const value: any = Array.isArray(v)
      ? v.map((val) => convertToCamelCasedObject(val))
      : typeof v === "object"
      ? convertToCamelCasedObject(v)
      : v;
    return { ...accum, [camelCase(k)]: value };
  }, {} as { [key: string]: any });
}

function mapFetchedEventToEvent(fetchedEvent: FetchedEvent): Event {
  const camelCasedEvent = convertToCamelCasedObject(fetchedEvent) as CamelCasedEvent;
  return {
    ...Object.entries(camelCasedEvent).reduce((accum, [k, v]) => {
      const key = k as keyof CamelCasedEvent;
      return {
        ...accum,
        [key]: v,
        ...(key === "startDate" && typeof v === "string" ? { [key]: parseFetchedDate(v) } : {}),
        ...(key === "endDate" && typeof v === "string" ? { [key]: parseFetchedDate(v) } : {}),
      };
    }, {} as Event),
    lowestPrice: getLowestTicketPrice(camelCasedEvent.ticketTypes),
  };
}

export function getLowestTicketPrice(tickets: Ticket[]) {
  if (!tickets || tickets.length === 0) return 0;
  const cheapestTicket = tickets.reduce((prev, curr) => {
    return prev.price < curr.price ? prev : curr;
  });
  return cheapestTicket.price || 0;
}

function parseFetchedDate(date: string) {
  return parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
}
