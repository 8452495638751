import HeadTitle from "@atoms/head-title/head-title";
import useCollectibles from "@hooks/use-collectibles";
import { Collectible } from "@api/collectibles";
import { useEffect, useState } from "react";
import Container from "@atoms/container";
import IframeCollectibleListing from "@pages/iframe-collectibles-listing";
import { useParams } from "react-router-dom";

export type IframeCollectibleListingParams = {
  sellerSlug: string;
};

function CollectibleListingRoute() {
  const params = useParams<IframeCollectibleListingParams>();
  if (!params.sellerSlug) throw new Error("Seller Slug not found in url");
  const { fetchAllCollectibles } = useCollectibles();
  const [collectibles, setCollectibles] = useState<Collectible[]>([]);
  let isLoading = false;
  const collectiblesQuery = fetchAllCollectibles({
    page: 1,
    perPage: 10000,
    sellerSlug: params.sellerSlug,
    orderBy: "price",
    order: "asc",
  }).then((response) => {
    return response.result;
  });
  const getCollectibles = (query: any) => {
    return query.then((data: Collectible[]) => {
      setCollectibles(data);
    });
  };

  useEffect(() => {
    getCollectibles(collectiblesQuery);
  }, []);

  return (
    <>
      <HeadTitle titlePrefix="Collectables" />
      <Container children={<IframeCollectibleListing collectibles={collectibles} isLoading={isLoading} />} />
    </>
  );
}

export default CollectibleListingRoute;
