import React, { useEffect, useRef, useState } from "react";
import QRCodeStyling from "qr-code-styling";

interface Props {
  url: string;
  backgroundColor?: string;
  width?: number;
  height?: number;
}

const qrCode = new QRCodeStyling({
  width: 200,
  height: 200,
  dotsOptions: {
    type: "square",
    gradient: {
      type: "linear",
      rotation: 0,
      colorStops: [
        { offset: 0, color: "#00c2ff" },
        { offset: 1, color: "#0067ff" },
      ],
    },
  },
  cornersSquareOptions: {
    type: "square",
    gradient: {
      type: "linear",
      rotation: 0,
      colorStops: [
        { offset: 0, color: "#00c2ff" },
        { offset: 1, color: "#0067ff" },
      ],
    },
  },
  cornersDotOptions: {
    type: "square",
    gradient: {
      type: "linear",
      rotation: 0,
      colorStops: [
        { offset: 0, color: "#00c2ff" },
        { offset: 1, color: "#0067ff" },
      ],
    },
  },
});

const QrGradient = ({ url, backgroundColor = "#000000", width, height }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    qrCode.update({ data: url, width, height, backgroundOptions: { color: backgroundColor } });
  }, [url, backgroundColor, width, height]);

  useEffect(() => {
    if (ref.current) qrCode.append(ref.current);
  }, []);

  return <div ref={ref} />;
};

export default QrGradient;
