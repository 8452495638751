import React from "react";

type Props = {
  text: string;
};

function CurrencySymbol({ text }: Props) {
  return (
    <span className="text-blue mr-1">{text}</span>
  );
}

export default CurrencySymbol;
