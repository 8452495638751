import { DetailedHTMLProps, SelectHTMLAttributes } from "react";
import { ChevronDown } from "@atoms/icons";

type Item = { value: string; label: string | number; key: string | number };

type Props<I> = Pick<
  DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>,
  "onChange" | "value"
> & {
  items: I[];
};

export default function Dropdown<I extends Item>({ items, onChange, value }: Props<I>) {
  return (
    <div className="relative w-24 h-11 bg-grey/10 rounded-lg">
      <select
        {...{ value, onChange }}
        className="w-full h-full bg-transparent focus:outline-none px-4"
        style={{ WebkitAppearance: "none" }}
        onClick={(e) => e.stopPropagation()}
      >
        {items.map(({ value, label, key }) => (
          <option key={key} className="w-24 h-11 text-black" value={value}>
            {label}
          </option>
        ))}
      </select>
      <ChevronDown className="absolute top-1/2 right-4 -translate-y-1/2 pointer-events-none" />
    </div>
  );
}
