import * as React from "react";
import { SVGProps } from "react";

const AppleAppStore = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 189 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M187.56 46.0029C187.56 48.774 185.169 51.0187 182.211 51.0187H7.15506C4.1986 51.0187 1.79883 48.774 1.79883 46.0029V6.00333C1.79883 3.23349 4.1986 0.980957 7.15506 0.980957H182.21C185.169 0.980957 187.559 3.23349 187.559 6.00333L187.56 46.0029Z"
      fill="black"
    />
    <path
      d="M181.647 1.04162C184.869 1.04162 187.49 3.4905 187.49 6.5V45.5C187.49 48.5095 184.869 50.9584 181.647 50.9584H7.71322C4.49196 50.9584 1.87078 48.5095 1.87078 45.5V6.5C1.87078 3.4905 4.49196 1.04162 7.71322 1.04162H181.647ZM181.647 2.74817e-06H7.71322C3.88841 2.74817e-06 0.755859 2.92663 0.755859 6.5V45.5C0.755859 49.0734 3.88841 52 7.71322 52H181.647C185.472 52 188.605 49.0734 188.605 45.5V6.5C188.605 2.92663 185.472 2.74817e-06 181.647 2.74817e-06Z"
      fill="#A6A6A6"
    />
    <path
      d="M42.6774 25.7191C42.637 21.5292 46.3495 19.4908 46.5192 19.3959C44.4167 16.532 41.1579 16.1407 40.0127 16.1095C37.2757 15.8404 34.6208 17.6396 33.2265 17.6396C31.8044 17.6396 29.6574 16.1355 27.3434 16.1797C24.3656 16.2226 21.5799 17.8333 20.052 20.3345C16.899 25.4344 19.2506 32.9289 22.2714 37.0512C23.7826 39.0701 25.5484 41.3243 27.8596 41.245C30.1207 41.1579 30.9654 39.8982 33.694 39.8982C36.3977 39.8982 37.1908 41.245 39.548 41.1943C41.9747 41.1579 43.5025 39.1663 44.9608 37.1292C46.7071 34.8152 47.4084 32.5363 47.4362 32.4193C47.3792 32.4011 42.7233 30.741 42.6774 25.7191Z"
      fill="white"
    />
    <path
      d="M38.2247 13.3977C39.4408 11.9768 40.2729 10.0437 40.0419 8.08203C38.2817 8.15483 36.0804 9.21953 34.8128 10.6092C33.6913 11.8338 32.6894 13.841 32.9482 15.7286C34.9255 15.8664 36.9557 14.7965 38.2247 13.3977Z"
      fill="white"
    />
    <path
      d="M75.4008 40.9552H72.2407L70.5097 35.8735H64.493L62.8441 40.9552H59.7676L65.7286 23.6548H69.4105L75.4008 40.9552ZM69.9879 33.7415L68.4225 29.224C68.257 28.7625 67.9467 27.6757 67.4889 25.9649H67.4332C67.2509 26.7007 66.9573 27.7875 66.5538 29.224L65.0162 33.7415H69.9879Z"
      fill="white"
    />
    <path
      d="M90.7297 34.5647C90.7297 36.6863 90.1161 38.3633 88.8888 39.5944C87.7895 40.6903 86.4245 41.2376 84.7951 41.2376C83.0362 41.2376 81.7728 40.6474 81.0033 39.467H80.9476V46.0385H77.981V32.5874C77.981 31.2536 77.9435 29.8847 77.8711 28.4807H80.4801L80.6457 30.458H80.7014C81.6907 28.9682 83.1921 28.2246 85.2069 28.2246C86.7821 28.2246 88.097 28.8057 89.149 29.9692C90.2037 31.134 90.7297 32.6654 90.7297 34.5647ZM87.7074 34.6661C87.7074 33.4519 87.4152 32.4509 86.828 31.6631C86.1865 30.8415 85.3252 30.4307 84.2454 30.4307C83.5135 30.4307 82.8484 30.6595 82.2542 31.1106C81.6587 31.5656 81.2691 32.1597 81.0868 32.8955C80.9949 33.2387 80.949 33.5195 80.949 33.7405V35.8205C80.949 36.7279 81.2468 37.4936 81.8424 38.1189C82.4379 38.7442 83.2116 39.0562 84.1633 39.0562C85.2807 39.0562 86.1504 38.6532 86.7723 37.8498C87.3957 37.0451 87.7074 35.9843 87.7074 34.6661Z"
      fill="white"
    />
    <path
      d="M106.088 34.5647C106.088 36.6863 105.474 38.3633 104.245 39.5944C103.147 40.6903 101.782 41.2376 100.153 41.2376C98.3942 41.2376 97.1308 40.6474 96.3627 39.467H96.307V46.0385H93.3404V32.5874C93.3404 31.2536 93.3028 29.8847 93.2305 28.4807H95.8395L96.0051 30.458H96.0607C97.0487 28.9682 98.5501 28.2246 100.566 28.2246C102.14 28.2246 103.455 28.8057 104.51 29.9692C105.56 31.134 106.088 32.6654 106.088 34.5647ZM103.065 34.6661C103.065 33.4519 102.772 32.4509 102.185 31.6631C101.543 30.8415 100.685 30.4307 99.6034 30.4307C98.8701 30.4307 98.2064 30.6595 97.6108 31.1106C97.0153 31.5656 96.6271 32.1597 96.4448 32.8955C96.3543 33.2387 96.307 33.5195 96.307 33.7405V35.8205C96.307 36.7279 96.6048 37.4936 97.1976 38.1189C97.7931 38.7429 98.5668 39.0562 99.5213 39.0562C100.639 39.0562 101.508 38.6532 102.13 37.8498C102.754 37.0451 103.065 35.9843 103.065 34.6661Z"
      fill="white"
    />
    <path
      d="M123.259 36.1037C123.259 37.5753 122.713 38.7726 121.615 39.6969C120.408 40.707 118.729 41.2114 116.571 41.2114C114.578 41.2114 112.981 40.8526 111.771 40.1337L112.459 37.8236C113.761 38.5594 115.19 38.9286 116.747 38.9286C117.865 38.9286 118.734 38.692 119.359 38.2214C119.981 37.7508 120.291 37.119 120.291 36.3312C120.291 35.6292 120.035 35.0377 119.522 34.558C119.011 34.0783 118.158 33.6324 116.967 33.2203C113.725 32.0906 112.105 30.4357 112.105 28.2595C112.105 26.8373 112.673 25.6712 113.81 24.7638C114.943 23.8551 116.454 23.4014 118.343 23.4014C120.028 23.4014 121.428 23.6757 122.546 24.223L121.804 26.4824C120.76 25.952 119.58 25.6868 118.26 25.6868C117.216 25.6868 116.401 25.9273 115.817 26.4057C115.323 26.8334 115.075 27.3547 115.075 27.9722C115.075 28.656 115.357 29.2215 115.925 29.6661C116.419 30.0769 117.317 30.5215 118.619 31.0012C120.212 31.6005 121.382 32.3012 122.135 33.1046C122.885 33.9054 123.259 34.9077 123.259 36.1037Z"
      fill="white"
    />
    <path
      d="M133.067 30.5604H129.797V36.6171C129.797 38.1576 130.373 38.9272 131.528 38.9272C132.058 38.9272 132.498 38.8843 132.846 38.7985L132.928 40.9032C132.344 41.1073 131.574 41.21 130.621 41.21C129.449 41.21 128.534 40.8759 127.873 40.209C127.215 39.5408 126.884 38.4202 126.884 36.8459V30.5578H124.936V28.4778H126.884V26.1937L129.797 25.3721V28.4778H133.067V30.5604Z"
      fill="white"
    />
    <path
      d="M147.821 34.6151C147.821 36.5326 147.233 38.1069 146.062 39.338C144.833 40.6055 143.202 41.2373 141.169 41.2373C139.21 41.2373 137.65 40.6302 136.487 39.416C135.324 38.2018 134.742 36.6691 134.742 34.8218C134.742 32.8887 135.341 31.3053 136.541 30.0742C137.739 28.8418 139.356 28.2256 141.389 28.2256C143.348 28.2256 144.925 28.8327 146.115 30.0482C147.253 31.2273 147.821 32.7496 147.821 34.6151ZM144.743 34.7048C144.743 33.5543 144.48 32.5676 143.947 31.7447C143.325 30.7489 142.436 30.2523 141.283 30.2523C140.091 30.2523 139.185 30.7502 138.563 31.7447C138.03 32.5689 137.767 33.5712 137.767 34.7568C137.767 35.9073 138.03 36.894 138.563 37.7156C139.205 38.7114 140.101 39.208 141.257 39.208C142.39 39.208 143.279 38.701 143.92 37.6896C144.467 36.8511 144.743 35.854 144.743 34.7048Z"
      fill="white"
    />
    <path
      d="M157.464 30.9182C157.171 30.8675 156.857 30.8415 156.529 30.8415C155.485 30.8415 154.678 31.2094 154.111 31.9465C153.617 32.5965 153.369 33.4181 153.369 34.41V40.9555H150.404L150.432 32.4093C150.432 30.9715 150.394 29.6624 150.32 28.482H152.904L153.013 30.8688H153.095C153.408 30.0485 153.902 29.3881 154.578 28.8928C155.239 28.4469 155.953 28.2246 156.723 28.2246C156.997 28.2246 157.244 28.2428 157.464 28.2753V30.9182Z"
      fill="white"
    />
    <path
      d="M170.732 34.1279C170.732 34.6245 170.697 35.0431 170.623 35.385H161.723C161.758 36.6174 162.188 37.5599 163.015 38.2099C163.765 38.791 164.734 39.0822 165.926 39.0822C167.243 39.0822 168.446 38.8859 169.527 38.492L169.991 40.416C168.728 40.9308 167.236 41.1869 165.515 41.1869C163.445 41.1869 161.819 40.6175 160.637 39.48C159.457 38.3425 158.865 36.815 158.865 34.8988C158.865 33.0177 159.415 31.4512 160.516 30.2019C161.668 28.8681 163.225 28.2012 165.184 28.2012C167.108 28.2012 168.565 28.8681 169.555 30.2019C170.338 31.2614 170.732 32.5718 170.732 34.1279ZM167.903 33.409C167.922 32.5874 167.729 31.8776 167.327 31.2783C166.813 30.5074 166.024 30.1226 164.963 30.1226C163.993 30.1226 163.204 30.4983 162.601 31.2523C162.107 31.8516 161.814 32.5705 161.723 33.4077H167.903V33.409Z"
      fill="white"
    />
    <path
      d="M69.0071 13.0118C69.0071 14.5419 68.5159 15.6937 67.5349 16.4672C66.6263 17.1809 65.335 17.5384 63.6624 17.5384C62.8331 17.5384 62.1235 17.5046 61.5293 17.437V9.0767C62.3043 8.9597 63.1392 8.8999 64.0409 8.8999C65.6341 8.8999 66.835 9.2236 67.6448 9.871C68.5521 10.6029 69.0071 11.6494 69.0071 13.0118ZM67.4695 13.0495C67.4695 12.0576 67.1884 11.2971 66.6263 10.7667C66.0641 10.2376 65.2431 9.9724 64.162 9.9724C63.7028 9.9724 63.3118 10.001 62.9876 10.0608V16.4165C63.1671 16.4425 63.4955 16.4542 63.9727 16.4542C65.0887 16.4542 65.95 16.1643 66.5567 15.5845C67.1634 15.0047 67.4695 14.1597 67.4695 13.0495Z"
      fill="white"
    />
    <path
      d="M77.1588 14.3482C77.1588 15.2907 76.8708 16.0629 76.2947 16.6687C75.6908 17.2914 74.8907 17.6021 73.8916 17.6021C72.9287 17.6021 72.162 17.3044 71.5901 16.7064C71.0196 16.1097 70.7344 15.357 70.7344 14.4496C70.7344 13.5006 71.028 12.7219 71.618 12.1174C72.2079 11.5129 73.0011 11.21 74.0002 11.21C74.9631 11.21 75.7367 11.5077 76.3225 12.1044C76.8791 12.6842 77.1588 13.433 77.1588 14.3482ZM75.6463 14.3924C75.6463 13.8269 75.5155 13.342 75.2553 12.9377C74.9492 12.4489 74.5136 12.2045 73.9473 12.2045C73.3615 12.2045 72.9162 12.4489 72.6101 12.9377C72.3485 13.342 72.2191 13.8347 72.2191 14.4171C72.2191 14.9826 72.3499 15.4675 72.6101 15.8718C72.9259 16.3606 73.3657 16.605 73.9334 16.605C74.49 16.605 74.9269 16.3567 75.2414 15.8588C75.5113 15.4467 75.6463 14.9579 75.6463 14.3924Z"
      fill="white"
    />
    <path
      d="M88.0916 11.335L86.0392 17.4632H84.7034L83.8532 14.8021C83.6375 14.1378 83.4622 13.4774 83.3259 12.8222H83.2994C83.1728 13.4956 82.9975 14.1547 82.772 14.8021L81.869 17.4632H80.5179L78.5879 11.335H80.0865L80.8282 14.2483C81.0077 14.9373 81.1552 15.5938 81.2734 16.2152H81.2999C81.4084 15.703 81.5879 15.0504 81.8411 14.2613L82.772 11.3363H83.9604L84.8523 14.1989C85.068 14.897 85.2433 15.5691 85.3783 16.2165H85.4186C85.5174 15.586 85.6663 14.9139 85.8639 14.1989L86.6598 11.3363H88.0916V11.335Z"
      fill="white"
    />
    <path
      d="M95.6519 17.463H94.1936V13.953C94.1936 12.8714 93.7539 12.3306 92.8717 12.3306C92.439 12.3306 92.0897 12.4788 91.8184 12.7765C91.5498 13.0742 91.4135 13.4252 91.4135 13.8269V17.4617H89.9552V13.0859C89.9552 12.5477 89.9371 11.964 89.9023 11.3322H91.1839L91.2521 12.2903H91.2924C91.4622 11.9926 91.7154 11.7469 92.048 11.5506C92.4432 11.3218 92.8857 11.2061 93.3699 11.2061C93.9821 11.2061 94.4914 11.3907 94.8963 11.7612C95.4 12.2149 95.6519 12.8922 95.6519 13.7918V17.463Z"
      fill="white"
    />
    <path d="M99.6717 17.463H98.2148V8.52295H99.6717V17.463Z" fill="white" />
    <path
      d="M108.258 14.3482C108.258 15.2907 107.97 16.0629 107.394 16.6687C106.79 17.2914 105.989 17.6021 104.991 17.6021C104.027 17.6021 103.26 17.3044 102.69 16.7064C102.119 16.1097 101.834 15.357 101.834 14.4496C101.834 13.5006 102.128 12.7219 102.718 12.1174C103.308 11.5129 104.101 11.21 105.098 11.21C106.063 11.21 106.835 11.5077 107.422 12.1044C107.979 12.6842 108.258 13.433 108.258 14.3482ZM106.744 14.3924C106.744 13.8269 106.614 13.342 106.353 12.9377C106.049 12.4489 105.612 12.2045 105.047 12.2045C104.46 12.2045 104.014 12.4489 103.71 12.9377C103.448 13.342 103.319 13.8347 103.319 14.4171C103.319 14.9826 103.449 15.4675 103.71 15.8718C104.026 16.3606 104.465 16.605 105.033 16.605C105.59 16.605 106.025 16.3567 106.34 15.8588C106.611 15.4467 106.744 14.9579 106.744 14.3924Z"
      fill="white"
    />
    <path
      d="M115.315 17.463H114.005L113.897 16.7571H113.856C113.408 17.32 112.77 17.6021 111.94 17.6021C111.321 17.6021 110.82 17.4162 110.443 17.047C110.101 16.7116 109.93 16.2943 109.93 15.799C109.93 15.0502 110.264 14.4795 110.936 14.0843C111.606 13.6891 112.55 13.4954 113.765 13.5045V13.3901C113.765 12.5828 113.311 12.1798 112.402 12.1798C111.755 12.1798 111.185 12.3319 110.692 12.6335L110.396 11.7391C111.005 11.3868 111.758 11.21 112.646 11.21C114.36 11.21 115.22 12.055 115.22 13.745V16.0018C115.22 16.6141 115.252 17.1016 115.315 17.463ZM113.801 15.357V14.4119C112.192 14.3859 111.388 14.798 111.388 15.6469C111.388 15.9667 111.48 16.2059 111.668 16.3658C111.855 16.5257 112.095 16.605 112.38 16.605C112.7 16.605 112.999 16.5101 113.272 16.3216C113.546 16.1318 113.714 15.8913 113.777 15.5962C113.792 15.5299 113.801 15.4493 113.801 15.357Z"
      fill="white"
    />
    <path
      d="M123.601 17.463H122.307L122.239 16.4789H122.199C121.786 17.2277 121.082 17.6021 120.092 17.6021C119.302 17.6021 118.644 17.3123 118.122 16.7324C117.6 16.1526 117.34 15.3999 117.34 14.4756C117.34 13.4837 117.622 12.6803 118.19 12.0667C118.74 11.4947 119.413 11.2087 120.215 11.2087C121.095 11.2087 121.712 11.4856 122.062 12.0407H122.09V8.52295H123.55V15.812C123.55 16.4087 123.567 16.9586 123.601 17.463ZM122.09 14.8786V13.8568C122.09 13.68 122.076 13.537 122.05 13.4278C121.968 13.1002 121.791 12.8246 121.523 12.6023C121.251 12.38 120.924 12.2682 120.547 12.2682C120.003 12.2682 119.577 12.4697 119.264 12.874C118.954 13.2783 118.797 13.7944 118.797 14.4249C118.797 15.0307 118.946 15.5221 119.245 15.9004C119.561 16.3034 119.986 16.5049 120.519 16.5049C120.998 16.5049 121.381 16.3372 121.671 16.0005C121.953 15.6898 122.09 15.3154 122.09 14.8786Z"
      fill="white"
    />
    <path
      d="M136.073 14.3482C136.073 15.2907 135.785 16.0629 135.209 16.6687C134.605 17.2914 133.806 17.6021 132.806 17.6021C131.844 17.6021 131.077 17.3044 130.504 16.7064C129.934 16.1097 129.648 15.357 129.648 14.4496C129.648 13.5006 129.942 12.7219 130.532 12.1174C131.122 11.5129 131.915 11.21 132.916 11.21C133.877 11.21 134.652 11.5077 135.237 12.1044C135.793 12.6842 136.073 13.433 136.073 14.3482ZM134.562 14.3924C134.562 13.8269 134.431 13.342 134.171 12.9377C133.863 12.4489 133.429 12.2045 132.861 12.2045C132.277 12.2045 131.832 12.4489 131.524 12.9377C131.263 13.342 131.133 13.8347 131.133 14.4171C131.133 14.9826 131.264 15.4675 131.524 15.8718C131.84 16.3606 132.28 16.605 132.847 16.605C133.404 16.605 133.842 16.3567 134.157 15.8588C134.425 15.4467 134.562 14.9579 134.562 14.3924Z"
      fill="white"
    />
    <path
      d="M143.914 17.463H142.457V13.953C142.457 12.8714 142.018 12.3306 141.134 12.3306C140.701 12.3306 140.352 12.4788 140.082 12.7765C139.812 13.0742 139.677 13.4252 139.677 13.8269V17.4617H138.218V13.0859C138.218 12.5477 138.201 11.964 138.166 11.3322H139.446L139.514 12.2903H139.555C139.726 11.9926 139.979 11.7469 140.31 11.5506C140.707 11.3218 141.148 11.2061 141.634 11.2061C142.244 11.2061 142.754 11.3907 143.159 11.7612C143.664 12.2149 143.914 12.8922 143.914 13.7918V17.463Z"
      fill="white"
    />
    <path
      d="M153.729 12.3553H152.124V15.3323C152.124 16.0889 152.409 16.4672 152.974 16.4672C153.235 16.4672 153.452 16.4464 153.624 16.4035L153.661 17.437C153.373 17.5384 152.995 17.5891 152.528 17.5891C151.952 17.5891 151.504 17.4253 151.18 17.0977C150.855 16.7701 150.693 16.2189 150.693 15.4454V12.3553H149.734V11.3348H150.693V10.2116L152.122 9.80859V11.3335H153.728V12.3553H153.729Z"
      fill="white"
    />
    <path
      d="M161.449 17.463H159.989V13.979C159.989 12.8805 159.549 12.3306 158.668 12.3306C157.992 12.3306 157.53 12.6491 157.277 13.2861C157.234 13.42 157.209 13.5838 157.209 13.7762V17.4618H155.752V8.52295H157.209V12.2162H157.237C157.696 11.5441 158.354 11.2087 159.207 11.2087C159.811 11.2087 160.31 11.3933 160.707 11.7638C161.201 12.2253 161.449 12.9117 161.449 13.8191V17.463Z"
      fill="white"
    />
    <path
      d="M169.412 14.1089C169.412 14.3533 169.392 14.5587 169.357 14.7264H164.984C165.004 15.3322 165.212 15.7937 165.617 16.1135C165.987 16.3995 166.465 16.5425 167.049 16.5425C167.696 16.5425 168.286 16.4463 168.818 16.2526L169.046 17.199C168.424 17.4512 167.692 17.5773 166.844 17.5773C165.829 17.5773 165.029 17.2978 164.45 16.7388C163.868 16.1798 163.58 15.4297 163.58 14.4885C163.58 13.5642 163.849 12.7946 164.39 12.181C164.955 11.5258 165.719 11.1982 166.683 11.1982C167.626 11.1982 168.343 11.5258 168.827 12.181C169.218 12.701 169.412 13.3445 169.412 14.1089ZM168.02 13.7566C168.031 13.3523 167.935 13.0039 167.738 12.7101C167.485 12.3318 167.099 12.142 166.577 12.142C166.101 12.142 165.713 12.3266 165.417 12.6971C165.175 12.9922 165.031 13.3445 164.984 13.7566H168.02Z"
      fill="white"
    />
  </svg>
);

export default AppleAppStore;
