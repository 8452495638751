import { Collectible } from "@api/collectibles";
import IframeCollectibleListCard from "@molecules/iframe-collectible-list-card";
import IframeCollectibleListMobileCard from "@molecules/iframe-collectible-list-mobile-card";
import React from "react";
import IframeHeading from "@atoms/iframe-heading";
import { useMediaQuery } from "react-responsive";

type CollectiblesListingProps = {
  collectibles?: Collectible[];
  isLoading: boolean;
};

function IframeCollectibleListing({ collectibles, isLoading }: CollectiblesListingProps) {
  const isMobile = useMediaQuery({ query: "(max-width: 420px)" });

  return (
    <>
      <IframeHeading title={"Collectables"}></IframeHeading>
      <div className="grid grid-cols-1 gap-y-2 pt-5 w-full">
        {collectibles?.map((collectible, index) => {
          const detailUrl = `/collectables/${collectible.uuid}`;
          return (
            <React.Fragment key={`/collectables/${collectible.uuid}`}>
              <div className="block" key={collectible.uuid}>
                {isMobile ? (
                  <IframeCollectibleListMobileCard {...collectible} loading={isLoading} {...{ detailUrl }} />
                ) : (
                  <IframeCollectibleListCard {...collectible} loading={isLoading} {...{ detailUrl }} />
                )}
                {index + 1 !== collectibles.length && <div className="h-px my-6 bg-white/50" />}
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
}

export default IframeCollectibleListing;
