import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { fetchFeaturedEvents } from "@api/events";
import Home, { Props as HomeProps } from "@pages/home";
import HeadTitle from "@atoms/head-title/head-title";
import Layout from "@templates/layout/layout";

const collectibles = [
  {
    id: 1,
    imgUrl:
      "https://images.unsplash.com/photo-1540039155733-5bb30b53aa14?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=80&raw_url=true&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074",
    editionsLeft: 12,
    creator: "Ernestine52",
    gbpPrice: 625.95,
    usdPrice: 906.17,
    name: "Joker NFT #12345",
  },
  {
    id: 2,
    imgUrl:
      "https://images.unsplash.com/photo-1540039155733-5bb30b53aa14?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=80&raw_url=true&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074",
    editionsLeft: 12,
    creator: "Ernestine52",
    gbpPrice: 625.95,
    usdPrice: 906.17,
    name: "Joker NFT #12345",
  },
  {
    id: 3,
    imgUrl:
      "https://images.unsplash.com/photo-1540039155733-5bb30b53aa14?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=80&raw_url=true&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074",
    editionsLeft: 12,
    creator: "Ernestine52",
    gbpPrice: 625.95,
    usdPrice: 906.17,
    name: "Joker NFT #12345",
  },
];
const user = {
  name: "Joe",
  userName: "joeblogg.near",
  seatCoins: 1960,
  unclaimedGold: 4,
  unclaimedHolographic: 9,
  unclaimedSapphire: 2,
};
function Homepage() {
  const featuredEventQuery = useQuery("featuredEvents", fetchFeaturedEvents);
  const navigate = useNavigate();

  const events: HomeProps["events"] | undefined = (() => {
    return featuredEventQuery.isSuccess
      ? featuredEventQuery.data.map((event) => {
          const remainingTickets = event.ticketTypes.reduce(
            (total, ticketType) => total + ticketType.ticketsRemaining,
            0
          );
          return { ...event, date: event.startDate, imgUrl: event.thumbnailUrl, remainingTickets };
        })
      : undefined;
  })();

  return (
    <Layout showBgImage>
      <HeadTitle />
      <Home
        {...{ user, collectibles }}
        loadingEvents={featuredEventQuery.isFetching}
        events={events}
        onViewEvent={(ev) => navigate(`/events/${ev.slug}`)}
        onViewAllEvents={() => navigate("/events")}
      />
    </Layout>
  );
}

export default Homepage;
