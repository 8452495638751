import { Calendar, Clock } from "@atoms/icons";
import { format } from "date-fns";

export type DateTimeProps = {
  startDate: string | Date;
  endDate: string | Date;
  dateFormat?: "dd/MM/yy HH:mm" | "dd/MM/yy";
  fontSize?: string;
  timezone?: string;
};

export default function EventDateTime({
  startDate,
  endDate,
  dateFormat = "dd/MM/yy",
  fontSize = "text-xl",
  timezone,
}: DateTimeProps) {
  const startDay = format(new Date(startDate), dateFormat);
  const endDay = endDate && format(new Date(endDate), dateFormat);
  const eventIsMultiDay = endDate && startDay !== endDay;
  return (
    <div className={`flex flex-row flex-wrap justify-between ${fontSize}`}>
      <span className="flex flex-row items-center space-x-3 mr-5">
        <Calendar style={{ height: 50 }} />
        <span className="text-white">
          {startDay} {eventIsMultiDay && `- ${endDay}`}
        </span>
      </span>
      <span className="flex flex-row items-center space-x-2 text-white">
        <Clock style={{ height: 50 }} />
        <span>{format(new Date(startDate), "HH:mm")}</span>
        {!eventIsMultiDay && (
          <>
            <span>-</span>
            <span>{format(new Date(endDate), "HH:mm")}</span>
          </>
        )}
        {timezone && <span>({timezone})</span>}
      </span>
    </div>
  );
}
