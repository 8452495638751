import { DateRangePicker, DateRange, BaseDateRangePickerProps } from "@material-ui/pickers";
import Input from "@atoms/input";
import classNames from "classnames";
import { createTheme, ThemeProvider } from "@material-ui/core";
import { ExtendWrapper } from "@material-ui/pickers/wrappers/Wrapper";
import { AllSharedDateRangePickerProps } from "@material-ui/pickers/DateRangePicker/RangeTypes";
import { ResponsiveWrapperProps } from "@material-ui/pickers/wrappers/ResponsiveWrapper";
import { FC } from "react";

type Props = Omit<
  BaseDateRangePickerProps<Date> &
    ExtendWrapper<FC<ResponsiveWrapperProps>> &
    AllSharedDateRangePickerProps<Date> &
    React.RefAttributes<HTMLDivElement>,
  "renderInput"
> & {
  value: DateRange<Date>;
  onChange: (range: DateRange<Date>) => void;
  horizontal?: boolean;
  disabled?: [boolean, boolean];
  errors?: [string, string];
  placeholders?: [string | null, string | null];
};

function MaterialDateRangePicker({ value, onChange, disabled, horizontal, errors, placeholders, ...rest }: Props) {
  const [startError, endError] = errors || [];
  const [startDisabled, endDisabled] = disabled || [];
  const [startPlaceholder, endPlaceholder] = placeholders || [];

  const theme = createTheme({
    overrides: {
      ...({
        MuiPickersArrowSwitcher: {
          iconButton: {
            backgroundColor: "#042241",
          },
        },
      } as any),
    },
    typography: {
      fontFamily: "Cera Pro",
      allVariants: {
        color: "#fff",
      },
    },
    palette: {
      text: {
        primary: "#fff",
        secondary: "#fff",
      },
      primary: {
        main: "#0067FF",
      },
      action: {
        active: "#fff",
      },
      background: {
        paper: "#031629",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <DateRangePicker
        clearable
        startText={startPlaceholder}
        endText={endPlaceholder}
        calendars={2}
        mask="__/__/____"
        value={value}
        onChange={(newValue) => onChange(newValue)}
        renderInput={(start, end) => {
          const startLabel = typeof start.label === "string" ? start.label : undefined;
          const endLabel = typeof end.label === "string" ? end.label : undefined;
          return (
            <div
              className={classNames("flex gap-2 flex-1 w-full", { "flex-row": horizontal, "flex-col": !horizontal })}
            >
              <Input
                ref={start.inputRef}
                disabled={startDisabled}
                error={startError}
                label={startLabel}
                {...start.inputProps}
              />
              <Input ref={end.inputRef} disabled={endDisabled} error={endError} label={endLabel} {...end.inputProps} />
            </div>
          );
        }}
        {...rest}
      />
    </ThemeProvider>
  );
}

export default MaterialDateRangePicker;
