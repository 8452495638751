import { Event } from "@api/events";
import EventDetailsPanel from "@molecules/event-details-card/event-details-panel";
import Avatar from "@atoms/avatar";
import Button from "@atoms/button";
import { MapPin } from "@atoms/icons";
import { useMediaQuery } from "react-responsive";
import EventTicketsList from "@organisms/event-tickets-list";
import EventDateTime from "@molecules/event-date-time";
import { ComponentProps, useMemo, useState } from "react";
import { Ticket } from "@api/tickets";
import { priceFormatter } from "@utils/formatters";
import PageContainer from "@atoms/page-container";
import EventHeaderThumbnail from "@organisms/event-header-thumbnail";
import ShareButton from "@molecules/share-button";
import Collapsible from "@atoms/collapsible";
import useImagekitUrl from "@hooks/use-imagekit-url";
import { UseMutationResult } from "react-query";
import ImagekitImage from "@molecules/imagekit-image";

type Props = {
  occassion: Event;
  onPurchaseTickets: UseMutationResult<any, unknown, { [uuid: string]: number }, unknown>;
  urlOnlyEvent: boolean;
  timezone?: string;
  priceIncludesVat?: boolean;
  ticketTransfersEnabled: boolean;
};

function EventDetailsPage({
  occassion,
  onPurchaseTickets,
  urlOnlyEvent,
  timezone,
  priceIncludesVat,
  ticketTransfersEnabled,
}: Props) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isMdScreenOrLower = useMediaQuery({ query: "(max-width: 767px)" });
  const isPhablet = useMediaQuery({ minWidth: 500 });
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [selectedTickets, setSelectedTickets] = useState<{ [ticketUuid: string]: number }>({});
  const { seller } = occassion;
  const mobileCoverWidth = isPhablet ? 600 : 300;
  const eventThumbnailUrl = useImagekitUrl(occassion.thumbnailUrl, {
    transformation: [{ width: "450" }],
  });
  const sellerThumbnailUrl = useImagekitUrl(seller.thumbnailUrl, { transformation: [{ width: "60", radius: "6" }] });

  const onSelectTicket = (ticket: Ticket, quantity: number) => {
    setSelectedTickets((current) => ({ ...current, [ticket.uuid]: quantity }));
  };

  const totalSelectedTicketCost = useMemo(() => {
    const ticketTypesByUUID = occassion.ticketTypes.reduce((allTickets, ticketType) => {
      return { ...allTickets, [ticketType.uuid]: ticketType };
    }, {} as { [uuid: string]: Ticket });
    return Object.keys(selectedTickets).reduce((total, ticketUUID) => {
      const ticket = ticketTypesByUUID[ticketUUID];
      if (!ticket) throw new Error("Could not find ticket");
      const ticketTotal = ticket.price * (selectedTickets[ticketUUID] || 0);
      return total + ticketTotal;
    }, 0);
  }, [selectedTickets, occassion.ticketTypes]);

  const hasSelectedTickets = useMemo(() => {
    if (!!Object.keys(selectedTickets).length) {
      return Object.values(selectedTickets).some((quantity) => quantity > 0);
    }
    return false;
  }, [selectedTickets]);

  const hasLotteryTicket =
    occassion.ticketTypes.length > 0 ? occassion.ticketTypes.some(({ lottery }) => !!lottery) : false;
  const isLotteryOnly =
    occassion.ticketTypes.length > 0
      ? occassion.ticketTypes.every(({ lottery }) => {
          return !!lottery;
        })
      : false;
  const thumbVariant: ComponentProps<typeof EventHeaderThumbnail>["variant"] = (() => {
    if (urlOnlyEvent && occassion.ticketTypes.length === 0) return "unavailable";
    if (hasLotteryTicket) return "lottery";
    if (occassion.isEnded) return "ended";
    return "default";
  })();
  const headerThumbnailProps = {
    variant: thumbVariant,
    thumbnailUrl: eventThumbnailUrl,
  };
  return (
    <PageContainer>
      <div className="text-white">
        <div className="mb-8 md:mb-14">
          <div className="relative w-full md:py-10 md:h-[524px] md:p-4 flex flex-row items-center">
            <div className="flex flex-row justify-between w-full h-full">
              <div className="flex flex-col justify-between md:items-start w-full z-10 md:basis-2/4 md:grow-0 md:shrink-0">
                <div className="flex flex-col justify-between relative w-full">
                  <div className="flex flex-row justify-between items-center mb-4 md:mb-4 z-10">
                    <div>
                      <div className="text-sm md:text-xl font-bold text-white mb-1 md:mb-2">Organiser:</div>
                      <Avatar
                        imgSrc={sellerThumbnailUrl}
                        label={seller.name || seller.walletAddress}
                        size="medium"
                        labelClassName="text-white"
                        verified={seller.isVerified}
                      />
                    </div>
                    <ShareButton
                      title={occassion.name}
                      text={"Hey, check this event out on SeatlabNFT. Do you want to go?"}
                    />
                  </div>
                  <hr className="hidden md:block opacity-40 bg-brand-black" />
                </div>
                <div className="md:relative z-10 w-full">
                  <h1 className="text-2xl md:text-4xl font-bold text-white">{occassion.name}</h1>
                  <div className="flex flex-row align-center space-x-9 mt-6">
                    <EventDateTime startDate={occassion.startDate} endDate={occassion.endDate} {...{ timezone }} />
                  </div>
                  <span className="flex flex-row items-center space-x-3 md:hidden">
                    <MapPin />
                    <p className="mt-3 text-lg underline ">{occassion.venueName}</p>
                  </span>
                  <div className="flex items-center relative w-full md:hidden mt-8">
                    <ImagekitImage
                      src={occassion.coverPhotoUrl}
                      transformation={[{ width: mobileCoverWidth.toString() }]}
                      alt="cover"
                      className="w-screen max-w-none rounded-2xl aspect-2/1 object-cover relative left-2/4 right-2/4 -ml-[50vw] -mr-[50vw]"
                    />
                    <div className="absolute bg-black/70 inset-0 w-screen -ml-[20px]" />
                    <EventHeaderThumbnail {...headerThumbnailProps} isMobile={true} />
                  </div>
                </div>
                <div className="w-full">
                  <hr className="hidden md:block md:mb-2 opacity-40 bg-brand-black" />
                  <span className="md:flex flex-row items-center space-x-3 hidden">
                    <MapPin />
                    <p className="mt-3 text-lg underline ">{occassion.venueName}</p>
                  </span>
                </div>
              </div>
              {
                <div className="hidden md:flex md:justify-end">
                  <EventHeaderThumbnail {...headerThumbnailProps} isMobile={false} />
                </div>
              }
            </div>
            <span className="hidden md:block">
              <div className="absolute h-[524px] inset-0 w-full -left-[4%] overflow-hidden rounded-3xl">
                <ImagekitImage
                  src={occassion.coverPhotoUrl}
                  alt="cover"
                  transformation={[{ width: "1024" }]}
                  className="object-cover h-full w-full"
                />
                <div className="absolute bg-black/70 inset-0" />
              </div>
            </span>
          </div>
        </div>

        <div className="flex flex-col md:flex-row md:gap-6 md:mb-4">
          <div className="flex flex-col md:w-1/2 lg:w-3/5 md:h-full">
            <EventDetailsPanel
              title="About the organiser"
              containerClassName="w-full hidden md:block"
              contentClassName="max-h-52"
            >
              <div className="flex flex-col lg:flex-row justify-between my-4">
                <div>
                  {" "}
                  {/* Add "md:w-2/3" className to this div if adding View Profile button again */}
                  <Avatar
                    imgSrc={sellerThumbnailUrl}
                    label={seller.name || seller.walletAddress}
                    containerClassName="min-h-[48px]"
                    verified={seller.isVerified}
                  />
                  {seller.bio && <p className="mt-4 whitespace-pre-wrap">{seller.bio}</p>}
                  {seller.supportEmail && (
                    <p className="mt-4">
                      Support: <a href={`mailto:${seller.supportEmail}`}>{seller.supportEmail}</a>
                    </p>
                  )}
                </div>
                {/* <Button className="mt-4 lg:mt-0" variant="outline" size="medium">
                  View Profile
                </Button> */}
              </div>
            </EventDetailsPanel>
            <div className="sm:mt-16  relative">
              <h2 className="text-2xl text-white font-700">
                {isLotteryOnly ? "About This Lottery" : "About This Event"}
              </h2>
              <br />
              {isMdScreenOrLower ? (
                <Collapsible
                  open={showFullDescription}
                  onToggle={setShowFullDescription}
                  shrinkHeight="xs"
                  openLabel=""
                  closeLabel=""
                  iconClass="w-4 h-4"
                >
                  <p className="whitespace-pre-wrap">{occassion.description}</p>
                </Collapsible>
              ) : (
                <div className="relative max-h-[512px] overflow-auto scrollbar-thin scrollbar-thumb-grey scrollbar-track-slate/50 scrollbar-thumb-rounded whitespace-pre-wrap">
                  {occassion.description}
                </div>
              )}
            </div>
            <div className="mt-5">
              {occassion.eventPageButton_1Url && occassion.eventPageButton_1Text && (
                <span className="mr-4">
                  <Button
                    className="h-11 px-6"
                    size="medium"
                    onClick={() => {
                      window.open(occassion.eventPageButton_1Url, "_blank");
                    }}
                    variant="primary"
                  >
                    {occassion.eventPageButton_1Text}
                  </Button>
                </span>
              )}
              {occassion.eventPageButton_2Url && occassion.eventPageButton_2Text && (
                <span className="mr-4">
                  <Button
                    className="h-11 px-6"
                    size="medium"
                    onClick={() => {
                      window.open(occassion.eventPageButton_2Url, "_blank");
                    }}
                    variant="primary"
                  >
                    {occassion.eventPageButton_2Text}
                  </Button>
                </span>
              )}
            </div>
            {/* About the event panel */}
          </div>
          {/* Buy ticket panel  */}
          <div className="md:w-1/2 lg:w-2/5">
            <EventDetailsPanel
              title={isLotteryOnly ? "Enter the lottery" : "Buy tickets for this event"}
              headerClassName="bg-blue"
              containerClassName="md:max-h-[915px]"
              footer={
                hasSelectedTickets ? (
                  <div className="flex flex-row pl-20 pr-2 md:pr-10 py-4 justify-end space-x-4 bg-dark-panel">
                    <div className="text-right text-xl text-white">
                      <p>Total Cost:</p>
                      <p>
                        {occassion.seller.currencySymbol}
                        {priceFormatter(totalSelectedTicketCost)}
                      </p>
                    </div>
                    <Button
                      disabled={onPurchaseTickets.isLoading}
                      onClick={() => onPurchaseTickets.mutate(selectedTickets)}
                    >
                      {isLotteryOnly ? "Enter lottery" : "Buy now"}
                    </Button>
                  </div>
                ) : null
              }
            >
              <div className="my-5">
                {occassion.ticketTypes.length < 1 && occassion.eventPageButton_1Url && occassion.eventPageButton_1Text && (
                  <span className="mr-4">
                    <Button
                      className="h-11 px-6"
                      size="medium"
                      onClick={() => {
                        window.open(occassion.eventPageButton_1Url, "_blank");
                      }}
                      variant="primary"
                    >
                      {occassion.eventPageButton_1Text}
                    </Button>
                  </span>
                )}
                {occassion.ticketTypes.length < 1 && occassion.eventPageButton_2Url && occassion.eventPageButton_2Text && (
                  <span className="mr-4">
                    <Button
                      className="h-11 px-6 mt-4"
                      size="medium"
                      onClick={() => {
                        window.open(occassion.eventPageButton_2Url, "_blank");
                      }}
                      variant="primary"
                    >
                      {occassion.eventPageButton_2Text}
                    </Button>
                  </span>
                )}
                <h2 className="text-lg text-white mb-4">
                  {occassion.ticketTypes.length > 1 && isLotteryOnly
                    ? "Download the SeatlabNFT app to enter this lottery."
                    : null}
                  {occassion.ticketTypes.length > 1 && !isLotteryOnly
                    ? "Please select your ticket type and quantity, then proceed to “Buy now”."
                    : null}
                </h2>
                <EventTicketsList
                  tickets={occassion.ticketTypes}
                  selectedTickets={selectedTickets}
                  onSelectTicket={onSelectTicket}
                  showPriceInclVat={priceIncludesVat}
                  ticketTransfersEnabled={ticketTransfersEnabled}
                  currencySymbol={occassion.seller.currencySymbol}
                />
              </div>
            </EventDetailsPanel>
            <EventDetailsPanel
              title="About the organiser"
              containerClassName="w-full mt-5 max-h-[300px] md:hidden"
              collapsible={isTabletOrMobile}
            >
              <div className="flex flex-col lg:flex-row justify-between my-4">
                <div>
                  {" "}
                  {/* Add "md:w-2/3" className to this div if adding View Profile button again */}
                  <Avatar
                    imgSrc={seller.thumbnailUrl}
                    label={seller.name || seller.walletAddress}
                    containerClassName="min-h-[48px]"
                    verified={seller.isVerified}
                  />
                  {seller.bio && <p className="mt-4">{seller.bio}</p>}
                  {seller.supportEmail && (
                    <p className="mt-4">
                      Support: <a href={`mailto:${seller.supportEmail}`}>{seller.supportEmail}</a>
                    </p>
                  )}
                </div>
                {/* <Button className="mt-4 lg:mt-0" variant="outline" size="medium">
                  View Profile
                </Button> */}
              </div>
            </EventDetailsPanel>
          </div>
        </div>
      </div>
    </PageContainer>
  );
}

export default EventDetailsPage;
