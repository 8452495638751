import { Collectible } from "@api/collectibles";
import { ReactComponent as Play } from "@assets/icons/play.svg";
import Svg from "@atoms/svg";
import useModal from "@hooks/use-modal";
import ImagekitImage from "@molecules/imagekit-image";

export const CollectableDetailImage = ({ imageUrl, audioMp3Url }: Pick<Collectible, "imageUrl" | "audioMp3Url">) => {
  const { showModal } = useModal();

  const onPlay = () => {
    showModal({ title: "Purchase required", children: "Requires collectable to be purchased in order to play" });
  };
  return (
    <div className="flex flex-col justify-center bg-dark-panel rounded-3xl overflow-hidden relative">
      <ImagekitImage className="object-contain aspect-square" src={imageUrl} alt="Collectible" />
      {!!audioMp3Url && (
        <div className="bg-black/20 absolute inset-0 flex items-center justify-center">
          <button className="bg-blue p-4 pl-5 aspect-square rounded-full block" onClick={() => onPlay()}>
            <Svg icon={Play} size="large" />
          </button>
        </div>
      )}
    </div>
  );
};
