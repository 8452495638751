import { Ticket } from "@api/tickets";
import EventTicketCard from "@molecules/event-ticket-card";

export type TicketListProps = {
  tickets: Ticket[];
  onSelectTicket: (ticket: Ticket, quantity: number) => void;
  selectedTickets?: { [uuid: string]: number };
  showPriceInclVat?: boolean;
  ticketTransfersEnabled: boolean;
  currencySymbol: string;
};

export default function EventTicketsList({
  tickets,
  onSelectTicket,
  selectedTickets,
  showPriceInclVat,
  ticketTransfersEnabled,
  currencySymbol,
}: TicketListProps) {
  return (
    <div className="flex flex-col space-y-3 mb-2">
      {tickets.map((ticket, index) => {
        if (ticket.lottery) return null;
        const selectedQuantity = selectedTickets?.[ticket.uuid] || 0;
        return (
          <EventTicketCard
            key={index}
            ticket={ticket}
            selectedQuantity={selectedQuantity}
            onSelectQuantity={(quantity) => onSelectTicket(ticket, quantity)}
            inclVat={showPriceInclVat}
            ticketTransfersEnabled={ticketTransfersEnabled}
            // collectibles={ticket.collectibles}
            currencySymbol={currencySymbol}
          />
        );
      })}
    </div>
  );
}
