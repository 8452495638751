import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.998 20.0001C5.47505 20.0001 0.998047 15.5231 0.998047 10.0001C0.998047 4.47706 5.47505 6.10352e-05 10.998 6.10352e-05C16.521 6.10352e-05 20.998 4.47706 20.998 10.0001C20.998 15.5231 16.521 20.0001 10.998 20.0001ZM10.998 18.0001C13.1198 18.0001 15.1546 17.1572 16.6549 15.6569C18.1552 14.1566 18.998 12.1218 18.998 10.0001C18.998 7.87833 18.1552 5.8435 16.6549 4.34321C15.1546 2.84292 13.1198 2.00006 10.998 2.00006C8.87632 2.00006 6.84148 2.84292 5.34119 4.34321C3.8409 5.8435 2.99805 7.87833 2.99805 10.0001C2.99805 12.1218 3.8409 14.1566 5.34119 15.6569C6.84148 17.1572 8.87632 18.0001 10.998 18.0001ZM11.998 10.0001H15.998V12.0001H9.99805V5.00006H11.998V10.0001Z"
      fill="white"
    />
  </svg>
);

export default SvgComponent;
