import cn from "classnames";
import { ReactNode, useState } from "react";

type Props = {
  value?: string;
  children: ReactNode;
  label: string;
};

function StripeInput({ value, children, label }: Props) {
  const [inputIsFocused, setInputIsFocused] = useState(false);

  const shouldShrinkLabel = inputIsFocused || !!value;
  const labelBasePositioning = cn("transition-transform origin-top-left absolute top-5 left-4");
  const labelClass = cn(
    labelBasePositioning,
    "text-grey z-10 items-center flex pointer-events-none scale-75 -translate-y-2"
  );
  return (
    <div className="rounded-md px-4 pt-7 pb-2 bg-brand-black relative h-16 w-full flex flex-col justify-center">
      <label className={labelClass}>{label}</label>
      {children}
    </div>
  );
}

export default StripeInput;
