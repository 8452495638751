import cx from "classnames";

type Props = {
  thumbnailUrl: string;
  isMobile: boolean;
  variant?: "default" | "unavailable" | "lottery" | "comingSoon" | "ended";
  className?: string;
};

function EventHeaderThumbnail({ thumbnailUrl, isMobile, variant = "default", className }: Props) {
  const bannerClasses = () => {
    switch (variant) {
      case "lottery":
      case "comingSoon":
        return "bg-blue text-white";
      case "ended":
        return "bg-danger text-white";
      case "unavailable":
        return "bg-warning text-navy";
      default:
        return "";
    }
  };
  const bannerText = () => {
    switch (variant) {
      case "lottery":
        return "Ticket lottery";
      case "comingSoon":
        return "Tickets available soon";
      case "ended":
        return "This event has ended";
      case "unavailable":
        return "No tickets available for this event";
      default:
        return "";
    }
  };
  const classes = cx(
    className,
    "rounded-3xl object-cover aspect-square overflow-hidden relative mt-0 bg-dark-panel flex items-center",
    {
      "w-[50%] md:w-[35%] -top-1/4 lg:h-[80%] z-10 md:top-0 md:mt-[-20px] md:mt-0 md:inset-y-[10%] inset-x-[25%] md:inset-x-[70%] md:right-0 md:mr-[-40px]":
        isMobile,
      "h-[60%] lg:h-[80%] min-h-[60%] lg:min-h-[80%]  z-20": !isMobile,
    }
  );

  return (
    <div className={classes}>
      <img src={thumbnailUrl} alt="event poster" />
      {variant !== "default" && (
        <div className={cx("absolute bottom-0 left-0 w-full p-3 text-center font-bold", bannerClasses())}>
          {bannerText()}
        </div>
      )}
    </div>
  );
}

export default EventHeaderThumbnail;
