import BorderedCard from "@molecules/bordered-card/bordered-card";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import StripeInput from "@atoms/stripe-input";
import Input from "@atoms/input";
import { useState } from "react";
import Button from "@atoms/button";
import { StripeCardNumberElement } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import useModal from "@hooks/use-modal";

const inputStyle = {
  color: "white",
  fontSize: "16px",
  fontFamily: "Cera Pro, sans-serif",
};

export default function IframePaymentSection({ clientSecret }: { clientSecret: string }) {
  const { showModal } = useModal();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [name, setName] = useState<string>("");
  const disableSubmit = !stripe || !elements;

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (disableSubmit) {
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardNumberElement as StripeCardNumberElement,
      },
    });

    if (result.error) {
      console.error(result.error.message);
      showModal({ title: "An error occurred", children: result.error.message });
    } else {
      // Check if the PaymentIntent requires any actions and if so let Stripe.js handle the flow.
      if (result.paymentIntent && result.paymentIntent.status === "requires_action") {
        // Let Stripe.js handle the rest of the payment flow.
        const { error } = await stripe.confirmCardPayment(clientSecret);
        if (error) {
          // The payment failed -- ask your customer for a new payment method.
          console.error(error.message);
          showModal({ title: "An error occurred", children: error.message });
        } else {
          // The payment has succeeded.
          navigate("/iframe/purchase-success");
        }
      } else {
        // The payment has succeeded.
        navigate("/iframe/purchase-success");
      }
    }
  };

  return (
    <BorderedCard title="Pay by card">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <Input label="Name on card" onChange={(e) => setName(e.target.value)} value={name} />
        <StripeInput label="Card number">
          <CardNumberElement className="bg-brand-black" options={{ placeholder: "", style: { base: inputStyle } }} />
        </StripeInput>
        <StripeInput label="Expiry date">
          <CardExpiryElement className="bg-brand-black" options={{ placeholder: "", style: { base: inputStyle } }} />
        </StripeInput>
        <StripeInput label="CVC">
          <CardCvcElement className="bg-brand-black" options={{ placeholder: "", style: { base: inputStyle } }} />
        </StripeInput>
      </div>
      <Button className="mt-6" disabled={disableSubmit} onClick={handleSubmit}>
        Finish & pay
      </Button>
    </BorderedCard>
  );
}
