import Heading from "@atoms/heading";
import React, { ReactNode, FC, useEffect } from "react";
import { Close } from "@atoms/icons";
import SVG from "@atoms/svg";
import useBodyOverflowHidden from "@hooks/use-body-overflow-hidden";

export type Props = {
  children: ReactNode;
  closeModal: () => void;
  footerContent?: ReactNode;
  show?: boolean;
  subTitle?: string;
  title?: string;
};

function Modal({ children, closeModal, footerContent, show, subTitle, title }: Props) {
  const { setBodyOverflowHidden, removeBodyOverflowHidden } = useBodyOverflowHidden();

  const onModalClose = () => {
    closeModal();
    removeBodyOverflowHidden();
  };
  useEffect(() => {
    if (show) setBodyOverflowHidden();
  }, [show]);

  if (!show) {
    return null;
  }

  return (
    <div onClick={onModalClose} className="bg-darker-grey fixed inset-0 flex items-center justify-center z-50 p-10">
      <div
        onClick={(e) => e.stopPropagation()}
        className="bg-dark-panel rounded-lg mt-8 overflow-hidden flex-auto"
        style={{ maxWidth: "600px" }}
      >
        <div className="p-5 bg-layer-blue flex flex-row justify-between items-center">
          <div className="flex flex-row flex-1 ml-11 justify-center">
            <Heading className="text-center" as="h2" variant="heading2">
              {title}
            </Heading>
            {subTitle && <span className="pl-2">{subTitle}</span>}
          </div>
          <div className="w-11 aspect-square overflow-hidden">
            <SVG onClick={onModalClose} color="#737373" size="xxlarge" icon={Close} styles="cursor-pointer" />
          </div>
        </div>
        <div className="p-7">{children}</div>
        {footerContent && <div className="p-7 border-t border-white">{footerContent}</div>}
      </div>
    </div>
  );
}

export default Modal;
