import Button from "@atoms/button";
import TicketWalletStoreLink from "@organisms/ticket-wallet-store-link";

function AppInstallPrompt() {
  const url = window.location.href;

  return (
    <div className="flex text-center items-center flex-col w-full px-4 pt-10">
      <h1 className="text-4xl text-white font-bold mb-6">You’ll need our wallet to continue</h1>
      <p className="text-white mb-6 md:w-3/6">
        If you don’t already have it, download our wallet app to purchase/store tickets and collectables.{" "}
        <span className="font-bold">Get it below:</span>
      </p>
      <div style={{ height: 100, width: 400 }} className="flex space-x-4">
        <TicketWalletStoreLink platform="android" />
        <TicketWalletStoreLink platform="ios" />
      </div>
      <Button className="w-96">
        <a href={url} target="_blank" rel="noreferrer">
          Continue Using Wallet
        </a>
      </Button>
    </div>
  );
}

export default AppInstallPrompt;
