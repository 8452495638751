import { fetchStaticPageContent } from "@api/static-content";
import { useQuery } from "react-query";

type StaticPageSlugs = "faq" | "privacy-policy" | "terms-of-service" | "terms-of-use";

function useStaticContent(page: StaticPageSlugs) {
  const { data, isError, isLoading } = useQuery("static-content", fetchStaticPageContent);
  const pageData = data && data.find((content) => content.slug === page);
  return { data: pageData, isLoading, isError };
}

export default useStaticContent;
