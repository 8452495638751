import EventCard, { Props as EventCardProps } from "@molecules/event-card";
import ListPreview from "@molecules/list-preview";

export type EventListItem = EventCardProps & { id: number; slug: string };

export type Props<E = EventListItem> = {
  events: E[] | undefined;
  isLoading?: boolean;
  onViewEvents?: () => void;
  onViewEvent?: (event: E) => void;
  skeletonNum: number;
};

export default function ExploreEventsCards({ events, isLoading, onViewEvent, onViewEvents, skeletonNum }: Props) {
  const defaultList: EventListItem[] = new Array(skeletonNum).fill(null).map((_, idx) => ({
    id: idx,
    endDate: new Date(),
    startDate: new Date(),
    lowestPrice: 0,
    imgUrl: "",
    name: "",
    remainingTickets: 0,
    slug: "",
    seller: {
      isVerified: true,
      bio: "",
      coverPhotoUrl: "",
      id: idx,
      name: "",
      supportEmail: "",
      supportPhone: "",
      thumbnailUrl: "",
      walletAddress: "",
      currency: "GBP",
      currencySymbol: "£",
    },
  }));

  return (
    <ListPreview
      title="Explore events on our marketplace"
      data={events ?? defaultList}
      buttonText="View All Events"
      onButtonClick={onViewEvents}
      renderItem={({ data: event }) => {
        const onClick = onViewEvent ? () => onViewEvent(event) : undefined;
        return <EventCard {...{ ...event, onClick }} loading={isLoading} />;
      }}
    />
  );
}
