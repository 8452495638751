import { ReactNode } from "react";

const borderGradientStyle = {
  color: "#FFF",
  backgroundClip: "padding-box" /* !importanté */,
  border: "solid 3px transparent" /* !importanté */,
  borderRadius: "10px",
};

interface Props {
  children: ReactNode;
  className: string;
}

function GradientBorderSection({ children, className }: Props) {
  return (
    <section className={className} style={borderGradientStyle}>
      <div className="absolute before:inset-0 before:-z-10 before:-m-0.5 before:bg-gradient-to-l before:from-blue before:to-bluer" />
      {children}
    </section>
  );
}

export default GradientBorderSection;
