import { SVGProps } from "react";

const ErrorCross = (props: SVGProps<SVGSVGElement>) => (
  <svg width={55} height={55} viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="27.5" cy="27.5" r="26" stroke="#EB2359" strokeWidth="3" />
    <path
      d="M20.875 34.125L33.125 21.875"
      stroke="#EB2359"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.125 34.125L20.875 21.875"
      stroke="#EB2359"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ErrorCross;
