import { createContext, ReactNode, useEffect, useState } from "react";
import Pusher, { Channel } from "pusher-js";
import { createPusherClient } from "../api/lib";

type LoginCodeParams = { code: string; expires_at: number };
type LoginSessionId = string;
type AuthContextType = {
  profile?: Profile;
  loginParams: LoginCodeParams | undefined;
  loginSessionId: LoginSessionId | undefined;
  setLoginParams: (loginCode: LoginCodeParams) => void;
  setLoginSessionId: (sessionId: LoginSessionId) => void;
  setProfile: (profile: Profile) => void;
  listenForLogin: (code: string) => Channel | undefined;
  disconnectPusher: () => void;
  saveTokenToStorage: (token: string) => void;
};

const pusherApiKey = process.env.REACT_APP_PUSHER_API_KEY;
export const STORAGE_KEY = "seatlabMarketplace/AUTH_TOKEN";

export const AuthContext = createContext<AuthContextType>({
  profile: undefined,
  loginParams: undefined,
  loginSessionId: undefined,
  setLoginParams: () => undefined,
  setLoginSessionId: () => undefined,
  setProfile: () => undefined,
  listenForLogin: () => undefined,
  disconnectPusher: () => undefined,
  saveTokenToStorage: () => undefined,
});

if (!pusherApiKey) throw new Error("PUSHER_API_KEY is not set");

export function AuthContextProvider({ children, userProfile }: { children: ReactNode; userProfile?: Profile }) {
  const [profile, setProfile] = useState<Profile | undefined>(userProfile);
  const [pusherClient, setPusherClient] = useState<Pusher>();
  const [loginParams, setLoginParams] = useState<LoginCodeParams>();
  const [loginSessionId, setLoginSessionId] = useState<LoginSessionId>();

  useEffect(() => setProfile(userProfile), [userProfile]);

  const listenForLogin = (sessionId: string) => {
    const pusher = createPusherClient();
    setPusherClient(pusher);
    return pusher.subscribe(sessionId);
  };

  const disconnectPusher = () => {
    if (!pusherClient) return;
    pusherClient.disconnect();
    setPusherClient(undefined);
  };

  const saveTokenToStorage = (token: string) => window.localStorage.setItem(STORAGE_KEY, token);

  return (
    <AuthContext.Provider
      value={{
        disconnectPusher,
        listenForLogin,
        profile,
        setProfile,
        saveTokenToStorage,
        loginParams,
        setLoginParams,
        loginSessionId,
        setLoginSessionId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export type Profile = { [key: string]: any };
