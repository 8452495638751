import { Link, useNavigate, useParams } from "react-router-dom";
import { fetchCollectibleVerificationCode } from "@api/collectibles";
import CollectibleDetailsPage from "@pages/collectible-details";
import { useMutation, useQuery } from "react-query";
import HeadTitle from "@atoms/head-title/head-title";
import useCollectibles from "@hooks/use-collectibles";

function EventDetails() {
  const navigate = useNavigate();
  const params = useParams<{ collectableSlug: string }>();
  const { fetchCollectibleBySlug } = useCollectibles();
  const { data: collectible, isLoading } = useQuery(
    ["collectible", params.collectableSlug],
    ({ queryKey: [, collectibleSlug] }) => {
      if (!collectibleSlug) throw new Error("Event Slug not found in url");
      return fetchCollectibleBySlug(collectibleSlug);
    }
  );

  const getCollectiblesCode = useMutation(
    (selectedCollectible: string) => fetchCollectibleVerificationCode({ [selectedCollectible]: 1 }),
    {
      onSuccess: (code) => void navigate(`/buy-collectables/${code}`),
    }
  );

  if (isLoading) return <div />;

  if (!collectible) {
    return (
      <div>
        <h1 className="text-4xl">Sorry, this collectable cannot be found</h1>
        <Link to="/">Go back to collectables</Link>
      </div>
    );
  }

  return (
    <>
      <HeadTitle titlePrefix={collectible.title} image={collectible.imageUrl} />
      <CollectibleDetailsPage collectible={collectible} onPurchaseCollectible={getCollectiblesCode} />
    </>
  );
}

export default EventDetails;
