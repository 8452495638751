import { ChevronLeft, ChevronRight } from "@atoms/icons";
import classNames from "classnames";

const buttonClass = "w-10 h-10 bg-dark-panel text-base flex items-center justify-center";

type PaginationButtonsProps = {
  pages: number;
  setPage: (page: number) => void;
  activePage: number;
};

const PaginationButtons = ({ pages, setPage, activePage = 1 }: PaginationButtonsProps) => {
  const buttons = [];
  {
    let dotsAdded = false;
    for (let i = 0; i < pages; i++) {
      const pageNumber = i + 1;
      if (pages > 6 && pageNumber > 4 && pageNumber < pages - 1) {
        if (!dotsAdded) {
          dotsAdded = true;
          buttons.push({
            dots: true,
            label: "...",
            onClick: () => {},
            active: false,
          });
        }
      } else {
        buttons.push({
          label: pageNumber,
          onClick: () => setPage(pageNumber),
          active: activePage === pageNumber,
        });
      }
    }
  }
  return (
    <div className="flex flex-row gap-x-2">
      <button
        onClick={() => setPage(activePage - 1)}
        className={classNames(buttonClass, { invisible: activePage === 1 })}
      >
        <ChevronLeft className="h-4 w-4" />
      </button>
      {buttons.map((item) => (
        <button
          key={item.label}
          className={classNames(buttonClass, {
            "font-bold": item.active,
            "font-light": !item.active,
            "cursor-pointer": !item.dots,
            "cursor-default": item.dots,
          })}
          onClick={item.onClick}
        >
          {item.label}
        </button>
      ))}
      <button
        onClick={() => setPage(activePage + 1)}
        className={classNames(buttonClass, { invisible: activePage === pages })}
      >
        <ChevronRight className="h-4 w-4" />
      </button>
    </div>
  );
};

export default PaginationButtons;
