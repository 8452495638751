import useAuth from "@hooks/use-auth";
import * as EventsApi from "@api/events";

function useEvents() {
  const { profile } = useAuth();

  return {
    ...EventsApi,
    fetchEventQuestions: (slug: string) => EventsApi.fetchEventQuestions(slug),
    fetchEventBySlug: (slug: string) => EventsApi.fetchEventBySlug(slug, !!profile),
    fetchUrlOnlyEvent: (slug: string, ticketId: string) => EventsApi.fetchUrlOnlyEvent(slug, ticketId, !!profile),
  };
}

export default useEvents;
