import { apiUrl, authenticatedFetch } from "./lib";

export type CreateUserValue = {
  name: string;
  email: string;
  password: string;
  address: string;
  walletAddress: string;
  kycUrl: string;
  kycVerified: boolean;
};

export function getMyProfile(token?: string) {
  return authenticatedFetch(`${apiUrl}/buyer/profile`).then((json) => json.result);
}
