type Props = {
  // TODO: remove any
  icon: any;
  heading: string;
  description: string;
};

function IconCard({ icon: Icon, heading, description }: Props) {
  return (
    <article className="rounded-3xl bg-[#030E20] px-6 py-10 border-brand-black border text-center max-w-sm">
      <div className="h-14 w-14 mx-auto">
        <Icon />
      </div>
      <header className="text-xl font-bold mt-8 text-white">{heading}</header>
      <p className="text-base mt-4 whitespace-pre-line opacity-70 leading-8">{description}</p>
    </article>
  );
}

export default IconCard;
