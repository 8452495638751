import { Ticket } from "@api/tickets";
import Button from "@atoms/button";
import Collapsible from "@atoms/collapsible";
import Dropdown from "@atoms/dropdown";
import { priceFormatter } from "@utils/formatters";
import cn from "classnames";
import { SyntheticEvent, useContext, useMemo, useState } from "react";
import { ReactComponent as Lock } from "@assets/icons/lock.svg";
import { ReactComponent as LockUnlocked } from "@assets/icons/lock-unlocked.svg";

export type Collectible = {
  img: string;
  type: string;
  quantity: number;
  username?: string;
};

export type EventTicketCardProps = {
  ticket: Ticket;
  selectedQuantity: number;
  onSelectQuantity: (quantity: number) => void;
  inclVat?: boolean;
  ticketTransfersEnabled: boolean;
  currencySymbol: string;
};

type QuantitySelectorProps = {
  maxAvailable: number;
  selectedQuantity: number;
  onSelectQuantity: (quantity: number) => void;
};

const FEATURES = {
  GROUP_TICKETS: true,
};

function TicketQuantitySelector({ maxAvailable, selectedQuantity, onSelectQuantity }: QuantitySelectorProps) {
  const dropdownItems = useMemo(
    () =>
      new Array(maxAvailable + 1).fill("").map((i, idx) => ({
        value: idx.toString(),
        label: idx.toString(),
        key: idx,
      })),
    [maxAvailable]
  );

  return (
    <Dropdown
      items={dropdownItems}
      value={selectedQuantity}
      onChange={(e) => onSelectQuantity(parseInt(e.target.value, 10))}
    />
  );
}

export default function EventTicketCard({
  ticket,
  selectedQuantity,
  onSelectQuantity,
  inclVat,
  ticketTransfersEnabled,
  currencySymbol,
}: EventTicketCardProps) {
  const {
    name,
    price,
    description,
    ticketsRemaining,
    maxPerWallet,
    lottery,
    purchasable,
    soldOutText = "Sold out",
    allowTransfers,
  } = ticket;
  const [expanded, setExpanded] = useState(false);
  const isSoldOut = !ticketsRemaining;
  const isDisabled = isSoldOut;
  const [ticketLotteryEntered, setTicketLotteryEntered] = useState<boolean>(false);
  const [ticketRegisteredInterest, setTicketRegisteredInterest] = useState<boolean>(false);
  const priceLabel = (() => {
    const isFree = !price;
    if (isFree) return "Free";
    else if (FEATURES.GROUP_TICKETS)
      return `${currencySymbol}${priceFormatter(price)} each` + (inclVat ? "  (inc. VAT)" : "");
    else return `${currencySymbol}${priceFormatter(price)}`;
  })();
  const buttonProps = {
    variant: "secondary" as "secondary",
    size: "medium" as "medium",
    className: "uppercase",
  };

  const handleEnterTicketLottery = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!ticketLotteryEntered) {
      setTicketLotteryEntered(true);
    }
  };

  const handleRegisterInterest = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!ticketRegisteredInterest) {
      setTicketRegisteredInterest(true);
    }
  };

  const renderButtonSelector = (isSelected?: boolean) => {
    const onClick = (num: number) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      onSelectQuantity(num);
    };
    if (isSoldOut)
      return <Button {...buttonProps} disabled children={<span className="whitespace-normal">{soldOutText}</span>} />;
    if (isSelected) return <Button {...buttonProps} children="Selected" onClick={onClick(0)} />;
    else return <Button {...buttonProps} children="Select" onClick={onClick(1)} />;
  };

  const renderQuantitySelector = () => {
    const maxAvailable = ticketsRemaining < maxPerWallet ? ticketsRemaining : maxPerWallet;
    if (isSoldOut) return <Button {...buttonProps} disabled children={soldOutText} />;
    return <TicketQuantitySelector {...{ selectedQuantity, onSelectQuantity, maxAvailable }} />;
  };

  return (
    <div
      className={cn(
        "flex flex-col w-full rounded-lg p-5 drop-shadow-lg",
        `${
          expanded
            ? "bg-dark-blue bg-gradient-to-r from-[#17191D99] to-[#00110000] overflow-y-auto"
            : !!selectedQuantity
            ? "bg-dark-blue/30 bg-gradient-to-r from-[#17191D99] to-[#00110000] overflow-y-auto"
            : "bg-[#1A2743] bg-gradient-to-r from-[#17191D99] to-[#00110000]"
        }`,
        { "opacity-50": isSoldOut },
        { "cursor-pointer": !isDisabled }
      )}
    >
      <div className={cn("flex flex-row justify-between mb-4", `${isSoldOut ? "text-grey/30" : "text-white"}`)}>
        <div className="flex-1">
          <h3 className="text-lg leading-6 font-bold mb-1">{name}</h3>
          <span>{priceLabel}</span>
        </div>
        {!lottery && purchasable && (
          <div className="ml-4">
            {FEATURES.GROUP_TICKETS ? renderQuantitySelector() : renderButtonSelector(!!selectedQuantity)}
          </div>
        )}
      </div>
      {
        <Collapsible open={expanded} onToggle={setExpanded}>
          <span className="whitespace-pre-wrap">{description}</span>
          <span className="mt-4 pb-4 w-full block h-px border-t-white border-t border-opacity-30" />
          {ticketTransfersEnabled && !allowTransfers && (
            <p className="flex items-center justify-start font-bold pb-4">
              <Lock className="mr-1 grow-0 shrink-0" />
              <span>This ticket is non transferable</span>
            </p>
          )}
          {ticketTransfersEnabled && allowTransfers && (
            <p className="flex items-center justify-start font-bold pb-4">
              <LockUnlocked className="mr-1 grow-0 shrink-0" />
              <span>This ticket is transferable</span>
            </p>
          )}
        </Collapsible>
      }
      {!purchasable ? (
        <Button
          className="w-full mt-4"
          size="medium"
          variant={ticketRegisteredInterest ? "secondary" : "primary"}
          onClick={handleRegisterInterest}
        >
          {ticketRegisteredInterest ? "Registered for alerts" : "Register interest"}
        </Button>
      ) : lottery ? (
        <Button
          className="w-full mt-4"
          size="medium"
          variant={ticketLotteryEntered ? "secondary" : "primary"}
          onClick={handleEnterTicketLottery}
        >
          {ticketLotteryEntered ? "Registered for lottery" : "Enter ticket lottery"}
        </Button>
      ) : null}
      {/* event collectables
      {expanded && (
        <div className="mt-4 text-white">
          <div className="h-px mb-4 bg-white/50" />
          <p>Collectables obtained with ticket:</p>
          <div>
            {collectables?.map((collectible) => (
              <div className="h-14 flex flex-row rounded-3xl bg-navy my-2.5 overflow-hidden text-l md:text-xl ">
                <img src={collectible.img} alt="collectibleImg" className="w-16 " />
                <span className="flex flex-row justify-between mx-4 items-center w-full">
                  <h2 className="font-bold">{collectible.type}</h2>
                  <h2 className="px-2 ">x{collectible.quantity}</h2>
                  {<CircularText elementHeight="32px" text={`x${collectible.quantity}`} />  }
                </span>
              </div>
            ))}
          </div>
        </div>
      )} */}
    </div>
  );
}
