import { ReactNode, useEffect, useState } from "react";
import Button from "@atoms/button";
import { Close, Filter } from "@atoms/icons";
import PageContainer from "@atoms/page-container";
import useBodyOverflowHidden from "@hooks/use-body-overflow-hidden";
import PaginationButtons from "@molecules/pagination-buttons";
import SearchListingFilters from "@organisms/search-listing-filters";
import classNames from "classnames";
import SearchBar from "@atoms/search-bar";
import { FilterOrganiser, SearchFilters, SearchListingResponse } from "@api/common";
import useSearchListingFilter from "@hooks/use-search-listing-filter";
import { UseQueryResult } from "react-query";
import { Values } from "@organisms/search-listing-filters/search-listing-filters";

type Props<R> = {
  organisersFilterTitle: string;
  showFilters: {
    pricing: boolean;
    dates: boolean;
  };
  searchPlaceholder?: string;
  children: (data: UseQueryResult<SearchListingResponse<R>, unknown>) => ReactNode;
  itemsQueryKey: string;
  organisersQueryKey: string;
  itemsQuery: (searchFilters: SearchFilters) => Promise<SearchListingResponse<R>>;
  organisersQuery: (includeUnverified: boolean) => Promise<FilterOrganiser[]>;
  noResults?: ReactNode;
};

function SearchListing<R = any>({
  children,
  showFilters,
  searchPlaceholder,
  itemsQuery,
  itemsQueryKey,
  organisersQuery,
  organisersQueryKey,
  noResults,
  organisersFilterTitle,
}: Props<R>) {
  const {
    query: searchQuery,
    organiserQuery,
    minPrice,
    maxPrice,
    startDate,
    endDate,
    selectedOrganiserIds,
    verifiedOnly,
    page,
    search: activeSearch,
    setVerifiedOnly,
    setPage,
    refetchQuery,
  } = useSearchListingFilter([itemsQueryKey, itemsQuery], [organisersQueryKey, organisersQuery]);
  const totalPages = searchQuery.data?.last_page;
  const filters = { minPrice, maxPrice, startDate, endDate, verifiedOnly, selectedOrganiserIds };
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [search, setSearch] = useState("");
  const filterVisibilityClass = showFilterMenu ? "fixed" : "hidden";
  const { setBodyOverflowHidden, removeBodyOverflowHidden } = useBodyOverflowHidden();

  const onFilterSubmit = (values: Partial<Values & { search: string }>) =>
    refetchQuery({ search: activeSearch, ...values });

  const toggleFilterMenu = () => setShowFilterMenu(!showFilterMenu);

  const onClearSearch = () => {
    setSearch("");
    onFilterSubmit({ ...filters, search: undefined });
  };

  const emptyNode = !!noResults ? (
    typeof noResults === "string" ? (
      <p className="font-bold text-2xl font-white text-center mt-12 md:mt-32">{noResults}</p>
    ) : (
      { noResults }
    )
  ) : null;

  useEffect(() => {
    if (showFilterMenu) setBodyOverflowHidden();
    else removeBodyOverflowHidden();
    return () => removeBodyOverflowHidden();
  }, [showFilterMenu, setBodyOverflowHidden, removeBodyOverflowHidden]);

  return (
    <PageContainer>
      <div className="lg:flex lg:flex-row lg:gap-x-9 lg:justify-center">
        <aside
          className={classNames(
            filterVisibilityClass,
            "z-10 inset-0 h-full overflow-auto bg-siteBg pb-8",
            "lg:static lg:w-1/4 lg:block lg:bg-transparent lg:h-auto lg:pb-0"
          )}
        >
          <div className="text-2xl font-bold p-4 text-white border-b border-brand-black flex justify-between items-center lg:px-0">
            <header>Filters</header>
            <div className="lg:hidden w-8 h-8 cursor-pointer" onClick={toggleFilterMenu}>
              <Close />
            </div>
          </div>
          <SearchListingFilters
            organisersFilterTitle={organisersFilterTitle}
            organisers={organiserQuery.data}
            pricing={showFilters.pricing}
            dates={showFilters.dates}
            setVerified={(verified) => {
              setVerifiedOnly(verified);
              setShowFilterMenu(false);
            }}
            verifiedOnly={verifiedOnly}
            onSubmit={(vals) => {
              onFilterSubmit(vals);
              setShowFilterMenu(false);
            }}
            initialValues={{ minPrice, maxPrice, startDate, endDate }}
          />
        </aside>
        <div className="lg:w-3/4">
          <SearchBar
            value={activeSearch === search ? "" : search}
            activeSearch={activeSearch}
            disabled={!!activeSearch}
            onClear={onClearSearch}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={!!activeSearch ? "" : searchPlaceholder}
            onSearch={() => onFilterSubmit({ ...filters, search })}
            className="mb-8"
          />
          {searchQuery.data?.total === 0 ? emptyNode : children(searchQuery)}
          {!!totalPages && totalPages > 1 && (
            <div className="flex flex-row justify-center mt-8">
              <PaginationButtons activePage={page} pages={totalPages} setPage={setPage} />
            </div>
          )}
        </div>
      </div>
      <Button
        rounded
        className={classNames("fixed bottom-8 right-6 z-50 lg:hidden", { hidden: showFilterMenu })}
        onClick={() => setShowFilterMenu(true)}
        size="medium"
        icon={Filter}
        iconPosition="left"
        iconSize="small"
      >
        Filters
      </Button>
    </PageContainer>
  );
}

export default SearchListing;
