import { useContext, useState } from "react";
import Countdown from "react-countdown";
import CheckoutPrePaymentForm, { Props as PrePaymentFormProps } from "@organisms/checkout-pre-payment-form";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import { UserException } from "@api/lib";
import GradientBorderSection from "@atoms/gradient-border-section";
import { FetchedTicket } from "@api/tickets";
import { Collectible } from "@api/collectibles";
import { FetchedEvent } from "@api/events";
import { UseMutationResult } from "react-query";
import useModal from "@hooks/use-modal";
import IframeQuickPaymentSection from "@pages/checkout/quick-payment-section";
import IframePaymentSection from "@pages/iframe-checkout/iframe-payment-section";
import { AppVariablesContext } from "@context/app-variables-context";

type BaseProps = Pick<PrePaymentFormProps, "priceList" | "totalPrice"> & {
  title?: string;
  purchaseExpiry?: Date;
  clientSecret?: string;
  onProcessPurchase: (params: {
    email: string;
    mobile: string;
    firstname: string;
    lastname: string;
    custom_question_1: string | null;
    custom_question_2: string | null;
    custom_answer_1: string | null;
    custom_answer_2: string | null;
  }) => Promise<{
    client_secret: string;
    stripe_publishable_key: string;
    stripe_country: string;
    stripe_currency: string;
  }>;
  handlePurchaseExpiryRedirect: () => void;
  completeFreePurchase: UseMutationResult<any, unknown, void, any>;
  currencySymbol: string;
};
type TicketCheckoutProps = BaseProps & {
  type: "ticket";
  ticketPurchaseDetails?: { ticketType: FetchedTicket; quantity: number }[];
  occassion?: FetchedEvent;
};
type CollectibleCheckoutProps = BaseProps & {
  type: "collectible";
  collectiblePurchaseDetails?: Collectible[];
};

export function IframeCheckout({
  purchaseExpiry,
  onProcessPurchase,
  title,
  handlePurchaseExpiryRedirect,
  priceList,
  totalPrice,
  completeFreePurchase,
  currencySymbol,
  ...props
}: TicketCheckoutProps | CollectibleCheckoutProps) {
  const { marketplaceFeatures } = useContext(AppVariablesContext) || {};
  const quickPayEnabled = marketplaceFeatures ? marketplaceFeatures.quickPayEnabled : false;
  const { showModal } = useModal();
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>();
  const [clientSecret, setClientSecret] = useState<string | undefined>(undefined);
  const [stripeCountry, setStripeCountry] = useState<string | undefined>(undefined);
  const [stripeCurrency, setStripeCurrency] = useState<string | undefined>(undefined);
  const navigate = useNavigate();
  const options = {
    clientSecret: clientSecret,
  };
  const onSubmitPreForm = ({
    email,
    fullNumber,
    firstname,
    lastname,
    custom_question_1,
    custom_question_2,
    custom_answer_1,
    custom_answer_2,
  }: {
    email: string;
    fullNumber: string;
    firstname: string;
    lastname: string;
    custom_question_1: string | null;
    custom_question_2: string | null;
    custom_answer_1: string | null;
    custom_answer_2: string | null;
  }) => {
    onProcessPurchase({
      email,
      mobile: fullNumber,
      firstname,
      lastname,
      custom_question_1,
      custom_question_2,
      custom_answer_1,
      custom_answer_2,
    })
      .then(({ client_secret, stripe_publishable_key, stripe_country, stripe_currency }) => {
        setStripePromise(loadStripe(stripe_publishable_key));
        if (client_secret) setClientSecret(client_secret);
        if (stripe_country) setStripeCountry(stripe_country);
        if (stripe_currency) setStripeCurrency(stripe_currency);
        else completeFreePurchase.mutateAsync().then(() => navigate("/iframe/purchase-success"));
      })
      .catch((error: UserException) => showModal({ title: "An error has ocurred", children: error.message }));
  };

  const showPaymentSection = !!clientSecret;
  const timeRenderer = (data: any) => {
    const { minutes, seconds } = data;
    return (
      <span className="flex flex-row align-center ml-2 font-bold w-24 text-center justify-center text-danger text-3xl">
        {minutes < 10 ? "0" + minutes : " " + minutes}:{seconds < 10 ? "0" + seconds : "" + seconds}
      </span>
    );
  };

  return (
    <>
      <div className="flex flex-col justify-between mb-8 md:flex-row">
        <h1 className="text-6xl font-bold text-white mb-8 md:mb-0">{title}</h1>
        {!!purchaseExpiry && (
          <GradientBorderSection className="bg-[#1A2743] relative px-5 py-2 text-white flex items-center">
            <span className="flex">Your purchase is reserved for:</span>
            <Countdown date={purchaseExpiry} renderer={timeRenderer} onComplete={handlePurchaseExpiryRedirect} />
          </GradientBorderSection>
        )}
      </div>
      {!showPaymentSection && !!currencySymbol && (
        <CheckoutPrePaymentForm
          onSubmit={onSubmitPreForm}
          disabled={showPaymentSection}
          {...{ priceList, totalPrice }}
          {...(props.type === "collectible"
            ? { collectiblePurchaseDetails: props.collectiblePurchaseDetails }
            : { occasion: props.occassion, ticketPurchaseDetails: props.ticketPurchaseDetails })}
          currencySymbol={currencySymbol}
        />
      )}
      {!!stripePromise && clientSecret && stripeCountry && stripeCurrency && (
        <>
          <Elements stripe={stripePromise} options={options}>
            {quickPayEnabled && showPaymentSection && totalPrice && (
              <IframeQuickPaymentSection
                stripeCurrency={stripeCurrency}
                stripeCountry={stripeCountry}
                clientSecret={clientSecret}
                totalPrice={totalPrice}
              />
            )}
            {showPaymentSection && <IframePaymentSection clientSecret={clientSecret} />}
          </Elements>
        </>
      )}
    </>
  );
}

export default IframeCheckout;
