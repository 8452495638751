import React, { ElementType } from "react";
import cn from "classnames";

import { ButtonProps } from "./button.types";
import Heading, { HeadingVariant } from "@atoms/heading";
import SVG from "@atoms/svg";

const buttonVariant = (variant: string, isFullWidth?: boolean, active?: boolean, rounded?: boolean): string => {
  const radius = rounded ? "rounded-full" : "rounded-md";
  const baseStyles = cn(
    `inline-flex justify-center text-white items-center whitespace-nowrap leading-none ${radius} outline-none active:ring-0 focus-visible:green-30 focus-visible:ring-4 focus-visible:ring-green-20`,
    { "w-full": isFullWidth }
  );

  // if loading bg-green-30 = bg-green-40
  const variantToClassName: Record<string, string> = {
    primary: `${baseStyles} bg-gradient-to-r hover:bg-gradient-to-tr from-bluer to-blue text-white hover:bg-dark-blue active:bg-blue `,
    secondary: `${baseStyles} bg-grey/10 hover:bg-opacity-25 active:bg-opacity-50`,
    outline: `${baseStyles} bg-transparent border border-white text-white hover:bg-white hover:text-brand-black active:bg-grey-40 active:text-grey-70`,
    info: `${baseStyles} bg-blue text-white`,
    underline: `${baseStyles} rounded-b-none ${
      active ? " border-bluer border-b-2" : "border-blue border-b opacity-40"
    }`,
  };

  return variantToClassName[variant];
};

const sizeVariant = {
  xlarge: "h-11 px-8",
  large: "h-14 px-8",
  medium: "h-12 px-4 tracking-wider",
  small: "h-9 px-6",
};

const fontSizeMap = {
  xlarge: HeadingVariant.heading1,
  large: HeadingVariant.heading2,
  medium: HeadingVariant.heading2,
  small: HeadingVariant.heading3,
};

function Button<T extends ElementType>({
  active,
  variant = "primary",
  type,
  onClick,
  children,
  isFullWidth = false,
  disabled,
  size = "large",
  iconPosition = "right",
  icon,
  className,
  as,
  rounded,
  iconSize,
  ...props
}: ButtonProps<T> & Omit<React.ComponentPropsWithoutRef<T>, keyof ButtonProps<T>>) {
  const headingVariant = fontSizeMap[size];
  const Component = as || "button";
  return (
    <Component
      {...props}
      type={as === "button" ? "button" : type}
      className={cn(buttonVariant(variant, isFullWidth, active, rounded), sizeVariant[size], className, {
        "cursor-not-allowed opacity-30": disabled,
      })}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && iconPosition === "left" && (
        <span className={size === "large" ? "mr-3" : "mr-2"}>
          <SVG size={iconSize || size} icon={icon} />
        </span>
      )}
      <Heading variant={headingVariant}>{children}</Heading>

      {icon && iconPosition === "right" && (
        <span className={size === "large" ? "ml-3" : "ml-2"}>
          <SVG size={size} icon={icon} />
        </span>
      )}
    </Component>
  );
}

export default Button;
