import SVG from "@atoms/svg";
import { Key } from "@atoms/icons";

type ClaimableCardProps = {
  name: string;
  itemCount: number | null;
};

export default function ClaimableCard({ name, itemCount }: ClaimableCardProps) {
  return (
    <div className="bg-navy w-full p-5 flex flex-row justify-between rounded-2xl text-lg font-bold">
      <p>{name}</p>
      <p>{!!itemCount ? `x${itemCount}` : <SVG icon={Key} color="white" />}</p>
    </div>
  );
}
