import ColectiblesListing from "@pages/collectibles-listing";
import HeadTitle from "@atoms/head-title/head-title";
import useCollectibles from "@hooks/use-collectibles";
import SearchListing from "@templates/search-listing";

function CollectiblesListingRoute() {
  const { fetchAllCollectibles, fetchSellers } = useCollectibles();

  return (
    <>
      <HeadTitle titlePrefix="Collectables" />
      <SearchListing
        organisersFilterTitle="Creators"
        showFilters={{ pricing: true, dates: false }}
        itemsQuery={fetchAllCollectibles}
        organisersQueryKey="allCreators"
        organisersQuery={fetchSellers}
        itemsQueryKey="allCollectibles"
        searchPlaceholder="Search by title or creator"
        noResults="No collectables to display"
      >
        {({ isLoading, data }) => <ColectiblesListing collectibles={data?.result} loading={isLoading} />}
      </SearchListing>
    </>
  );
}

export default CollectiblesListingRoute;
