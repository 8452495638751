import { Share } from "@atoms/icons";

type Props = {
  title: string;
  text: string;
  url?: string;
};

function ShareButton({ title, text, url = window.location.href }: Props) {
  const shareData = { title, text, url };

  if (!navigator.canShare) return null;

  const handleShare = async () => {
    try {
      await navigator.share(shareData);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="flex flex-row cursor-pointer" onClick={handleShare}>
      <span className="text-l font-bold text-white mr-2">Share</span>
      <Share />
    </div>
  );
}

export default ShareButton;
