import { Collectible } from "@api/collectibles";
import BadgeInfo from "@atoms/badge-info";
import { MapPin } from "@atoms/icons";
import EventDateTime from "@molecules/event-date-time";
import ImagekitImage from "@molecules/imagekit-image/imagekit-image";
import { priceFormatter } from "@utils/formatters";
import { ReactNode } from "react";
import { Link, useNavigate } from "react-router-dom";
import CurrencySymbol from "@atoms/currency-symbol";
import Button from "@atoms/button";

export default function IframeCollectibleListCard({
  detailUrl,
  imageUrl,
  quantityAvailable,
  title,
  description,
  price,
  seller,
}: Collectible & { loading?: boolean; detailUrl: string }) {
  const navigate = useNavigate();
  const clickableText = (child: ReactNode) => <Link to={detailUrl}>{child}</Link>;

  return (
    <div className="flex flex-row h-52 w-full">
      {clickableText(
        <span className="inline-block relative rounded-2xl overflow-hidden">
          <span className="absolute bg-gradient-to-b from-transparent to-black top-0 left-0 w-full h-full z-1 opacity-30" />
          <ImagekitImage src={imageUrl} className="object-cover h-52 w-52 " alt="Collectable thumbnail" />
          <span className="absolute right-4 bottom-3">
            <BadgeInfo count={quantityAvailable} text="Editions left:" variant="alert" />
          </span>
        </span>
      )}
      <div className="flex flex-col items-between justify-between mx-8 text-white w-full">
        <div />
        <div className="flex flex-col gap-1 mt-4">
          <h2 className="text-lg font-bold">{clickableText(title)}</h2>
          {clickableText(
            <span className="flex flex-row space-x-2">
              <span className="flex items-center w-full">
                <span className="text-s">
                  <CurrencySymbol text={seller.currencySymbol} />
                  {price ? priceFormatter(price) : "Free"}
                </span>
              </span>
              <span className="float-right">
                <Button
                  size="large"
                  className="w-full max-w-[250px] mt-4"
                  onClick={() => {
                    void navigate(`${detailUrl}`);
                  }}
                >
                  Buy now
                </Button>
              </span>
            </span>
          )}
        </div>
        <span className="flex flex-row items-center space-x-5">&nbsp;</span>
      </div>
    </div>
  );
}
