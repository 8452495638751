import { AppleAppStore, GooglePlayStore } from "@atoms/icons";
import { AppVariablesContext } from "@context/app-variables-context";
import { useContext } from "react";

type Props = {
  platform: "ios" | "android";
};

function TicketWalletStoreLink({ platform }: Props) {
  const { nativeAppUrls } = useContext(AppVariablesContext) || {};
  const androidLink = nativeAppUrls?.wallet.android;
  const iosLink = nativeAppUrls?.wallet.ios;
  const link = platform === "ios" ? iosLink : androidLink;
  const Icon = platform === "ios" ? AppleAppStore : GooglePlayStore;

  if (!link) return null;

  return (
    <a href={link} target="_blank" rel="noreferrer" style={{ display: "block", flex: 1 }}>
      <Icon />
    </a>
  );
}

export default TicketWalletStoreLink;
