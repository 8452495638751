import cn from "classnames";
import { AvatarProps } from "./avatar.types";
import Heading, { HeadingVariant } from "@atoms/heading";
import { SuccessCheck } from "@atoms/icons";
import SVG from "@atoms/svg";
import classes from "./avatar.module.css";
import classNames from "classnames";

const avatarVariant = (variant: string): string => {
  const baseStyles = cn(
    `inline-flex justify-center text-white items-center whitespace-nowrap leading-none rounded-lg outline-none active:ring-0 focus-visible:green-30 focus-visible:ring-4 focus-visible:ring-green-20`
  );
  // if loading bg-green-30 = bg-green-40
  const variantToClassName: Record<string, string> = {
    primary: `${baseStyles}  bg-grey/10 text-white hover:bg-dark-blue`,
    withUsername: `${baseStyles} `,
  };

  return variantToClassName[variant];
};

const ImageSizeVariant = {
  large: "h-12",
  medium: "h-10",
  small: "h-8",
};
const borderVariant = {
  primary: "border-2",
  sapphire: "border-violet-500 border-2",
  gold: "border-yellow-300 border-2",
  holographic: "border-violet-300 border-2",
};

const fontSize = {
  large: HeadingVariant.heading2,
  medium: HeadingVariant.heading3,
  small: HeadingVariant.heading4,
};

const Avatar = ({
  border = "sapphire",
  imgSrc,
  imgClassName,
  isDisabled = false,
  label,
  labelClassName,
  onClick,
  size = "large",
  sub,
  variant = "primary",
  verified,
  containerClassName,
}: AvatarProps) => {
  return (
    <div
      onClick={isDisabled ? undefined : onClick}
      className={classNames("shrink-0 flex items-center text-left", containerClassName)}
    >
      {imgSrc && (
        <img
          className={cn(
            avatarVariant(variant),
            ImageSizeVariant[size],
            borderVariant[border],
            imgClassName,
            {
              "cursor-not-allowed opacity-30": isDisabled,
            },
            "aspect-square object-cover rounded-md",
            "mr-2"
          )}
          src={imgSrc}
          alt="user"
        />
      )}

      {(!!label || !!sub) && (
        <div className="flex flex-col justify-center">
          {!!label && (
            <Heading
              children={label}
              className={classNames(labelClassName, classes.sellerName)}
              variant={fontSize[size]}
            />
          )}
          {!!sub && <div className="block text-sm">{sub}</div>}
        </div>
      )}
      {verified && <SVG icon={SuccessCheck} height={24} width={24} />}
    </div>
  );
};

export default Avatar;
