import cn from "classnames";
import React, { DetailedHTMLProps, InputHTMLAttributes, ReactNode, useState } from "react";

export type Props = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  error?: string;
  label?: string;
  icon?: ReactNode;
  iconPostion?: "left" | "right";
};

function Input({
  value,
  error,
  disabled,
  className,
  label,
  placeholder,
  onFocus,
  onBlur,
  icon,
  iconPostion = "left",
  ...rest
}: Props) {
  const [inputIsFocused, setInputIsFocused] = useState(false);

  const inputStyle = cn(
    "outline-none text-heading3 rounded-md text-white h-16 p-4 bg-brand-black w-full",
    !!error ? "pr-9" : "pr-4",
    { "ring-2 ring-inset ring-danger bg-danger/20": !!error },
    { "bg-opacity-40 cursor-not-allowed": disabled },
    { "px-4 pt-7 pb-2": !!label },
    { "pl-14": !!icon && iconPostion === "left" },
    { "pr-14": !!icon && iconPostion === "right" },
    className
  );

  const labelBasePositioning = cn("transition-transform origin-top-left absolute top-5 left-4");
  const labelBaseClass = cn(labelBasePositioning, "text-grey z-10 items-center flex pointer-events-none ");
  const labelSmallClass = "scale-75 -translate-y-2";
  const shouldShrinkLabel = inputIsFocused || value;

  const focusHandler = (kind: "blur" | "focus") => {
    const fn = kind === "blur" ? onBlur : onFocus;
    const updateState = () => setInputIsFocused(kind === "focus");
    return (e: React.FocusEvent<HTMLInputElement>) => {
      if (fn) fn(e);
      updateState();
    };
  };

  return (
    <div className="relative">
      {label && <label className={cn(labelBaseClass, { [labelSmallClass]: shouldShrinkLabel })}>{label}</label>}
      {error && <span className="text-danger absolute -top-4 lg:top-2 right-4 text-xs">{error}</span>}
      {!!icon && (
        <div
          className={cn("absolute top-0 bottom-0 w-14 flex", {
            "left-0": iconPostion === "left",
            "right-0": iconPostion === "right",
          })}
        >
          {icon}
        </div>
      )}
      <input
        placeholder={shouldShrinkLabel ? placeholder : label ? "" : placeholder}
        onFocus={focusHandler("focus")}
        onBlur={focusHandler("blur")}
        className={inputStyle}
        {...{ ...rest, value, disabled }}
        aria-invalid={!!error}
      />
    </div>
  );
}

export default Input;
