import { LoadingSpinner } from "@atoms/icons";
import usePurchaseRequest from "@hooks/use-purchase-request";
import { FetchedTicket } from "@api/tickets";
import IframeCheckout from "@pages/iframe-checkout";
import React, { useCallback, useEffect } from "react";
import IframeHeading from "@atoms/iframe-heading";
import slugify from "slugify";

type Props = {
  title: string;
  purchaseCode: string;
  onPurchaseExpiry: (redirectUrl: string) => void;
};

function IframeEventCheckout({ title, purchaseCode, onPurchaseExpiry }: Props) {
  const { requestQuery, processPurchase, purchaseExpiry, priceList, totalPrice, completeFreePurchase } =
    usePurchaseRequest("event", purchaseCode);
  const handlePurchaseExpiryRedirect = useCallback(() => {
    const sellerName = !!requestQuery.data ? requestQuery.data.event.seller.currency_symbol : "";
    const sellerSlug = !!sellerName ? slugify(sellerName, { lower: true, replacement: "" }) : "";
    const redirectUrl = !!requestQuery.data
      ? `/iframe/events/${requestQuery.data.event.slug}`
      : `/iframe/${sellerSlug}/events`;
    onPurchaseExpiry(redirectUrl);
  }, [onPurchaseExpiry, requestQuery.data]);
  const currencySymbol = !!requestQuery.data ? requestQuery.data.event.seller.currency_symbol : "$";

  const onProcessPurchase = ({
    email,
    mobile,
    firstname,
    lastname,
    custom_question_1,
    custom_question_2,
    custom_answer_1,
    custom_answer_2,
  }: {
    email: string;
    mobile: string;
    firstname: string;
    lastname: string;
    custom_question_1: string | null;
    custom_question_2: string | null;
    custom_answer_1: string | null;
    custom_answer_2: string | null;
  }) => {
    return processPurchase.mutateAsync({
      email,
      phone: mobile,
      code: purchaseCode,
      firstname,
      lastname,
      custom_question_1,
      custom_question_2,
      custom_answer_1,
      custom_answer_2,
    });
  };
  const ticketPurchaseDetails = (() => {
    if (!requestQuery.data) return [];
    const ticketTypes = requestQuery.data.event.ticket_types;
    const purchaseIdAndQuantity = Object.entries(requestQuery.data.purchase_request);
    return purchaseIdAndQuantity.reduce((accum, [id, quantity]) => {
      const ticketType = ticketTypes.find(({ uuid }) => uuid === id);
      if (!ticketType) return accum;
      return [...accum, { ticketType, quantity }];
    }, [] as { ticketType: FetchedTicket; quantity: number }[]);
  })();

  useEffect(() => {
    if (requestQuery.isError && (requestQuery.error as any)?.message === "Code expired") handlePurchaseExpiryRedirect();
  }, [requestQuery.isError, requestQuery.error, handlePurchaseExpiryRedirect]);

  if (!requestQuery.isSuccess) return <div className="w-24 h-24 mx-auto" children={<LoadingSpinner />} />;
  return (
    <>
      <IframeHeading title={"Tickets"}></IframeHeading>
      <div className="grid grid-cols-1 gap-y-0 pt-5 w-full">
        <IframeCheckout
          type="ticket"
          occassion={requestQuery.data?.event}
          {...{
            onProcessPurchase,
            purchaseExpiry,
            title,
            handlePurchaseExpiryRedirect,
            ticketPurchaseDetails,
            priceList,
            totalPrice,
            completeFreePurchase,
            currencySymbol,
          }}
        />
      </div>
    </>
  );
}

export default IframeEventCheckout;
