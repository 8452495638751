import cn from "classnames";
import React, { useState } from "react";
import CurrencyInput, { CurrencyInputProps } from "react-currency-input-field";

type Props = CurrencyInputProps & {
  error?: string;
  label?: string;
};

function CurrencyInputField({
  value,
  error,
  disabled,
  className,
  required,
  label,
  placeholder,
  onBlur,
  onFocus,
  ...rest
}: Props) {
  const [inputIsFocused, setInputIsFocused] = useState(false);

  const shouldShrinkLabel = inputIsFocused || !!value;
  const inputStyle = cn(
    "outline-none text-heading3 rounded-md text-white h-16 p-4 bg-brand-black w-full",
    !!error ? "pr-9" : "pr-4",
    { "ring-2 ring-inset ring-danger bg-danger/20": !!error },
    { "bg-opacity-40 cursor-not-allowed": disabled },
    { "px-4 pt-7 pb-2": !!label },
    className
  );
  const labelBasePositioning = cn("transition-transform origin-top-left absolute top-5 left-4");
  const labelBaseClass = cn(labelBasePositioning, "text-grey z-10 items-center flex pointer-events-none ");
  const labelSmallClass = "scale-75 -translate-y-2";

  const onInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) onBlur(event);
    setInputIsFocused(false);
  };

  const onInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (onFocus) onFocus(event);
    setInputIsFocused(true);
  };

  return (
    <div className="relative mt-4 lg:mt-0">
      {label && <label className={cn(labelBaseClass, { [labelSmallClass]: shouldShrinkLabel })}>{label}</label>}
      {error && <span className="text-danger absolute -top-4 lg:top-2 right-4 text-xs">{error}</span>}
      <CurrencyInput
        placeholder={shouldShrinkLabel ? placeholder : label ? "" : placeholder}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
        className={inputStyle}
        {...{ ...rest, disabled }}
        value={value || ""}
        aria-required={required}
        aria-invalid={!!error}
      />
    </div>
  );
}

export default CurrencyInputField;
