import { Event } from "@api/events";
import { Calendar, Clock, MapPin } from "@atoms/icons";
import ImagekitImage from "@molecules/imagekit-image/imagekit-image";
import { priceFormatter } from "@utils/formatters";
import { ReactNode } from "react";
import { Link, useNavigate } from "react-router-dom";
import CurrencySymbol from "@atoms/currency-symbol";
import Button from "@atoms/button";
import { format } from "date-fns";

export default function IframeEventListMobileCard({
  name,
  thumbnailUrl,
  startDate,
  endDate,
  venueName,
  seller,
  ticketTypes,
  lowestPrice,
  detailUrl,
}: Event & { loading?: boolean; lowestPrice?: number | null; detailUrl: string }) {
  const navigate = useNavigate();
  const clickableText = (child: ReactNode) => <Link to={detailUrl}>{child}</Link>;
  const startDay = format(new Date(startDate), "dd/MM/yy");
  const endDay = endDate && format(new Date(endDate), "dd/MM/yy");
  const eventIsMultiDay = endDate && startDay !== endDay;

  return (
    <div className="flex flex-row h-48 w-full">
      {clickableText(
        <span className="inline-block relative rounded-2xl overflow-hidden mt-5 w-39">
          <span className="absolute bg-gradient-to-b from-transparent to-black top-0 left-0 w-full h-max-48 z-1 opacity-30" />
          <ImagekitImage src={thumbnailUrl} className="object-cover max-h-40 w-30" alt="Event thumbnail" />
        </span>
      )}
      <div className="flex flex-col items-between justify-between mx-1 text-white w-full">
        <div />
        <div className="flex flex-col gap-1 mt-1">
          <h1 className="text-lg font-bold ml-2">{clickableText(name)}</h1>
          {clickableText(
            <>
              <span className="flex flex-row space-x-2">
                <span className="flex items-center w-full">
                  <span className="pl-2">
                    <div className="flex flex-row flex-wrap justify-between text-lg">
                      <span className="flex flex-row items-start space-x-3 mr-5">
                        <Calendar style={{ height: 50 }} />
                        <span className="text-white">
                          {startDay} {eventIsMultiDay && `- ${endDay}`}
                        </span>
                      </span>
                      <span className="flex flex-row items-start space-x-2 text-white text-lg">
                        <Clock style={{ height: 50 }} />
                        <span>{format(new Date(startDate), "HH:mm")}</span>
                        {!eventIsMultiDay && (
                          <>
                            <span>-</span>
                            <span>{format(new Date(endDate), "HH:mm")}</span>
                          </>
                        )}
                      </span>
                    </div>
                    <span className="flex flex-row items-start space-x-2 text-white text-lg">
                      {clickableText(
                        <span className="flex space-x-2">
                          <MapPin />
                          <p>{venueName}</p>
                        </span>
                      )}
                      <CurrencySymbol text={seller.currencySymbol} />
                      {lowestPrice ? priceFormatter(lowestPrice) : "Free"}
                    </span>
                  </span>
                </span>
              </span>
            </>
          )}
        </div>
        <span className="flex flex-row items-center space-x-5">
          <Button
            size="small"
            className="w-full max-w-[250px] mt-4"
            onClick={() => {
              void navigate(`${detailUrl}`);
            }}
          >
            Buy now
          </Button>
        </span>
      </div>
    </div>
  );
}
