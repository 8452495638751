import { SVGProps } from "react";

const ShieldTick = (props: SVGProps<SVGSVGElement>) => (
  <svg width="54" height="66" viewBox="0 0 54 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27 0L51.2658 5.478C52.6154 5.784 53.5781 6.999 53.5781 8.406V38.367C53.5781 44.385 50.6161 50.007 45.6874 53.343L27 66L8.31262 53.343C3.38091 50.004 0.421875 44.385 0.421875 38.37V8.406C0.421875 6.999 1.38459 5.784 2.73417 5.478L27 0ZM27 6.147L6.32812 10.812V38.367C6.32812 42.378 8.30081 46.125 11.5876 48.351L27 58.791L42.4124 48.351C45.6992 46.125 47.6719 42.381 47.6719 38.37V10.812L27 6.15V6.147ZM40.1473 21.666L44.326 25.908L25.5323 45L13.0022 32.271L17.1779 28.029L25.5293 36.513L40.1473 21.663V21.666Z"
      fill="white"
    />
  </svg>
);

export default ShieldTick;
