import useModal from "@hooks/use-modal";
import Modal from "@molecules/modal";

function ContextAlertModal() {
  const {
    props: { title, subTitle, children, footerContent, show },
    closeModal,
  } = useModal();

  return <Modal {...{ show, subTitle, title, footerContent, closeModal, children }} />;
}

export default ContextAlertModal;
