import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 18.1583L13.5375 13.6208C14.4348 12.7234 15.0459 11.5801 15.2934 10.3354C15.541 9.09069 15.4138 7.80055 14.9282 6.62809C14.4425 5.45564 13.62 4.45353 12.5648 3.74849C11.5096 3.04344 10.2691 2.66713 9 2.66713C7.73093 2.66713 6.49036 3.04344 5.43516 3.74849C4.37996 4.45353 3.55752 5.45564 3.07184 6.62809C2.58616 7.80055 2.45905 9.09069 2.70658 10.3354C2.95412 11.5801 3.56518 12.7234 4.4625 13.6208L9 18.1583ZM9 20.7507L3.16634 14.917C2.01256 13.7632 1.22684 12.2932 0.908518 10.6929C0.590198 9.09252 0.753581 7.43373 1.37801 5.92624C2.00243 4.41876 3.05986 3.13029 4.41656 2.22377C5.77326 1.31726 7.36831 0.833405 9 0.833405C10.6317 0.833405 12.2267 1.31726 13.5834 2.22377C14.9401 3.13029 15.9976 4.41876 16.622 5.92624C17.2464 7.43373 17.4098 9.09252 17.0915 10.6929C16.7732 12.2932 15.9874 13.7632 14.8337 14.917L9 20.7507ZM9 10.9167C9.48623 10.9167 9.95255 10.7235 10.2964 10.3797C10.6402 10.0359 10.8333 9.56956 10.8333 9.08333C10.8333 8.5971 10.6402 8.13079 10.2964 7.78697C9.95255 7.44315 9.48623 7.25 9 7.25C8.51377 7.25 8.04746 7.44315 7.70364 7.78697C7.35982 8.13079 7.16667 8.5971 7.16667 9.08333C7.16667 9.56956 7.35982 10.0359 7.70364 10.3797C8.04746 10.7235 8.51377 10.9167 9 10.9167ZM9 12.75C8.02754 12.75 7.09491 12.3637 6.40728 11.6761C5.71964 10.9884 5.33334 10.0558 5.33334 9.08333C5.33334 8.11087 5.71964 7.17824 6.40728 6.49061C7.09491 5.80297 8.02754 5.41667 9 5.41667C9.97246 5.41667 10.9051 5.80297 11.5927 6.49061C12.2804 7.17824 12.6667 8.11087 12.6667 9.08333C12.6667 10.0558 12.2804 10.9884 11.5927 11.6761C10.9051 12.3637 9.97246 12.75 9 12.75Z"
      fill="white"
    />
  </svg>
);

export default SvgComponent;
