import { IKImage } from "imagekitio-react";
import { IKImageProps } from "imagekitio-react/dist/types/components/IKImage/combinedProps";

type Props = IKImageProps & {};

function ImagekitImage(props: Props) {
  return <IKImage lqip={{ active: true }} loading="lazy" {...props} />;
}

export default ImagekitImage;
