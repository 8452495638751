import { StaticPageData } from "@api/static-content";
import PageContainer from "@atoms/page-container";
import StaticContentTemplate from "@organisms/static-content-template";

export default function PrivacyPolicy({ content }: { content: StaticPageData }) {
  return (
    <PageContainer>
      <StaticContentTemplate content={content} />
    </PageContainer>
  );
}
