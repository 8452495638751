import Footer from "@organisms/footer";
import Header from "@organisms/header";
import classNames from "classnames";
import { ReactNode } from "react";
import styleClasses from "./layout.module.css";

const Layout = ({ children, showBgImage }: { children: ReactNode; showBgImage?: boolean }) => (
  <div
    className={classNames({
      [styleClasses.withBg]: showBgImage,
    })}
  >
    <div className="relative min-h-screen flex flex-col justify-between max-w-[1560px] mx-auto">
      <Header />
      <main className="flex-1">{children}</main>
      <Footer />
    </div>
  </div>
);

export default Layout;
