import { SVGProps } from "react";

const Twitter = (props: SVGProps<SVGSVGElement>) => (
  <svg width="39" height="32" viewBox="0 0 39 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M38.5541 3.84053C37.1226 4.48777 35.6046 4.91285 34.0503 5.10169C35.6886 4.10011 36.9148 2.5238 37.5003 0.666526C35.9628 1.60186 34.2772 2.25736 32.5203 2.61194C31.3402 1.32122 29.776 0.465205 28.0709 0.17698C26.3657 -0.111245 24.6152 0.184467 23.0914 1.01814C21.5677 1.85182 20.356 3.17675 19.6449 4.78696C18.9338 6.39717 18.763 8.20244 19.1591 9.92211C16.0411 9.76236 12.9909 8.93411 10.2065 7.49113C7.42202 6.04815 4.96555 4.0227 2.99656 1.54628C2.29961 2.76997 1.93335 4.16104 1.93531 5.57703C1.93531 8.35619 3.31906 10.8114 5.42281 12.2489C4.17783 12.2089 2.96025 11.8652 1.87156 11.2465V11.3462C1.87194 13.1971 2.4985 14.991 3.64503 16.4236C4.79155 17.8562 6.38747 18.8394 8.16219 19.2064C7.00647 19.5266 5.79464 19.5738 4.61844 19.3444C5.11881 20.9376 6.09408 22.331 7.40767 23.3294C8.72127 24.3278 10.3074 24.8813 11.9441 24.9124C10.3175 26.2182 8.45502 27.1836 6.46321 27.7532C4.47141 28.3228 2.3893 28.4855 0.335938 28.232C3.92037 30.5884 8.09299 31.8394 12.3547 31.8354C26.7791 31.8354 34.6672 19.6204 34.6672 9.02703C34.6672 8.68203 34.6578 8.33319 34.6428 7.99203C36.1782 6.85768 37.5033 5.45248 38.5559 3.84244L38.5541 3.84053Z"
      fill="white"
    />
  </svg>
);

export default Twitter;
