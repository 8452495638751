import React, { InputHTMLAttributes } from "react";
import classNames from "classnames";
import SVG from "@atoms/svg";
import { MagnifyingGlass } from "@atoms/icons";
import Button from "@atoms/button";
import Pill from "@molecules/pill";

export interface Props extends React.DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  iconPosition?: "left" | "right";
  onSearch?: () => void;
  onClear?: () => void;
  buttonText?: string;
  activeSearch?: string;
}

const SearchBar = ({
  iconPosition = "left",
  onSearch,
  buttonText = "Search",
  className,
  activeSearch,
  onClear,
  ...props
}: Props) => {
  const searchStyle = classNames(
    "flex-1 h-16 outline-none text-white text-heading3 bg-transparent placeholder:text-placeholder",
    { "bg-opacity-60": props.disabled }
  );

  const containerClasses = classNames(
    "relative align-center flex items-center text-neutral-400 rounded-lg bg-brand-black",
    { "pl-12 pr-5": iconPosition === "left" },
    { "pl-5 pr-14": iconPosition === "right" }
  );
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (onSearch) onSearch();
  };

  const icon = (
    <span
      className={classNames("text-placeholder absolute top-0 bottom-0 flex items-center", {
        "left-4": iconPosition === "left",
        "right-4": iconPosition === "right",
      })}
    >
      <SVG icon={MagnifyingGlass} size="small" height="unset" />
    </span>
  );

  return (
    <form onSubmit={onSubmit} className={className}>
      <div className={containerClasses}>
        {icon}
        {activeSearch && <Pill className="mr-2" label={activeSearch} onClose={onClear} />}
        <input className={searchStyle} {...props} />
        {onSearch && (
          <Button type="submit" size="small" className="hidden md:flex my-auto top-0 bottom-0 right-4">
            {buttonText}
          </Button>
        )}
      </div>
    </form>
  );
};

export default SearchBar;
