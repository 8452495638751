import * as React from "react";
import { SVGProps } from "react";

const MagnifyGlass = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 16" {...props}>
    <path
      stroke={"currentcolor"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M15.917 15.25l-3.75-3.75M1.417 7a6.25 6.25 0 1112.5 0 6.25 6.25 0 01-12.5 0z"
    />
  </svg>
);

export default MagnifyGlass;
