import React from "react";
import Heading from "@atoms/heading";
import { Link } from "react-router-dom";
import logo from "@assets/seatlab_white_logo.svg";
import smallLogo from "@assets/seatlab-logo-white-small.png";
import { useMediaQuery } from "react-responsive";

type Props = {
  title: string;
};

function IframeHeading({ title }: Props) {
  const isMobile = useMediaQuery({ query: "(max-width: 420px)" });

  return (
    <>
      <div className="flex flex-row pt-10 w-full">
        <span className={"w-1/3 text-left"}>
          <Heading variant={isMobile ? "heading2" : "heading1"}>{title}</Heading>
        </span>
        <span className={"w-2/3"}>
          <Heading variant={isMobile ? "heading2" : "heading1"} className={"float-right"}>
            Powered by &nbsp;
            <Link
              className={"float-right "}
              to="/"
              children={
                <img
                  className={isMobile ? "-mt-1" : "-mt-2"}
                  src={isMobile ? smallLogo : logo}
                  alt="Powered by Seatlab"
                />
              }
            />
          </Heading>
        </span>
      </div>
    </>
  );
}

export default IframeHeading;
